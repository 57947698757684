import React from 'react';
import styled from 'styled-components';
import MapboxMap from '../core/MapBox';

const MapBox = styled.div`
  height: 358px;
  width: calc(100% - 225px);
  position: relative;
  left: 225px;
  top: 70px;
  box-sizing: border-box;

  @media(max-width: 767px) {
    left: 0;
    top: 90px;
    width: 100%;
    height: 280px;
  }
`;

const MapBackground = styled.div`
  background: transparent;    
  display: block;
  margin-top: 0;
  position: relative;
`;

function MapBlock({markers}) {
  return (
    <MapBackground>
      <MapBox>
        <MapboxMap markers={markers}/>
      </MapBox>
    </MapBackground>
  )
}

export default MapBlock;
