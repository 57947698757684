/**
 * Implement Gatsby'carrier Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'intl'
import 'intl/locale-data/jsonp/en'

export {default as wrapRootElement} from './src/store/reduxWrapper';

export const onServiceWorkerUpdateReady = () => window.location.reload();
