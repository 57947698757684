const SHIPMENT_STATUS_PICKUP = 'pickup';
const SHIPMENT_STATUS_IN_TRANSIT = 'active';
const SHIPMENT_STATUS_ARRIVED = 'arrived';
const SHIPMENT_STATUS_DELIVERED = 'delivered';
const SHIPMENT_STATUS_AWAITING_DEPARTURE = 'awaiting_departure';

export function getTransportFromCodes(codes) {
  return codes.find(v => v.type === 'CCC')?.value
}

function formatTransport(transport, consolidation) {
  const carrier = (transport.carrierAddress || consolidation?.shippingLineAddress)
    ?.organisation.codes
    .find(v => v.type === 'CCC')
    ?.value
  return {
    vessel: transport.vessel,
    voyageFlight: transport.voyageFlight,
    carrier,
  }
}

function getTransport(item) {
  if (item?.transports?.length > 0) {
    return item.transports.map(transport => formatTransport(transport, null))
  }
  return item?.consolidations
    ?.flatMap(consolidation => consolidation.transports.map(transport => formatTransport(transport, consolidation)))
    ?? []
}

function formatNumber(number) {
  let rounded = Math.round(number);

  let parts = rounded.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
}

function getProgress(shipment) {
  return getProgressValue(shipment) + '%';
}

function getProgressValue(shipment) {
  switch (shipment.status) {
    case SHIPMENT_STATUS_AWAITING_DEPARTURE:
      return 0
    case SHIPMENT_STATUS_PICKUP:
      return 33
    case SHIPMENT_STATUS_IN_TRANSIT:
      return 50
    case SHIPMENT_STATUS_ARRIVED:
      return 66
    case SHIPMENT_STATUS_DELIVERED:
      return 100
  }
}

function getEstimatedDeliveryDate(estimatedArrival) {
  if (!estimatedArrival) {
    return false;
  }

  return new Date(new Date(Date.parse(estimatedArrival)).getTime() + (5*24*60*60*1000))
}

export function getEstimatedPickupDate(estimatedDeparture) {
  if (!estimatedDeparture) {
    return false;
  }
  return new Date(new Date(Date.parse(estimatedDeparture)).getTime() - (5*24*60*60*1000))
}

function isDelivered(estimatedArrival) {
  const estimatedDeliveryDate = getEstimatedDeliveryDate(estimatedArrival);
  if (!estimatedDeliveryDate) {
    return false;
  }
  estimatedDeliveryDate.setHours(0, 0, 0, 0)

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0)
  const current = currentDate.getTime();

  return current >= estimatedDeliveryDate;
}

export {
  formatNumber,
  getProgress,
  getTransport,
  getEstimatedDeliveryDate,
  isDelivered,
  SHIPMENT_STATUS_PICKUP,
  SHIPMENT_STATUS_ARRIVED,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_IN_TRANSIT,
  SHIPMENT_STATUS_AWAITING_DEPARTURE
}
