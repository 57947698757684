import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import AccordionOpen from '../../resources/img/ui/accordion-open.svg';
import AccordionClose from '../../resources/img/ui/accordion-close.svg';
import BasicInfoTable from './BasicInfoTable';
import FreightDetails from "./FreightDetails";
import ContainerDetails from "./ContainerDetails";
import {useTranslation} from "react-i18next";
import {CarrierInfoDefined} from "../shipment/CarrierInfo";
import {Reference, Icon, DataText} from "./BookingsStyle";
import {getEstimatedDeliveryDate, getEstimatedPickupDate, getTransportFromCodes} from "../../services/shipments";
import ProgressBar from "../shipments/ProgressBar";
import {formatDate} from "../../services/core/dateHelper";
import useCWTransportModes from "../../hooks/useCWTransportModes";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import FaresTable from "../quotes/FaresTable";
import breakpoints from "../../styles/breakpoints";

const Root = styled.tr`
    height: ${props => props.header ? '79px' : '114px'};
    border-top: 1px solid ${colors.lightBlue};
`;

const TableData = styled.td`
    ${fonts.UbuntuRegular};
    font-size: 14px;
    line-height: 20px;
    color: ${colors.navyBlue};
    vertical-align: top;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    :last-child {
      text-align: right; 
    }
    width: 20%;
    :nth-child(1) {
        width: 20%;
    }
    :nth-child(2) {
        width: 10%;
    }
    :nth-child(4) {
        width: 30%;
    }
    :nth-child(5) {
        width: 20%;
    }
`;

// const TableDataDiv = styled.div`
//     display: flex;
//     flex-direction: row;
// `;

const AccordionImgStyled = styled.img`
    height: auto;
    width: 10px;
    margin-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    @media(max-width: 768px) {
        padding-right: 0;
    }
`;

const LightGreyTitle = styled.div`
  ${fonts.DMSansRegular};
  color: ${colors.grey};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 28px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 14px;
    line-height: 20px;
    max-width: 940px;
    margin-top: 24px;
  }
`;

const NavyBlueText = styled.div`
  ${fonts.DMSansRegular};
  color: ${colors.navyBlue};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 14px;
    line-height: 20px;
    max-width: 940px;
  }
`;

const TermsContent = styled.div`
  padding: 8px 20px 25px;
  
  @media(min-width: ${breakpoints.md}){
    padding: 17px 20px 28px;
  }
`;

const CardTitleRow = styled.div`
    height: 60px;
    width: 100%;
    border-bottom: ${props => props.mobile ? 'none' : '1px solid #DEE2E8'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.mobile ? 'space-between' : 'flex-start'};
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    @media(max-width: 768px) {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
    }
`;

const CardLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CardLink = styled.button`
    margin-right: 7.5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    ${fonts.DMSansRegular};
    font-size: 14px;
    line-height: 20px;
    height: 60px;
    padding: 0;
    color: ${props => props.active ? colors.blue : colors.grey};
    border-bottom: ${props => props.active ? '3px solid' + colors.blue : '3px solid transparent'};
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
    background: transparent;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border-bottom: 3px solid ${colors.blue};
        outline: none;
        box-shadow: none;
    }
`;

const Card = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    box-sizing: border-box;
    display: ${props => props.active === "false" && 'none'};
    @media(min-width: 1100px){
      padding-left: 20px;
      padding-right: 20px;
    }
`;

const TableBackground = styled.table`
    background: ${colors.backgroundBlue};
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    cursor: default;
    tbody {
        width: 100%;
        tr {
            width: 100%;
            cursor: default;
        }
    }
`;

// const FreightDescription = styled.div`
//     color: ${colors.navyBlue};
//     font-size: 14px;
//     line-height: 20px;
// `;

const StyledTable = styled.table`
    width: 100%;
    tbody {
        width: 100%;
    }
`;

const BookingStatus = styled.div`
  margin-left: auto;
  background: #070D49;
  padding: 6px 14px;
  color: #fff;
  border-radius: 25px;
  font-weight: 300;
  ${props => props.status === 'confirmed' && 'background: #54b4a9;'}
  ${props => props.status === 'canceled' && 'background: #e86c65;'}
`


function TableRow({ item }) {
  const { t } = useTranslation()
  const [isCollapsed, setCollapsed] = useState(true)
  const [view, setView] = useState('details')
  const transportMode = useCWTransportModes()
  const { estimatedDeparture, estimatedArrival } = item
  const orderReferences = useMemo(
    () => item.docsAndCartage?.orderItems
      ?.sort(({ sequence: a }, { sequence: b }) => parseInt(a, 10) > parseInt(b, 10))
      .map(v => v.orderReference)
      .join(', ')
  )

  function formatBookingStatus(status) {
    switch (status) {
    case 'pending':
      return 'Pending'
    case 'confirmed':
      return 'Confirmed'
    case 'canceled':
      return 'Canceled'
    }
  }

  return (
    <>
      <StyledTable>
        <tbody>
          <Root onClick={() => setCollapsed(!isCollapsed)}>
            <TableData>
              {(item.transportMode && transportMode[item.transportMode]) &&
              <Icon src={transportMode[item.transportMode].image}
                    alt={`${t('core.transport_mode')}: ${transportMode[item.transportMode].label}`}/>}
              <DataText>
                {item.shipmentOrg?.consignorFullName}
                <Reference>
                  {item.reference && item.reference}
                </Reference>
                {orderReferences && `PO ${orderReferences}`}
              </DataText>
            </TableData>
            <TableData>
              {item.packingMode !== 'FCL' && item.packLines?.length > 0 && (
                <FreightDetails item={item} />
              )}
              {item.packingMode === 'FCL' && item.bookedContainers?.length > 0 && (
                <ContainerDetails item={item} />
              )}
            </TableData>
            <TableData>
              {item.bookingShippingLine?.codes && (
                <CarrierInfoDefined
                  transportMode={item.transportMode}
                  code={getTransportFromCodes(item.bookingShippingLine?.codes)}
                />
              )}
            </TableData>
            <TableData>
              <ProgressBar
                progress={[
                  {
                    event: 'Pick Up',
                    status: 'incomplete',
                  },
                  {
                    event: 'Departure',
                    location: item.origin,
                    date: estimatedDeparture && formatDate(estimatedDeparture),
                    transport: item.transportMode,
                    status: 'incomplete',
                  },
                  {
                    event: 'Arrival',
                    location: item.destination,
                    date: estimatedArrival && formatDate(estimatedArrival),
                    status: 'incomplete',
                  },
                  {
                    event: 'Delivery',
                    date: estimatedArrival && formatDate(getEstimatedDeliveryDate(estimatedArrival)),
                    status: 'incomplete',
                  },
                ]}
                completionPercentage={0}
              />
            </TableData>
            <TableData>
              <div style={{ display: 'flex' }}>
                <BookingStatus status={item.bookingStatus}>
                  {formatBookingStatus(item.bookingStatus)}
                </BookingStatus>
                <AccordionImgStyled src={!isCollapsed ? AccordionClose : AccordionOpen} alt={t('core.dropdown')}/>
              </div>
            </TableData>
          </Root>
        </tbody>
      </StyledTable>
      {!isCollapsed &&  (
        <TableBackground>
          <tbody>
            <tr>
              <td>
                <CardTitleRow tall>
                  <CardLinks>
                    <CardLink onClick={() => setView('details')} active={view === 'details'}>
                      Basic Info
                    </CardLink>
                    {item.quote && (
                      <CardLink onClick={() => setView('fares')} active={view === 'fares'}>
                        Quote
                      </CardLink>
                    )}
                  </CardLinks>
                </CardTitleRow>
                <Card active={`${view === 'details'}`}>
                  <BasicInfoTable item={item} />
                </Card>
                {item.quote && (
                  <Card active={`${view === 'fares'}`}>
                    <FaresTable fares={item.quote.result.fares} />
                    <TermsContent>
                      {item.quote.result.service && (
                        <>
                          <LightGreyTitle>{t('core.service')}</LightGreyTitle>
                          <NavyBlueText>
                            {item.quote.result.service}
                          </NavyBlueText>
                        </>
                      )}
                    </TermsContent>
                  </Card>
                )}
              </td>
            </tr>
          </tbody>
        </TableBackground>
      )}
      {/*<PopUp handlePopUp={this.handlePopUp} open={this.state.isPopUpOpen} item={item}/>*/}
    </>
  )
}

export default TableRow;
