import React, {Component} from "react"
import styled from "styled-components"
import {Container, Row, Col} from "styled-bootstrap-grid"
import {SelectStyled} from "../core/FormStyles";
import ReactPopup from 'reactjs-popup';
import CloseIcon from "../../resources/img/ui/close-popup.svg"
import Button from "../core/Button";
import {organisationService, userService} from "../../services";
import ErrorMessage from "../core/forms/ErrorMessage";
import SuccessMessage from "../core/forms/SuccessMessage";
import Arrow from "../../resources/img/ui/arrow-dropdown.svg";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const contentStyle = {
  margin: '45px auto auto',
  border: '0',
  height: 'auto',
  overflow: 'hidden',
  position: 'relative',
  width: '64%',
  maxWidth: '925px',
};

const contentStyleMobile = {
  margin: '40px 20px 40px',
  width: '100%',
  border: '0',
  height: 'auto',
  overflow: 'scroll',
  position: 'relative',
};

const overlayStyle = {
  overflowY: 'scroll',
  marginTop: '70px',
  marginLeft: '225px',
  paddingBottom: '45px'
};

const overlayStyleMedium = {
  overflow: 'scroll',
  marginTop: '70px',
  marginLeft: '225px',
};

const overlayStyleMobile = {
  overflow: 'scroll',
  marginTop: '90px'
};

const ColStyled = styled(Col)`
    padding: 0;
`;

const Box = styled.div`
    margin: 0 11px;
`;

const PopUpContent = styled.div`
    padding: 25px 20px 41px 20px;
    @media(min-width: 990px){
        padding: 30px 32px 72px 32px;
    }
`;

const ContainerStyled = styled(Container)`
    width: auto;
    padding-left: 0;
    padding-right: 0;
    @media(min-width: 990px){
            padding-left: 94px;
            padding-right: 93px;
    }
`;

const Close = styled.img`
    float: right;
    cursor: pointer;
`;

const Title = styled.div`
    color: ${colors.navyBlue};
    ${fonts.DMSansBold};
    text-align: center;
    font-size: 22px;
    padding-bottom: 22px;
    padding-top: 32px;
    @media(min-width: 990px){
        padding-top: 32px;
    }
`;

const Subtitle = styled.div`
    ${fonts.UbuntuRegular};
    font-size: 14px;
    line-height: 20px;
    color: ${colors.grey};
    text-align: center;
    padding-bottom: 43px;
`;

const Field = styled.input`
  height: 42px;
  box-sizing: border-box;
  border: 1px solid ${colors.lightBlue};
  margin-bottom: 30px;
  border-radius: 3px;
  width: 100%;
  &:hover,
  &:active,
  &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
  }
  color: ${colors.navyBlue};
  ${fonts.UbuntuRegular};
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  ::-webkit-input-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  ::-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-ms-input-placeholder {
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${colors.white} inset !important;
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${colors.navyBlue} !important;
    color: ${colors.navyBlue};
    ${fonts.UbuntuRegular};
    font-size: 14px;
    padding-left: 15px;
  }
`;

const FieldLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  ${fonts.UbuntuRegular};
  color: ${colors.grey};
  padding-bottom: 5px;
`;

const StyledSuccessMessage = styled(SuccessMessage)`
 text-align: center;
`;

const StyledSelect = styled.select`
    cursor:pointer;
    background: white url(${Arrow}) no-repeat;
    background-position: 95%;
    background-size: 8px;
    padding-left: 15px;
    height: 42px;
    max-width: 326px;
    width: 100%;
    border: 1px solid ${colors.lightBlue};
    margin-bottom: 30px;
    border-radius: 3px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;    
    color: ${colors.navyBlue};
      ${fonts.UbuntuRegular};
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px; 
    @media (max-width: 990px) {
      width: 100%;
      max-width: none;
    }
`;

const initialFormState = {
  id: null,
  email: null,
  password: null,
  organisationCode: null,
  firstName: null,
  lastName: null,
  companyName: null,
  phoneNumber: null,
  companyCountryCode: null,
  registrationSource: null,
  formErrors: null,
  isFormSubmitted: false,
  isFormSuccess: null
};

class UserPopup extends Component {
  state = {
    width: 0,
    height: 0,
    isOpen: false,
    organisations: [],
    ...initialFormState
  };

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.isFormSubmitted === true) {
      return;
    }

    this.setState({
      isFormSubmitted: true
    });

    const fields = [
      'email',
      'password',
      'firstName',
      'lastName'
    ];

    let error = null;

    const isUpdate = this.state.id != null;

    if (!isUpdate) {
      const password = this.state.password;

      if (!password || password.length < 5) {
        error = 'The minimum password length is 5 characters';
      }
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const fieldValue = this.state[field];

      if (isUpdate && field === 'password') {
        continue;
      }

      if (!fieldValue || fieldValue === '') {
        error = 'Please fill in all the required fields.';
      }
    }

    if (!error) {
      const stateData = this.state;

      const apiCall = function(data) {
        if (isUpdate) {
          return userService.update(data);
        }

        return userService.create({
          ...data,
          role: 'ROLE_USER',
        });
      };

      apiCall(stateData)
        .then(() => {
          this.setState({
            isFormSubmitted: false,
            isFormSuccess: true,
            formErrors: error
          });
        })
        .catch((err) => {
          error = 'There was an error submitting the form. Please try again';
          this.setState({
            isFormSubmitted: false,
            isFormSuccess: false,
            formErrors: error
          });
          console.log(err)
        });
    } else {
      this.setState({
        isFormSubmitted: false,
        formErrors: error
      });
    }
  }

  handleChange(event) {
    let target = event.target;

    let stateObject = {};
    stateObject[target.name] = target.value;

    this.setState(stateObject);
  }

  closePopup(e) {
    e.preventDefault();

    this.setState(initialFormState);

    this.closeModal();
  }

  closeModal() {
    this.props.handlePopup(false);
  }

  getContentStyle = () => {
    if (this.state.width >= 990) {
      return contentStyle;
    } else if (this.state.width < 990) {
      return contentStyleMobile;
    }
  };

  componentDidMount = () => {
    const user = this.props.user;

    if (user) {
      this.setState({
        id: user.id,
        email: user.email,
        organisationCode: user.organisationCode,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: user.companyName,
        phoneNumber: user.phoneNumber,
        companyCountryCode: user.companyCountryCode,
        registrationSource: user.registrationSource
      });
    }
    organisationService.list()
      .then(organisations => this.setState({
        ...this.state,
        organisations,
      }))
      .catch(console.error)

    if (typeof window !== 'undefined') {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
  };

  componentWillUnmount = () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  };
  updateWindowDimensions = () => {
    if (typeof window !== 'undefined') {
      this.setState({width: window.innerWidth, height: window.innerHeight});
    }
  };

  render() {
    const {isOpen} = this.props;
    const {formErrors, isFormSuccess} = this.state;

    const isUpdate = this.state.id != null;

    return (
      <ReactPopup
        open={isOpen}
        onClose={() => this.closeModal()}
        closeOnDocumentClick
        overlayStyle={this.state.width >= 990 ? overlayStyle : this.state.width >= 768 ? overlayStyleMedium : overlayStyleMobile}
        contentStyle={this.getContentStyle()}>
        <PopUpContent>
          <Close onClick={(e) => this.closePopup(e)} src={CloseIcon}/>
          <Title>{isUpdate ? "Update" : "Create New"} User</Title>
          <Subtitle>Users have access to MFO platform.</Subtitle>
          {isFormSuccess ? <>
              <ContainerStyled>
                <StyledSuccessMessage className={'styledSuccessMessage'}>
                  User {isUpdate ? "updated" : "created"} successfully!
                </StyledSuccessMessage>
              </ContainerStyled>
            </>
            :
            <form onSubmit={this.handleSubmit.bind(this)}>
              <ContainerStyled>
                <Row>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>Email Address</FieldLabel>
                      <Field placeholder="john.doe@myfreightonline.com" name={"email"}
                             value={this.state.email}
                             onChange={this.handleChange.bind(this)}/>
                    </Box>
                  </ColStyled>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>Password</FieldLabel>
                      <Field type={"password"} placeholder="**********" name={"password"}
                             onChange={this.handleChange.bind(this)}/>
                    </Box>
                  </ColStyled>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>Company Name</FieldLabel>
                      <Field placeholder="MFO"
                             name={"companyName"}
                             value={this.state.companyName}
                             onChange={this.handleChange.bind(this)}/>
                    </Box>
                  </ColStyled>
                  <ColStyled lg={6}>
                    {isUpdate &&
                    <Box>
                      <FieldLabel>Organisation Code</FieldLabel>
                      <SelectStyled
                        name="organisationCode"
                        value={this.state.organisationCode}
                        onChange={this.handleChange.bind(this)}
                      >
                        {this.state.organisations.map((organisation) => (
                          <option value={organisation.code} key={organisation.id}>
                            {organisation.name} ({organisation.code})
                          </option>
                        ))}
                      </SelectStyled>
                    </Box>
                    }
                  </ColStyled>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>First Name</FieldLabel>
                      <Field placeholder="John"
                             name={"firstName"}
                             value={this.state.firstName}
                             onChange={this.handleChange.bind(this)}/>
                    </Box>
                  </ColStyled>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>Last Name</FieldLabel>
                      <Field placeholder="Doe"
                             name={"lastName"}
                             value={this.state.lastName}
                             onChange={this.handleChange.bind(this)}/>
                    </Box>
                  </ColStyled>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>Phone Number</FieldLabel>
                      <Field placeholder="075123456789"
                             name={"phoneNumber"}
                             value={this.state.phoneNumber}
                             onChange={this.handleChange.bind(this)}/>
                    </Box>
                  </ColStyled>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>Country</FieldLabel>
                      <StyledSelect
                        name={"companyCountryCode"}
                        value={this.state.companyCountryCode}
                        onChange={this.handleChange.bind(this)}>
                        <option value="">Please select</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                        <option value="FR">France</option>
                        <option value="DE">Germany</option>
                        <option value="CN">China</option>
                        <option value="BE">Belgium</option>
                      </StyledSelect>
                    </Box>
                  </ColStyled>
                  <ColStyled lg={6}>
                    <Box>
                      <FieldLabel>Registration Source</FieldLabel>
                      <StyledSelect name={"registrationSource"}
                      value={this.state.registrationSource}
                                    onChange={this.handleChange.bind(this)}>
                        <option value="">Please select</option>
                        <option value="search">Search engine</option>
                        <option value="linkedin">LinkedIn</option>
                        <option value="email">Email</option>
                        <option value="recommendation">Colleague recommendation</option>
                        <option value="mfo">MFO team</option>
                      </StyledSelect>
                    </Box>
                  </ColStyled>
                </Row>
                <Button text={isUpdate ? "Update User" : "Create User"} type="submit" onClick={this.handleSubmit.bind(this)}/>
                <ErrorMessage>
                  {formErrors && formErrors}
                </ErrorMessage>
              </ContainerStyled>
            </form>
          }
        </PopUpContent>
      </ReactPopup>
    )
  }
}

export default UserPopup;
