import 'react-day-picker/lib/style.css';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import GlobalStyle from '~/styles/global';
import Loader from "~/components/core/Loader";
import AdminSideBar from "./AdminSideBar";
import AdminHeader from "./AdminHeader";
import AdminMobileNav from "./AdminMobileNav";
import {userService} from '~/services'
import {navigate} from "gatsby";

function AdminLayout({ loading, children }) {
  useEffect(() => {
    userService.getMe().then((user) => {
      if (user.role !== 'ROLE_ADMIN') {
        return navigate('/')
      }
    })
  },[])

  return (
    <>
      <AdminHeader/>
      <AdminSideBar/>
      <GlobalStyle/>
      {children}
      <AdminMobileNav/>
      <Loader loading={loading}/>
    </>
  )
}

AdminLayout.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default AdminLayout
