import ErrorMessage from "./forms/ErrorMessage";
import styled, {css} from "styled-components";
import React from "react";
import Unchecked from "../../resources/img/ui/unchecked.svg";
import Checked from "../../resources/img/ui/checked.svg";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import Arrow from "../../resources/img/ui/arrow-dropdown.svg";

const StyledField = styled.input`
  height: 42px;
  width: 326px;
  box-sizing: border-box;
  border: 1px solid ${colors.lightBlue};
  border-radius: 3px;
  color: ${colors.navyBlue};
  ${fonts.UbuntuRegular};
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  
  ::-webkit-input-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  ::-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  :-ms-input-placeholder {
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  :-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${colors.white} inset !important;
  }
  
  :-webkit-autofill {
    -webkit-text-fill-color: ${colors.navyBlue} !important;
    color: ${colors.navyBlue};
    ${fonts.UbuntuRegular};
    font-size: 14px;
    padding-left: 15px;
  }
  
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const CheckBox = styled.input`
  background: transparent url(${Unchecked}) no-repeat;
  background-size: 14px 14px;
  border: none;
  padding: 0 20px;
  background-position-x: left;
  background-position-y: center;
  cursor: pointer;
  
  &:checked {
    background: transparent url(${Checked}) no-repeat;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

const CheckBoxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fonts.Cabin};
  font-size: 12px;
  color: ${colors.navyBlue};
  line-height: 20px;
`;

const StyledSelect = styled.select`
  cursor:pointer;
  background: white url(${Arrow}) no-repeat 95%;
  background-size: 8px;
  height: 42px;
  width: 326px;
  border: 1px solid ${colors.lightBlue};
  border-radius: 3px;
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;    
  color: ${colors.navyBlue};
  ${fonts.UbuntuRegular};
  font-size: 14px;
  padding: 0 15px;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }

  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

export function FormInput({ input, meta, ...props }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <StyledField {...props} {...input} />
      {(meta.touched && meta.error) && (
        <ErrorMessage css={css`margin-top: 6px`}>
          {meta.error}
        </ErrorMessage>
      )}
    </div>
  )
}

export function FormSelect({ input, meta, children, ...props }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <StyledSelect {...props} {...input}>
        {children}
      </StyledSelect>
      {(meta.touched && meta.error) && (
        <ErrorMessage css={css`margin-top: 6px`}>
          {meta.error}
        </ErrorMessage>
      )}
    </div>
  )
}

export function FormCheckbox({ input, meta, children, ...props }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <CheckBoxContainer>
        <CheckBox {...props} {...input} type="checkbox"/>
        {children}
      </CheckBoxContainer>
      {(meta.touched && meta.error) && (
        <ErrorMessage css={css`margin-top: 6px`}>
          {meta.error}
        </ErrorMessage>
      )}
    </div>
  )
}
