import {createSlice} from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
  },
  reducers: {
    setNotifications(state, action) {
      state.notifications = action.payload
    },
    addNotification(state, action) {
      state.notifications.push(action.payload)
    },
    removeNotification(state, action) {
      state.notifications = state.organisations.filter(v => v.id !== action.payload)
    },
    updateNotification(state, action) {
      state.notifications = state.notifications.map(v => v.id === action.payload.id ? action.payload : v)
    },
  },
})

export const {setNotifications, addNotification, removeNotification, updateNotification} = notificationSlice.actions

export default notificationSlice.reducer
