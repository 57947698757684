import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Card from '../components/bookings/Card';
import PrivateRoute from "../router/PrivateRoute"
import {bookingService} from "../services";
import {navigate} from "gatsby";
import { withTranslation } from "react-i18next";

const Background = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  left: 0;
  top: 105px;
  width: 100%;
  padding: 32px 20px 40px 20px;
  margin-bottom: 180px;

  @media(min-width: 768px){
    padding: 46px 35px 76px 35px;
    top: 70px;
    left: 225px;
    width: calc(100% - 225px);
  }
`;

const MAX_ITEM_PER_PAGE = 15

function BookingsPage({ location, t }) {
  const path = location?.pathname
  const { page, transports, search } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const rawTransports = searchParams.get('transports')
    return {
      page: parseInt(searchParams.get('page'), 10),
      transports: rawTransports?.split(',') ?? [],
      search: searchParams.get('q'),
    }
  }, [location.search])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)

  useEffect(() => {
    if (isNaN(page)) {
      changeSearchParams('page', 1)
      return
    }

    setLoading(true)
    bookingService.list({
      page,
      transportModes: transports.length ? transports : undefined,
      search,
    })
      .then((data) => setData(data))
      .catch(() => alert('error'))
      .finally(() => setLoading(false))
  }, [page, transports, search])

  const changeSearchParams = (key, value) => {
    const searchParams = new URLSearchParams(location.search)
    if (value === undefined) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }
    return navigate(`?${searchParams.toString()}`)
  }

  const toggleTransport = (transport) => {
    if (transports.some(v => v === transport)) {
      const newTransports = transports.filter(v => v !== transport)
      return changeSearchParams('transports', newTransports.length ? newTransports.join(',') : undefined)
    }
    return changeSearchParams('transports', [...transports, transport])
  }

  return (
    <Layout loading={loading}>
      <SEO title={t('core.bookings')} path={path}/>
      <Background>
        <Card
          title={t('bookings.title')}
          data={data?.items ?? []}
          count={data?.meta?.count ?? 0}
          pageStart={((data?.meta?.currentPage ?? 0) - 1) * MAX_ITEM_PER_PAGE}
          pageEnd={(data?.meta?.currentPage ?? 0) * MAX_ITEM_PER_PAGE}
          pageNumber={data?.meta?.currentPage}
          nextPage={data?.meta?.nextPage}
          previousPage={data?.meta?.previousPage}
          changePage={(page) => changeSearchParams('page', page)}
          transportFilters={transports}
          addTransportFilter={toggleTransport}
          currentSearch={search}
          updateCurrentSearch={(search) => changeSearchParams('q', search)}
        />
      </Background>
    </Layout>
  )
}

function bookings(props) {
  return <PrivateRoute component={BookingsPage} {...props}/>
}

export default withTranslation()(bookings);
