import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Card from '../components/invoices/Card';
import PrivateRoute from "../router/PrivateRoute"
import {transactionService} from "../services";
import {navigate} from "gatsby";
import { withTranslation } from "react-i18next";

const Background = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  left: 0;
  top: 105px;
  width: 100%;
  padding: 31px 20px 85px 20px;
  margin-bottom: 20px;

  @media(min-width: 767px){
    width: calc(100% - 225px);
    left: 225px;
    top: 70px;
    padding: 46px 35px 89px 35px;
    margin-bottom: 0;
  }
`;

const MAX_ITEM_PER_PAGE = 15;

function InvoicesPage({ location, t }) {
  const path = location?.pathname
  const { perPage, page } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      perPage: parseInt(searchParams.get('perPage'), 10),
      page: parseInt(searchParams.get('page'), 10),
    }
  }, [location.search]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);

  function changeSearchParams(params) {
    const searchParams = new URLSearchParams(location.search);
    params.forEach(([key, value]) => {
      if (value === undefined) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, value)
      }
    });
    return navigate(`?${searchParams.toString()}`)
  }

  useEffect(() => {
    if (isNaN(page) || isNaN(perPage)) {
      changeSearchParams([['page', 1], ['perPage', 15]]);
      return
    }

    setLoading(true);
    transactionService.list({
      page,
      perPage,
    })
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }, [page, perPage]);

  return (
    <Layout loading={loading}>
      <SEO title={t('core.invoices')} path={path}/>
      <Background>
        <Card
          data={data?.items ?? []}
          count={data?.meta?.count ?? 0}
          pageStart={((data?.meta?.currentPage ?? 0) - 1) * (data?.meta?.perPage ?? 0)}
          pageEnd={(data?.meta?.currentPage ?? 0) * (data?.meta?.perPage ?? 0)}
          pageNumber={data?.meta?.currentPage}
          nextPage={data?.meta?.nextPage}
          previousPage={data?.meta?.previousPage}
          perPage={data?.meta?.perPage ?? 0}
          changePerPage={(perPage) => changeSearchParams([['perPage', perPage], ['page', 1]])}
          changePage={(page) => changeSearchParams([['page', page]])}
        />
      </Background>
    </Layout>
  )
}

function invoices(props) {
  return <PrivateRoute component={InvoicesPage} {...props}/>
}

export default withTranslation()(invoices);
