import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import AccordionOpen from '../../resources/img/ui/accordion-open.svg';
import AccordionClose from '../../resources/img/ui/accordion-close.svg';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";


const TableRow = styled.tr`
  border-top:${props => props.dropdown ? 'none' : '1px solid' + colors.lightBlue};
  height: ${props => props.header ? '60px' : '42px'};
  vertical-align: middle;
  
  :last-child {
    border-bottom: 1px solid ${colors.lightBlue};
  }
  
  :nth-child(odd) {
    background: ${props => props.dropdown && colors.tableGrey};
  }
  
  :nth-child(even) {
    background: ${props => props.dropdown && colors.white};
  }
  
  @media(max-width: 1100px) {
    height: ${props => props.header ? '60px' : '62px'};
    
    :nth-child(2) {
      border-top: none;
    }
  }
  
`;

const TableData = styled.td`
  ${fonts.UbuntuRegular};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.navyBlue};
  vertical-align: middle;
  padding-left: ${props => props.inner && '15px'};
  box-sizing: border-box;
  font-weight: ${props => props.total === true && 'bold'};
  cursor: ${props => props.total === true ? 'default' : 'pointer'};
  width: 10%;
  padding: 0 12px;

  :last-child {
    text-align: right;
  }
  
  @media(max-width: 1100px) {
    display: ${props => props.desktop && 'none'};
    font-size: ${props => props.inner && '12px'};
    font-size: ${props => props.inner && '12px'};
    line-height: ${props => props.inner && '18px'};
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  @media(min-width: 1101px) {
    display: ${props => props.mobile && 'none'};
  }

  :first-child {
    width: 20%;
  }
  
  :last-child {
    width: 40px;
  }
  
  ${props => props.right && css`
    text-align: right;
  `}
`;

const AccordionImgStyled = styled.img`
  height: auto;
  width: 10px;
  margin-left: 10px;
  padding-right: 15px;
  cursor: pointer;
  
  @media(max-width: 768px) {
    padding-right: 0;
  }
`;

const SpaceDiv = styled.div`
  height: 30px;
  width: 100%;
`;


function InnerTableRow({ fare }) {
  const [isCollapsed, setCollapsed] = useState(false)

  return (
    <>
      <TableRow onClick={() => setCollapsed(!isCollapsed)} header>
        <TableData>
          {fare.type}
        </TableData>
        <TableData />
        <TableData right>
          {formatCurrency(fare, fare.amount)}
        </TableData>
        <TableData right>
          {fare.currency.toUpperCase()}
        </TableData>
        <TableData right>
          {fare.details && (
            <AccordionImgStyled
              src={isCollapsed ? AccordionClose : AccordionOpen}
              alt="Dropdown handling image"
            />
          )}
        </TableData>
      </TableRow>
      {(isCollapsed && fare.details) && (
        <>
          {fare.details.sort(({ order: a }, { order: b }) => a - b).map((detail, index) =>
            <TableRow key={index} dropdown>
              <TableData dropdown mobile inner>
                {detail.type} <br />
                {detail.description}
              </TableData>
              <TableData inner desktop>
                {detail.type}
              </TableData>
              <TableData mobile/>
              <TableData inner desktop>
                {detail.isPriceMissing ? <span style={{ color: 'red' }}>Price is missing</span> : detail.description}
              </TableData>
              <TableData inner right>
                {detail.isPriceMissing ? '?' : formatCurrency(detail, detail.amount)}
              </TableData>
              <TableData inner right>
                {detail.isPriceMissing ? '?' : detail.currency.toUpperCase()}
              </TableData>
              <TableData/>
            </TableRow>
          )}
          <SpaceDiv />
        </>
      )}
    </>
  )
}

function formatCurrency(fare, amount) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: fare.currency })
    .format(amount)
}

export default InnerTableRow
