import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styled from 'styled-components';
import { setOrganisations, removeOrganisation } from '~/store/organisation'
import AdminLayout from '~/components/admin/AdminLayout';
import SEO from '~/components/core/Seo';
import Card from '~/components/core/Card';
import PrivateRoute from "~/router/PrivateRoute"
import Button from "~/components/core/Button";
import Table from "~/components/core/Table";
import CreateOrganisationPopup from '~/components/admins/CreateOrganisationPopup'
import BinIcon from "~/resources/img/icons/bin-icon.svg"
import EditIcon from "~/resources/img/icons/edit-icon.svg"
import {adminService, organisationService} from "~/services";
import colors from "~/styles/colors";
import fonts from "~/styles/fonts";
import EditOrganisationPopup from "~/components/admins/EditOrganisationPopup";

const Background = styled.div`
  width: calc(100% - 225px);
  left: 225px;
  top: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 35px 80px;
  box-sizing: border-box;
  
  @media(max-width: 767px) {
    left: 0;
    top: 105px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; 
    padding-bottom: 105px;      
  }
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  
  img {
    padding-right: 9px;
    height: 14px;
    width: 14px;
  }
  
  span {
    text-transform: uppercase;
    ${fonts.DMSansBold};
    color: ${colors.navyBlue};
    font-size: 12px;
  }
  
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  background: transparent;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`

const BinButton = styled.button`
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  background: transparent;
  margin-right: 11px;
  margin-left: 30px;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
  
  img {
    height: 16px;
    width: 16px;
  }
`

const Name = styled.div`
  margin-left: 20px;
`

const Desktop = styled.div`
  display: none;
  width: 100%;

  @media(min-width: 767px){
    display: block;
  }
`;

const Mobile = styled.div`
  display: block;
  width: 100%;

  @media(min-width: 767px){
    display: none;
  }
`;

const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

function Organisations({ location }) {
  const path = location?.pathname
  const organisations = useSelector(v => v.organisation.organisations)
  const dispatch = useDispatch()
  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false)
  const [currentOrganisation, setCurrentOrganisation] = useState(null)

  useEffect(() => {
    organisationService.list()
      .then((data) => dispatch(setOrganisations(data)))
      .catch(console.error)
  }, [])

  function destroyOrganisation(item) {
    organisationService.destroy({ id: item.id })
      .then(() => dispatch(removeOrganisation(item.id)))
      .catch(console.error)
  }

  const tableContent = <Table
    headers={[]}
    data={organisations}
    rowTemplate={
      (item) => [
        <Block>
          <Name>{item.name} ({item.code})</Name>
        </Block>,
        <Block>
          <EditButton onClick={() => setCurrentOrganisation(item)}>
            <img src={EditIcon} alt="Edit Icon"/>
            <span>Edit</span>
          </EditButton>
          <BinButton onClick={() => destroyOrganisation(item)}>
            <img src={BinIcon} alt="Bin Icon"/>
          </BinButton>
        </Block>
      ]
    }
  />;

  return (
    <AdminLayout>
      <SEO title="Organisation Management" path={path}/>
      <Background>
        <Mobile>
          <Card title="Organisations" titleElements={[
            <Button
              color="blue"
              text="Create new"
              fullwidth={true}
              onClick={() => setCreatePopupOpen(true)}
            />,
          ]}/>
          <Card>
            {tableContent}
          </Card>
        </Mobile>
        <Desktop>
          <Card title="Organisations" titleElements={[
            <Button
              color="blue"
              text="Create new"
              onClick={() => setCreatePopupOpen(true)}
            />,
          ]}>
            {tableContent}
          </Card>
        </Desktop>
        {isCreatePopupOpen && (
          <CreateOrganisationPopup
            close={() => setCreatePopupOpen(false)}
          />
        )}
        {currentOrganisation && (
          <EditOrganisationPopup
            organisation={currentOrganisation}
            close={() => setCurrentOrganisation(null)}
          />
        )}
      </Background>
    </AdminLayout>
  )
}

function organisationsRoute(props) {
  return <PrivateRoute component={Organisations} {...props}/>
}

export default organisationsRoute;
