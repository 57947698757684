import React, {Component} from "react";
import AccordionOpen from '../../resources/img/ui/accordion-open.svg';
import AccordionClose from '../../resources/img/ui/accordion-close.svg';
import {ShipmentLink, Card, CardHeader, CardHeaderText, AccordionImgStyled} from "./DashboardStyle";
import {withTranslation} from "react-i18next";

class InvoicesToggle extends Component {
  state = {
    isCollapsed: true
  };

  toggleAccordion() {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    })
  }

  render() {
    const { item, link, t } = this.props;

    return (
      <Card open={this.state.isCollapsed}>
        <CardHeader onClick={() => this.toggleAccordion()}>
          {item.transactionNum && (
            <CardHeaderText id="true">
              <ShipmentLink>
                {item.transactionNum}
              </ShipmentLink>
            </CardHeaderText>
          )}
          <AccordionImgStyled src={!this.state.isCollapsed ? AccordionClose : AccordionOpen}
                              alt={t('core.dropdown')}/>
        </CardHeader>
        {!this.state.isCollapsed && this.props.children}
      </Card>
    )
  }
}

export default withTranslation()(InvoicesToggle);
