import {combineReducers} from 'redux'
import user, * as UserSelectors from './user'
import organisation from '../organisation'
import notification from '../notification'

const USER = 'USER';

const reducers = {
  [USER]: user,
  organisation,
  notification,
};

export default combineReducers(reducers)

export function isLoggedIn (store) {
  return UserSelectors.isLoggedIn(store[USER]);
}
