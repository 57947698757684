import React, {useMemo} from 'react';
import styled from 'styled-components';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import ContainerIcon from '../../resources/img/icons/container-icon.svg';
import {FreightImg, FreightType} from "./BookingsStyle";
import {useTranslation} from "react-i18next";

const FreightCount = styled.div`
    display: flex;
    flex-direction: column;
    ${fonts.UbuntuRegular};
    font-size: 12px;
    line-height: 19px;
    color: ${colors.navyBlue};
`;

const ContainerBlock = styled.div`
`;

const ORDERED_CONTAINERS = ['20GP', '40GP', '40HC', '45HC']

function ContainerDetails({ item }) {
  const { t } = useTranslation()
  const containers = useMemo(
    () => item.bookedContainers?.sort(
      (a, b) =>
        ORDERED_CONTAINERS.indexOf(a.reference.code.trim()) - ORDERED_CONTAINERS.indexOf(b.reference.code.trim()),
    ) ?? [],
    [item],
  )

  return containers.length > 0 ? (
    <FreightCount>
      {containers.map((container, index) => (
        <ContainerBlock key={index}>
          {container.containerCount > 0 && (
            <FreightType key={index}>
              <FreightImg src={ContainerIcon} alt={t('bookings.container_icon')}/>
              <>{container.containerCount}x {container.reference.code}</>
            </FreightType>
          )}
        </ContainerBlock>
      ))}
    </FreightCount>
  ) : null
}

export default ContainerDetails;
