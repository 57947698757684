import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import Button from '../core/Button';
import {Link} from "gatsby";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import {Form, Field} from "react-final-form";
import { FormInput } from '../core/Form'
import { authService } from '~/services'
import SuccessMessage from "../core/forms/SuccessMessage";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../core/forms/ErrorMessage";
import {withTranslation} from "react-i18next";

const CardBackground = styled.div`
  margin-top: 20px;
  background: ${colors.white};
  padding: 32px;
  width: 500px;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media(max-width: 650px) {
    width: calc(100% - 40px);
  }
`;

const CardTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  color: ${colors.navyBlue};
  padding-bottom: 25px;
  ${fonts.DMSansBold};
`;

const StyledForm = styled.div`
  @media (max-width: 650px) {
    width: calc(100% - 40px);
  }
`;

const FieldLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey};
  padding-bottom: 5px;
  ${fonts.UbuntuRegular};

  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Column = styled.div`
  margin-left: 12.5px;
  margin-right: 12.5px;
  width: calc(50% - 25px);
  
  @media(max-width: 926px) {
    width: calc(326px + 25px);
  }
  
  @media (max-width: 650px) {
    width: calc(100%);
    margin-left: 0;
    margin-right: 0;
  }
`;

const SpaceDiv = styled.div`
  padding-top: 15px;
`;

const ButtonBox = styled.div`
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;

  @media(min-width: 649px){
    padding: 0 86px 0 89px;
  }
`;

const GreyLink = styled(Link)`
  display: block;
  margin-top: 24px;
  text-align: center;
  color: ${colors.grey};
  font-size: 12px;
  ${fonts.UbuntuRegular};
`

const StyledFormDescription = styled.div`
  padding: 0 32px 24px;
  text-align: center;
  font-size: 14px;
  max-width: 270px;
  ${fonts.UbuntuRegular};
`

function ResetPasswordForm({ token, t }) {
  const [isFormSubmitted, setFormSubmitted] = useState(false)

  function validate(values) {
    const errors = {}

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t('form.password_does_not_match');
    }

    if (values.password?.length < 5) {
      errors.password = t('form.password_minimum_length');
    }

    ['password', 'confirmPassword'].forEach(field => {
      if (!values[field]) {
        errors[field] = t('core.required');
      }
    })

    return errors
  }

  function handleSubmit(values) {
    return authService.resetPassword({
      ...values,
      token,
    })
      .then(() => setFormSubmitted(true))
      .catch((err) => {
        const error = err?.response?.data?.message ?? t('form.response_error');
        return { [FORM_ERROR]: error }
      })
  }

  return isFormSubmitted ? (
    <CardBackground>
      <CardTitle>{t('reset_password.title')}</CardTitle>
      <SuccessMessage>{t('reset_password.success_message')}</SuccessMessage>
      <StyledForm>
        <Button text="Sign In" color="blue" signin="true" link="/login"/>
      </StyledForm>
    </CardBackground>
  ) : (
    <CardBackground>
      <CardTitle>
          {t('reset_password.change_your_password')}
      </CardTitle>
      <StyledFormDescription>
          {t('reset_password.choose_new_password')}
      </StyledFormDescription>
      <Form
        validate={validate}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitError }) => (
          <>
            {submitError && (
              <ErrorMessage css={css`margin: 0 0 24px; text-align: center`}>
                {submitError}
              </ErrorMessage>
            )}
            <StyledForm onSubmit={handleSubmit}>
              <Row>
                <Column>
                  <FieldLabel htmlFor="password">{t('register.password')}</FieldLabel>
                  <Field
                    component={FormInput}
                    type="password"
                    id="password"
                    name="password"
                  />
                </Column>
              </Row>
              <Row>
                <Column>
                  <FieldLabel htmlFor="confirmPassword">{t('register.confirm_password')}</FieldLabel>
                  <Field
                    component={FormInput}
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                  />
                </Column>
              </Row>
            </StyledForm>
            <SpaceDiv />
            <ButtonBox>
              <Button
                text={t('reset_password.change_password')}
                onClick={handleSubmit}
                color="blue"
                signin="true"
                type="submit"
              />
            </ButtonBox>
          </>
        )}
      />
    </CardBackground>
  )
}

export default withTranslation()(ResetPasswordForm);
