import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Card from '../components/shipments/Card';
import PrivateRoute from "../router/PrivateRoute"
import {shipmentService} from "../services";
import {navigate} from "gatsby";
import { withTranslation } from "react-i18next";

const Background = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  left: 0;
  top: 105px;
  width: 100%;
  padding: 31px 20px 125px 20px;

  @media(min-width: 767px){
    width: calc(100% - 225px);
    left: 225px;
    top: 70px;
    padding: 46px 35px 68px 35px;
  }
`;

function ShipmentsPage({ location, t }) {
  const path = location?.pathname
  const { perPage, page, transports, status } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const rawTransports = searchParams.get('transports');
    return {
      perPage: parseInt(searchParams.get('perPage'), 10),
      page: parseInt(searchParams.get('page'), 10),
      transports: rawTransports?.split(',') ?? [],
      status: searchParams.get('status'),
    }
  }, [location.search]);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (isNaN(page) || isNaN(perPage)) {
      changeSearchParams([['page', 1], ['perPage', 15]]);
      return
    }

    setLoading(true);
    shipmentService.list({
      page,
      perPage,
      status,
      transportModes: transports.length ? transports : undefined,
    })
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }, [page, perPage, status, transports]);

  const changeSearchParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    params.forEach(([key, value]) => {
      if (value === undefined) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, value)
      }
    });
    return navigate(`?${searchParams.toString()}`)
  };

  const toggleTransport = (transport) => {
    return changeSearchParams([['transports', transports.includes(transport) ? undefined : [transport]], ['page', 1]])
  };

  const toggleStatus = (newStatus) => {
    return changeSearchParams([
      [
        'status',
        newStatus !== status ? newStatus : undefined,
      ],
      ['page', 1],
    ])
  };

  return (
    <Layout loading={loading}>
      <SEO title={t('core.shipments')} path={path}/>
      <Background>
        <Card
          title={t('shipments.title')}
          data={data?.items ?? []}
          count={data?.meta?.count ?? 0}
          pageStart={((data?.meta?.currentPage ?? 0) - 1) * (data?.meta?.perPage ?? 0)}
          pageEnd={(data?.meta?.currentPage ?? 0) * (data?.meta?.perPage ?? 0)}
          pageNumber={data?.meta?.currentPage}
          nextPage={data?.meta?.nextPage}
          previousPage={data?.meta?.previousPage}
          perPage={data?.meta?.perPage ?? 0}
          changePerPage={(perPage) => changeSearchParams([['perPage', perPage], ['page', 1]])}
          changePage={(page) => changeSearchParams([['page', page]])}
          statusFilter={status}
          addStatusFilter={toggleStatus}
          transportFilters={transports}
          addTransportFilter={toggleTransport}
        />
      </Background>
    </Layout>
  )
}

function shipments(props) {
  return <PrivateRoute component={ShipmentsPage} {...props}/>
}

export default withTranslation()(shipments);
