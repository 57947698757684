import React, {Component} from 'react';
import { Link } from 'gatsby'
import styled from 'styled-components';
import Button from '../core/Button';
import ErrorMessage from "../core/forms/ErrorMessage";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import { withTranslation } from "react-i18next";

const CardBackground = styled.div`
    background: ${colors.white};
    height: 431px;
    width: 500px;
    border-radius: 4px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media(max-width: 650px) {
        width: calc(100% - 40px);
    }
`;

const CardTitle = styled.div`
    ${fonts.DMSansBold};
    font-size: 20px;
    line-height: 26px;
    color: ${colors.navyBlue};
    font-weight: bold;
    padding-bottom: 25px;
`;

const Form = styled.form`
  @media (max-width: 650px) {
    width: calc(100% - 40px);
  }
`;

const FieldLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 16px;
  ${fonts.UbuntuRegular};
  color: ${colors.grey};
  padding-bottom: 5px;
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Field = styled.input`
  height: 42px;
  width: 326px;
  box-sizing: border-box;
  border: 1px solid ${colors.lightBlue};
  margin-bottom: 30px;
  border-radius: 3px;
  background: ${colors.white};
  &:hover,
  &:active,
  &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
  }
  color: ${colors.navyBlue};
  ${fonts.UbuntuRegular};
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  ::-webkit-input-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  ::-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-ms-input-placeholder {
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${colors.white} inset !important;
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${colors.navyBlue} !important;
    color: ${colors.navyBlue};
    ${fonts.UbuntuRegular};
    font-size: 14px;
    padding-left: 15px;
  }
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Column = styled.div`
  width: calc(50% - 25px);
  @media(max-width: 926px) {
    width: calc(326px + 25px);
  }
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const SpaceDiv = styled.div`
    padding-top: 15px;
`;

const GreyLink = styled(Link)`
  display: block;
  margin-top: 24px;
  text-align: center;
  color: ${colors.grey};
  font-size: 12px;
  ${fonts.UbuntuRegular};
`

class LoginForm extends Component {
  state = {
    email: null,
    password: null
  };

  handleSubmit(e) {
    e.preventDefault();

    this.props.loginAction(this.state.email, this.state.password);
  }

  handleChange(event) {
    let target = event.target;

    let stateObject = {};
    stateObject[target.name] = target.value;

    this.setState(stateObject);
  }

  render() {
    const {user, t} = this.props;
    const isLoginFailed = user.loginFailed;

    return (
      <CardBackground>
        <CardTitle>{t('login.sign_in')}</CardTitle>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Row>
            <Column>
              <FieldLabel htmlFor={'email'}>{t('login.email_address')}</FieldLabel>
              <Field placeholder="john@myfreightonline.com"
                     name={"email"}
                     id={'email'}
                     onChange={this.handleChange.bind(this)}/>
            </Column>
            <Column>
              <FieldLabel htmlFor={'password'}>{t('login.password')}</FieldLabel>
              <Field type="password"
                     placeholder="**********"
                     name={"password"}
                     id={'password'}
                     onChange={this.handleChange.bind(this)}/>
            </Column>
          </Row>
          <SpaceDiv/>
          <Button text={t('login.sign_in')} color={'blue'} signin={'true'} onClick={this.handleSubmit.bind(this)}/>
          <GreyLink href="/forgot-password">
            {t('login.forgot_password')}
          </GreyLink>
        </Form>
        <ErrorMessage>
          {isLoginFailed === true && t('login.login_failed')}
        </ErrorMessage>
      </CardBackground>
    )
  }
}

export default withTranslation()(LoginForm);
