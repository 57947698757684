import React from 'react';
import {formatDate} from "../../services/core/dateHelper";
import {TableContainerMobile, TableBody, TableHeader, TableData} from "./DashboardStyle";
import ShipmentStatusName from "../core/ShipmentStatusName";

const ShipmentsMobile = (props) => {
  const {headers, item} = props;

  return (
    <TableContainerMobile>
      <TableBody>
        <tr>
          {headers
            .filter(header => !header.includes("ID"))
            .map((header, index) =>
              <TableHeader key={index} headers={headers.length - 1}>{header}</TableHeader>
            )}
        </tr>
        <tr>
          <TableData>
            {item.origin} <br/>
            {item.shippedOnBoardDate ? formatDate(item.shippedOnBoardDate) : (item.estimatedDeparture && formatDate(item.estimatedDeparture))}
          </TableData>
          <TableData>
            {item.destination && item.destination} <br/>
            {item.estimatedArrival && formatDate(item.estimatedArrival)}
          </TableData>
          <TableData>
            <ShipmentStatusName status={item.status} />
          </TableData>
        </tr>
      </TableBody>
    </TableContainerMobile>
  )
};

export default ShipmentsMobile;
