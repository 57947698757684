import api from "./api";

export async function list({ page = 1, perPage, status, transportModes }) {
  const res = await api.get('/shipments', {
    params: {
      page,
      perPage,
      status,
      transportModes,
    }
  })
  return res.data
}

export async function get({ reference }) {
  const res = await api.get(`/shipments/${reference}`)
  return res.data
}

export async function getDocuments({ reference }) {
  const res = await api.get(`/shipments/${reference}/documents`)
  return res.data
}

export async function getTransactions({ reference }) {
  const res = await api.get(`/shipments/${reference}/transactions`)
  return res.data
}

export async function downloadDocument({ reference, filename, index }) {
  const res = await api.get(`/shipments/${reference}/documents/download`, {
    params: {
      filename,
      index,
    }
  })
  return res.data
}

export async function getActivities({ reference }) {
  const res = await api.get(`/shipments/${reference}/activities`)
  return res.data
}

export async function createActivity({ reference, ...payload }) {
  const res = await api.post(`/shipments/${reference}/activities`, payload)
  return res.data
}
