import api from "./api";

export async function list() {
  const res = await api.get('/notifications');
  return res.data
}

export async function read({ id }) {
  const res = await api.post(`/notifications/${id}/read`);
  return res.data
}
