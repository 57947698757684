import React from 'react';
import { TableContainer, TableBody, TableRow, TableHeader, TableData, Icon } from './DashboardStyle'
import transportMode from "../core/transportMode";
import {withTranslation} from "react-i18next";

const Bookings = (props) => {
  const {headers, data, t} = props;
  return (
    <TableContainer>
      <TableBody>
        <TableRow header>
          {headers.map((header, index) =>
            <TableHeader key={index} headers={headers.length}>{header}</TableHeader>
          )}
        </TableRow>
        {(data && data.length > 0) && data.map((item, index) =>
          item ?
          <TableRow key={index}>
            <TableData id="true">
              {(item.transportMode && transportMode[item.transportMode]) &&
              <Icon src={transportMode[item.transportMode].image} alt={`${t('core.transport_mode')}: ${item.transportMode}`}/>}
              {item.bookingRef ? item.bookingRef : item.reference}
            </TableData>
            <TableData>{item.shipmentOrg?.consignorFullName}</TableData>
            <TableData>{t('bookings.pending')}</TableData>
          </TableRow>
            : null
        )}
      </TableBody>
    </TableContainer>
  )
};

export default withTranslation()(Bookings);