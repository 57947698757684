import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";

const PageBreadcrumb = styled.div`
    ${fonts.UbuntuRegular};
    color: ${colors.grey};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
    width: 100%;
    margin-bottom: 0;
    a {
      color: ${colors.blue};
      text-decoration: none;
    }
  
    li {
      display: inline-block;
      
      &:not(:first-child) {
        :before {
          color: ${colors.blue};
          content: "-";
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    @media(min-width: ${breakpoints.md}){
        margin-bottom: -8px;
    }
`;

const Breadcrumbs = (props) => {
    const {links, current} = props;
    return (
        <PageBreadcrumb>
            <nav aria-label="breadcrumb">
                <ol>
                    {links && links.map((link) => {
                        return (
                            <li key={link.path}>
                                <Link to={link.path}>
                                    {link.label}
                                </Link>
                            </li>
                        )
                    })}
                    <li aria-current="page">
                        {current}
                    </li>
                </ol>
            </nav>
        </PageBreadcrumb>
    );
};

export default Breadcrumbs;
