const fonts = {
  DMSansRegular: "font-family: 'DM Sans', sans-serif; font-weight:400;",
  DMSansMedium: "font-family: 'DM Sans', sans-serif; font-weight:500;",
  DMSansBold: "font-family: 'DM Sans', sans-serif; font-weight:700;",
  UbuntuRegular: "font-family: 'Ubuntu', sans-serif; font-weight:400;",
  UbuntuBold: "font-family: 'Ubuntu', sans-serif; font-weight:700;",
  Cabin: "font-family: 'Cabin', sans-serif;",
};

export default fonts;
