import { createSlice } from '@reduxjs/toolkit'

const organisationSlice = createSlice({
  name: 'organisation',
  initialState: {
    organisations: [],
  },
  reducers: {
    setOrganisations(state, action) {
      state.organisations = action.payload
    },
    addOrganisation(state, action) {
      state.organisations.push(action.payload)
    },
    removeOrganisation(state, action) {
      state.organisations = state.organisations.filter(v => v.id !== action.payload)
    },
    updateOrganisation(state, action) {
      state.organisations = state.organisations.map(v => v.id === action.payload.id ? action.payload : v)
    },
  },
})

export const { setOrganisations, addOrganisation, removeOrganisation, updateOrganisation } = organisationSlice.actions

export default organisationSlice.reducer
