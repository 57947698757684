const colors = {
    black: '#000',
    white: '#FFF',
    navyBlue: '#070D49',
    blue: '#006DC7',
    green: '#53B4A9',
    red: '#E76C66',
    grey: '#939BA6',
    stormGrey: '#6a6d91',
    lightBlue: '#DEE2E8',
    backgroundBlue: '#F7FBFF',
    skyBlue: '#D9E9F7',
    mediumBlue: '#CBE1F4',
    tableGrey: '#F4F5F6'
};

export default colors;