import React, {useState} from "react";
import AccordionOpen from '../../resources/img/ui/accordion-open.svg';
import AccordionClose from '../../resources/img/ui/accordion-close.svg';
import {ShipmentLink, Card, CardHeader, CardHeaderText, AccordionImgStyled, Icon} from "./DashboardStyle"
import Container from "../../resources/img/icons/container-icon.svg";
import Boat from "../../resources/img/icons/boat.svg";
import Plane from "../../resources/img/icons/plane.svg";
import Truck from "../../resources/img/icons/truck.svg";
import Rail from "../../resources/img/icons/rail.svg";
import {withTranslation} from "react-i18next";

const transportMode = {
  FCL: Container,
  LCL: Boat,
  AIR: Plane,
  TRUCK: Truck,
  RAIL: Rail,
  SEA: Boat,
  LAND: Truck,
  RAI: Rail,
  ROA: Rail
};

function Toggle({ item, link, children, t }) {
  const [isCollapsed, setCollapsed] = useState(true)

  return (
    <Card open={isCollapsed}>
      <CardHeader onClick={() => setCollapsed(!isCollapsed)}>
        <CardHeaderText id="true">
          <Icon
            src={transportMode[item.transportMode]}
            alt={`${t('core.transport_mode')}: ${item.transportMode}`}
          />
          {link ? (
            <ShipmentLink to={link}>
              {item.reference ? item.reference : item.id}
            </ShipmentLink>
          ) : (item.reference ? item.reference : item.id)}
        </CardHeaderText>
        <AccordionImgStyled
          src={!isCollapsed ? AccordionClose : AccordionOpen}
          alt={t('core.dropdown')}
        />
      </CardHeader>
      {!isCollapsed && children}
    </Card>
  )
}

export default withTranslation()(Toggle);