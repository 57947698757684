import React from "react"
import PropTypes from "prop-types"
import {navigate} from "gatsby"
import {isLoggedIn} from "../store/reducers/index";

const PrivateRoute = ({component: Component, ...props}) => {
  let isLoggedIn = false;

  if (typeof window !== 'undefined') {
    isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  }

  if (!isLoggedIn && typeof window !== 'undefined') {
    navigate('/login', {
      replace: true,
    });

    return null;
  }

  return <Component {...props} />
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
