import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import LogoImg from '~/resources/img/white-logo.svg';
import LogoutIcon from "~/resources/img/nav/logout-icon.svg"
import OneDriveSyncIcon from "~/resources/img/nav/onedrive-sync.svg"
import ClientsIcon from '~/resources/img/nav/clients-icon.svg';
import ClientsIconSelected from '~/resources/img/nav/clients-icon-white.svg'
import UsersIcon from '~/resources/img/nav/user-icon.svg';
import UsersIconSelected from '~/resources/img/nav/user-icon-white.svg'
import ShipmentsIcon from '~/resources/img/nav/shipments-icon.svg';
import ShipmentsIconSelected from '~/resources/img/nav/shipments-icon-selected.svg'
import {logoutAction} from "~/store/actions/user";
import {onedriveService} from '~/services'
import {useDispatch} from "react-redux";
import colors from "~/styles/colors";
import fonts from "~/styles/fonts";

const SideBarBackground = styled.div`
  height: 100%;
  min-height: 100vh;
  background-color: ${colors.navyBlue};
  width: 225px;
  position: fixed;
  z-index: 9;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(max-width: 767px) {
    display: none;
  }
`;

const Logo = styled.img`
  padding-top: 50px;
  height: 49px;
  width: 139px;
  padding-bottom: 45px;
  padding-left: 35px;
`;

const MenuLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  background: ${props => props.active === "true" ? "linear-gradient(to right, #006dc7 0%, rgba(0, 109, 199, 0) 100%);" : "transparent"};
  border-left: ${props => props.active === "true" ? "3px solid" + colors.white : "3px solid transparent"};
  border-style: none;
  height: 48px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: ${colors.white};
`;

const LinkIcon = styled.img`
  height: 20px;
  padding-right: 20px;
  padding-left: 35px;
  
  ${props => props.spin && `
    animation: rotation 4s infinite linear;
    transform-origin: 50% 50%;
    
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `}
`;


const LinkText = styled.span`
  font-size: 14px;
  ${fonts.DMSansRegular};
`;

const LogoutButton = styled.span`
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  ${fonts.Cabin};
  color: ${colors.stormGrey};

  &:hover {
    text-decoration: underline;
  }
`;

function AdminSideBar() {
  let path = '';

  if (typeof window !== 'undefined') {
    path = window.location.pathname;
  }

  const [activeLink, setActiveLink] = useState(path);
  const [isSyncingOneDrive, setSyncingOneDrive] = useState(false)
  const dispatch = useDispatch();

  function syncOneDrive() {
    if (isSyncingOneDrive) {
      return
    }

    setSyncingOneDrive(true)
    onedriveService.sync()
      .catch(() => alert('Failed to perform synchronisation'))
      .finally(() => setSyncingOneDrive(false))
  }

  return (
    <SideBarBackground>
      <div>
        <Link to={'/'}>
          <Logo src={LogoImg} alt='Logo'/>
        </Link>

        <MenuLink to="/admin/users" active={`${activeLink === "/admin/users"}`}
                  onClick={() => setActiveLink('/admin/users')}>
          <LinkIcon src={activeLink === '/admin/users' ? ClientsIconSelected : ClientsIcon}
                    alt='Icon for Users'/>
          <LinkText>Users</LinkText>
        </MenuLink>
        <MenuLink to="/admin/admins" active={`${activeLink === "/admin/admins"}`}
                  onClick={() => setActiveLink('/admin/admins')}>
          <LinkIcon src={activeLink === '/admin/admins' ? UsersIconSelected : UsersIcon}
                    alt='Icon for Admins'/>
          <LinkText>Admins</LinkText>
        </MenuLink>
        <MenuLink to="/admin/organisations" active={`${activeLink === "/admin/organisations"}`}
                  onClick={() => setActiveLink('/admin/organisations')}>
          <LinkIcon src={activeLink === '/admin/organisations' ? UsersIconSelected : UsersIcon}
                    alt='Icon for Organisations'/>
          <LinkText>Organisations</LinkText>
        </MenuLink>
        <MenuLink to="/admin/shipments" active={`${activeLink === "/admin/shipments"}`}
                  onClick={() => setActiveLink('/admin/shipments')}>
          <LinkIcon src={activeLink === '/admin/shipments' ? ShipmentsIconSelected : ShipmentsIcon}
                    alt='Icon for Shipments'/>
          <LinkText>Shipments</LinkText>
        </MenuLink>
      </div>
      <div>
        <MenuLink>
          <span style={{paddingLeft: 35, paddingRight: 20}}>
            <LinkIcon
              src={OneDriveSyncIcon}
              alt='Icon for Sync OneDrive'
              style={{paddingLeft: 0, paddingRight: 0}}
              spin={isSyncingOneDrive}
            />
          </span>
          <LogoutButton onClick={syncOneDrive}>
            Rate Sync
          </LogoutButton>
        </MenuLink>
        <MenuLink style={{marginBottom: 20}}>
          <LinkIcon src={LogoutIcon} alt='Icon for Settings'/>
          <LogoutButton onClick={() => dispatch(logoutAction())}>
            Log out
          </LogoutButton>
        </MenuLink>
      </div>
    </SideBarBackground>
  )
}

export default AdminSideBar
