import React, {useState} from "react";
import styled from "styled-components";
import SmallTable from './SmallTable';
import AccordionOpen from '../../resources/img/ui/accordion-open.svg';
import AccordionClose from '../../resources/img/ui/accordion-close.svg';
import { Link } from 'gatsby';
import colors from "../../styles/colors";
import { withTranslation } from "react-i18next";

const Card = styled.div`
  border-bottom: ${props => props.last === true ? 'none' : '1px solid #DEE2E8'};
  min-height: ${props => props.closed === false ? '180px' : '60px'};
  padding: ${({closed}) => closed? "10px 0px" : "0px"};
  
  @media(min-width: 1070px) {
    display: none;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;

const CardHeaderText = styled.div`
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.id ? colors.blue : colors.navyBlue};
  vertical-align: middle;
  text-align: center;
  
  a {
    cursor: pointer;
    text-decoration: none;
    border: none;
    background: transparent;
    outline: none;
    
    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
      border: none;
      background: transparent;
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
  }
`;

const AccordionImgStyled = styled.img`
  height: auto;
  width: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

const ShipmentLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  color: #006DC7;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

function Toggle({ item, last, type, t }) {
  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <Card closed={isCollapsed} last={last}>
      <CardHeader onClick={() => setCollapsed(!isCollapsed)}>
        <ShipmentLink to={`/shipment/${item.shipmentId}`}>
          <CardHeaderText id={'true'}>{item.transactionNum}</CardHeaderText>
        </ShipmentLink>
        <AccordionImgStyled
          src={!isCollapsed ? AccordionClose : AccordionOpen}
          alt={t('core.dropdown')}
        />
      </CardHeader>
      {!isCollapsed && (
        <SmallTable item={item} type={type} />
      )}
    </Card>
  )
}

export default withTranslation()(Toggle);
