import React from 'react';
import styled from 'styled-components';
import TableToggleRow from './TableRow';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import NoResults from "../core/NoResults";

const TableContainer = styled.table`
  width: 100%;
  
  @media(max-width: 768px) {
    display: none;
  }
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableRow = styled.tr`
  height: ${props => props.header ? '79px' : '114px'};
`;

const TableHeader = styled.th`
  text-align: left;
  color: ${colors.grey};
  font-size: 14px;
  line-height: 20px;
  ${fonts.DMSansRegular};
  vertical-align: bottom;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  width: 20%;
  
  :nth-child(1) {
    width: 20%;
  }
  
  :nth-child(2) {
    width: 10%;
  }
  
  :nth-child(4) {
    width: 30%;
  }
  
  :nth-child(5) {
    width: 20%;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  
  tbody {
    width: 100%;
  }
  
  tr {
    cursor: pointer;
  }
`

function Table({ headers, data }) {
  return (
    <>
      {(data && data.length > 0) ?
        <React.Fragment>
          <TableContainer>
            <TableBody>
              <TableRow header>
                {headers.map((header, index) =>
                  <TableHeader key={index} headers={headers.length}>{header}</TableHeader>
                )}
              </TableRow>
            </TableBody>
          </TableContainer>
          <StyledTable>
            <tbody>
              <tr>
                <td>
                  {data.length > 0 && data.map((item, index) =>
                    <TableToggleRow key={index} item={item}/>
                  )}
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </React.Fragment>
        :
        <NoResults/>
      }
    </>
  )
}

export default Table;
