import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import fonts from "../../../styles/fonts";

const StyledMessage = styled.div`
  ${fonts.UbuntuRegular};
  color: ${colors.red};
  font-size: 14px;
  margin-top: 25px;
  height: 25px;
`;

const ErrorMessage = (props) => {
  const {children, className} = props;

  return (
    <StyledMessage className={className}>
      {children}
    </StyledMessage>
  )

};

export default ErrorMessage;