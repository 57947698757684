import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import Button from '../core/Button';
import { Link } from 'gatsby';
import {formatDate} from "../../services/core/dateHelper";
import downloadjs from "downloadjs";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import { documentService } from "../../services";
import { withTranslation } from "react-i18next";
import InvoiceStatusWithName from "../core/InvoiceStatusWithName";

const TableContainer = styled.table`
  width: 100%;
  
  @media(max-width: 915px) {
    display: none;
  }
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid ${colors.lightBlue};
  height: 60px;

  :last-child {
    border-bottom: none;
  };
`;

const TableHeader = styled.th`
  text-align: left;
  color: ${colors.grey};
  font-size: 14px;
  line-height: 20px;
  ${fonts.DMSansRegular};
  vertical-align: bottom;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  
  :first-child {
    min-width: 180px;
  }
`;

const TableData = styled.td`
  ${fonts.UbuntuRegular};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.navyBlue};
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  width: 10%;
  
  :nth-child(2) {
    width: 20%;
  }
  
  :first-child {
    width: 15%;
  }
`;

const ShipmentLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  color: ${colors.blue};
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

const TransactionReference = styled.a`
  color: ${colors.blue};
  text-decoration: none;
  ${props => props.disabled && css`
    opacity: .7;
    pointer-events: none;
  `}
`;

function Row({ item, t }) {
  const [loading, setLoading] = useState(false)

  function download({ document }) {
    if (loading) {
      return
    }

    setLoading(true)
    documentService.download({ id: document.id })
      .then(blob => downloadjs(window.atob(blob), `${document.fileName}.${document.dataType.toLowerCase()}`, 'application/pdf'))
      .catch(() => alert(t('core.no_documents')))
      .finally(() => setLoading(false))
  }

  return (
    <TableRow>
      <TableData>
        <TransactionReference
          href={documentService.downloadUrl({ id: item.document?.id })}
          target="_blank"
          rel="noreferrer"
          disabled={!item.document}
        >
          {item.transactionNum}
        </TransactionReference>
      </TableData>
      <TableData>
        {item.issuerName}
      </TableData>
      <TableData>
        {item.invoiceDate && formatDate(item.invoiceDate)}
      </TableData>
      <TableData>
        {item.dueDate && formatDate(item.dueDate)}
      </TableData>
      <TableData>
        <ShipmentLink to={`/shipment/${item.shipmentId}`}>
          {item.shipmentId}
        </ShipmentLink>
      </TableData>
      <TableData>
        <InvoiceStatusWithName transaction={item} />
      </TableData>
      <TableData>
        {formatCurrency(item.invoiceAmount, item.currency)}
      </TableData>
      <TableData>
        <Button
          color="blue"
          text={item.document ? t('core.download') : t('core.download_unavailable')}
          disabled={!item.document}
          loading={loading}
          onClick={() => download(item)}
        />
      </TableData>
    </TableRow>
  )
}

function Table({ data, t }) {
  const headers = [
    t('core.invoice') + " ID",
    t('core.issuer'), t('invoices.invoice_date'),
    t('core.due_date'),
    t('core.shipment') + " ID",
    t('core.status'),
    t('core.amount'),
    t('core.action'),
  ];

  return (
    <TableContainer>
      <TableBody>
        <TableRow header>
          {headers.map((header, index) =>
            <TableHeader key={index} headers={headers.length}>
              {header}
            </TableHeader>
          )}
        </TableRow>
        {data && data.map((item, index) => <Row t={t} key={index} item={item} />)}
      </TableBody>
    </TableContainer>
  )
}

function formatCurrency(amount, currency) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount)
}

export default withTranslation()(Table);
