import {useTranslation} from "react-i18next";
import Boat from "../resources/img/icons/boat.svg";
import Plane from "../resources/img/icons/plane.svg";
import Truck from "../resources/img/icons/truck.svg";
import Rail from "../resources/img/icons/rail.svg";

function useCWTransportModes() {
  const { t } = useTranslation()
  return {
    SEA: {
      label: t('core.sea'),
      image: Boat
    },
    AIR: {
      label: t('core.sea'),
      image: Plane
    },
    LAND: {
      label: t('core.land'),
      image: Truck
    },
    RAIL: {
      label: t('core.rail'),
      image: Rail
    },
    RAI: {
      label: t('core.rail'),
      image: Rail
    },
    ROA: {
      label: t('core.truck'),
      image: Truck
    },
  }
}

export default useCWTransportModes
