import styled from 'styled-components';
import MarkerSea from '../../resources/img/map/marker-sea.svg';
import MarkerSeaComplete from '../../resources/img/map/marker-sea-complete.svg';
import MarkerSeaIncomplete from '../../resources/img/map/marker-sea-incomplete.svg';
import MarkerAir from '../../resources/img/map/marker-air.svg';
import MarkerAirComplete from '../../resources/img/map/marker-air-complete.svg';
import MarkerAirIncomplete from '../../resources/img/map/marker-air-incomplete.svg';
import MarkerLand from '../../resources/img/map/marker-land.svg';
import MarkerLandComplete from '../../resources/img/map/marker-land-complete.svg';
import MarkerLandIncomplete from '../../resources/img/map/marker-land-incomplete.svg';
import MarkerRai from '../../resources/img/map/marker-rai.svg';
import MarkerRaiComplete from '../../resources/img/map/marker-rai-complete.svg';
import MarkerRaiIncomplete from '../../resources/img/map/marker-rai-incomplete.svg';
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;

  .mapboxgl-canvas {
    outline: none;
  }
  
  .marker {
    width: 40px;
    height: 40px;
    cursor: pointer;
        
    &.sea {
      background: transparent url(${MarkerSea}) no-repeat !important;
      
      &.complete {
        background: transparent url(${MarkerSeaComplete}) no-repeat !important;
      }
      
      &.incomplete {
        background: transparent url(${MarkerSeaIncomplete}) no-repeat !important;
      }
    }

    &.air {
      background: transparent url(${MarkerAir}) no-repeat !important;
      
      &.complete {
        background: transparent url(${MarkerAirComplete}) no-repeat !important;
      }
      
      &.incomplete {
        background: transparent url(${MarkerAirIncomplete}) no-repeat !important;
      }
    }

    &.land {
      background: transparent url(${MarkerLand}) no-repeat !important;
      
      &.complete {
        background: transparent url(${MarkerLandComplete}) no-repeat !important;
      }
      
      &.incomplete {
        background: transparent url(${MarkerLandIncomplete}) no-repeat !important;
      }
    }

    &.rai {
      background: transparent url(${MarkerRai}) no-repeat !important;
      
      &.complete {
        background: transparent url(${MarkerRaiComplete}) no-repeat !important;
      }
      
      &.incomplete {
        background: transparent url(${MarkerRaiIncomplete}) no-repeat !important;
      }
    }
    background-position: center center;
  }

  .mapboxgl-popup  {
    ${fonts.UbuntuRegular};
    font-size: 9px;
    margin-top: -20px;

    .mapboxgl-popup-content {
      padding: 6px 12px;
      color: ${colors.navyBlue};
      font-size: 14px;
    }
  }
`;

export {
  MapContainer
}
