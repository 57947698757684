import React from 'react';
import {TableContainerMobile, TableBody, TableHeader, TableData} from "./DashboardStyle";

function QuotesMobile({headers, item}) {
  return (
    <TableContainerMobile>
      <TableBody>
        <tr>
          {headers
            .filter(header => !header.includes("ID"))
            .map((header, index) =>
              <TableHeader key={index} headers={headers.length - 1}>{header}</TableHeader>
            )}
        </tr>
        <tr>
          <TableData>
            {item.originLocation}
          </TableData>
          <TableData>
            {item.destinationLocation}
          </TableData>
          <TableData>
            {formatDate(item.cargoReadyDate)}
          </TableData>
        </tr>
      </TableBody>
    </TableContainerMobile>
  )
}

function formatDate(date) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
}

export default QuotesMobile;
