import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import AdminLayout from '~/components/admin/AdminLayout';
import SEO from '~/components/core/Seo';
import Card from '~/components/core/Card';
import PrivateRoute from "~/router/PrivateRoute"
import Button from "~/components/core/Button";
import SearchBar from "~/components/core/SearchBar";
import Table from "~/components/core/Table";
import BinIcon from "~/resources/img/icons/bin-icon.svg"
import EditIcon from "~/resources/img/icons/edit-icon.svg"
import UserAvatar from "~/resources/img/icons/user-avatar.svg"
import UserPopup from "~/components/admins/UserPopup"
import {userService} from "~/services";
import colors from "~/styles/colors";
import fonts from "~/styles/fonts";

const Background = styled.div`
  width: calc(100% - 225px);
  left: 225px;
  top: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 35px 80px;
  box-sizing: border-box;

  @media(max-width: 767px) {
    left: 0;
    top: 105px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; 
    padding-bottom: 105px;      
  }
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  background: transparent;
  
  img {
    padding-right: 9px;
    height: 14px;
    width: 14px;
  }
  
  span {
    text-transform: uppercase;
    ${fonts.DMSansBold};
    color: ${colors.navyBlue};
    font-size: 12px;
  }
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`

const BinButton = styled.button`
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  background: transparent;
  margin-right: 11px;
  margin-left: 30px;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
  
  img {
    height: 16px;
    width: 16px;
  }
`

const Name = styled.div`
  margin-left: 20px;
`

const Desktop = styled.div`
  display: none;
  width: 100%;

  @media(min-width: 767px){
    display: block;
  }
`;

const Mobile = styled.div`
  display: block;
  width: 100%;

  @media(min-width: 767px){
    display: none;
  }
`;

const UserBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ActionsBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

function AdminsPage({ location }) {
  const path = location?.pathname;
  const [data, setData] = useState([])
  const [user, setUser] = useState(null)
  const [isPopupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    userService.list({ role: 'ROLE_ADMIN' })
      .then((data) => setData(data))
      .catch(console.error)
  }, [])

  function deleteUser(id) {
    userService.destroy({ id })
      .then(() => {
        const users = data.filter(v => v.id !== id)
        setData(users)
        setUser(null)
      })
      .catch(console.error)
  }

  function createUser() {
    setUser(null)
    setPopupOpen(true)
  }

  function editUser(user) {
    setUser(user)
    setPopupOpen(true)
  }

  const tableContent = <Table
    headers={[]}
    data={data}
    rowTemplate={
      (item, index) => [
        <UserBlock>
          <img src={UserAvatar} alt={"User Avatar"}/>
          <Name>{`${item.firstName} ${item.lastName}`}</Name>
        </UserBlock>,
        <ActionsBlock>
          <EditButton onClick={() => editUser(item)}>
            <img src={EditIcon} alt={"Edit Icon"}/>
            <span>Edit</span>
          </EditButton>
          <BinButton onClick={() => deleteUser(item.id)}>
            <img src={BinIcon} alt={"Bin Icon"}/>
          </BinButton>
        </ActionsBlock>
      ]
    }
  />;

  return (
    <AdminLayout>
      <SEO title="User Management" path={path}/>
      <Background>
        <Mobile>
          <Card data={data} title={"Admin Users"} titleElements={[
            <SearchBar placeholder={"Search admin user name..."}/>,
            <Button color={'blue'} text={'Create new'} fullwidth={true} onClick={createUser}/>
          ]}/>
          <Card data={data}>{tableContent}</Card>
        </Mobile>
        <Desktop>
          <Card data={data} title={"Admin Users"} titleElements={[
            <SearchBar placeholder={"Search admin user name..."}/>,
            <Button color={'blue'} text={'Create new'} onClick={createUser}/>,
          ]}>
            {tableContent}
          </Card>
        </Desktop>
        {isPopupOpen && (
          <UserPopup
            handlePopup={setPopupOpen}
            user={user}
            isOpen={isPopupOpen}
          />
        )}
      </Background>
    </AdminLayout>
  )
}

function admins(props) {
  return <PrivateRoute component={AdminsPage} {...props}/>
}

export default admins;
