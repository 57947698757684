// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-admins-js": () => import("./../../../src/pages/admin/admins.js" /* webpackChunkName: "component---src-pages-admin-admins-js" */),
  "component---src-pages-admin-organisations-js": () => import("./../../../src/pages/admin/organisations.js" /* webpackChunkName: "component---src-pages-admin-organisations-js" */),
  "component---src-pages-admin-shipment-js": () => import("./../../../src/pages/admin/shipment.js" /* webpackChunkName: "component---src-pages-admin-shipment-js" */),
  "component---src-pages-admin-shipments-js": () => import("./../../../src/pages/admin/shipments.js" /* webpackChunkName: "component---src-pages-admin-shipments-js" */),
  "component---src-pages-admin-users-js": () => import("./../../../src/pages/admin/users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-js": () => import("./../../../src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-onboarding-business-department-js": () => import("./../../../src/pages/onboarding/business-department.js" /* webpackChunkName: "component---src-pages-onboarding-business-department-js" */),
  "component---src-pages-onboarding-company-js": () => import("./../../../src/pages/onboarding/company.js" /* webpackChunkName: "component---src-pages-onboarding-company-js" */),
  "component---src-pages-onboarding-finance-department-js": () => import("./../../../src/pages/onboarding/finance-department.js" /* webpackChunkName: "component---src-pages-onboarding-finance-department-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-shipment-js": () => import("./../../../src/pages/shipment.js" /* webpackChunkName: "component---src-pages-shipment-js" */),
  "component---src-pages-shipments-js": () => import("./../../../src/pages/shipments.js" /* webpackChunkName: "component---src-pages-shipments-js" */)
}

