import {connect} from 'react-redux';
import LoginForm from './LoginForm';
import {getUser} from "../../store/reducers/user";
import {loginAction} from "../../store/actions/user";

const mapStateToProps = state => {
  return {
    user: getUser(state)
  };
};

const mapDispatchToProps = {
  loginAction
};

const LoginFormContainer = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default LoginFormContainer;
