import Boat from "../../resources/img/icons/boat.svg";
import Plane from "../../resources/img/icons/plane.svg";
import Truck from "../../resources/img/icons/truck.svg";
import Rail from "../../resources/img/icons/rail.svg";

const transportMode = {
    SEA: {
        label: 'Sea',
        image: Boat
    },
    AIR: {
        label: 'Air',
        image: Plane
    },
    LAND: {
        label: 'Land',
        image: Truck
    },
    RAIL: {
        label: 'Rail',
        image: Rail
    },
    RAI: {
        label: 'Rail',
        image: Rail
    },
    TRUCK: {
        label: 'Truck',
        image: Truck
    },
};

export default transportMode;
