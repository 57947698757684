import api, {ENDPOINT} from "./api";

export async function download({ id }) {
  const res = await api.get(`/documents/${id}/download`)
  return res.data
}

export function downloadUrl({ id }) {
  return `${ENDPOINT}/documents/${id}/download?jwt=${localStorage.getItem('token')}&decode=true`
}
