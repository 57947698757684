import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import ClientsIconSelected from "~/resources/img/nav/clients-icon-white.svg";
import ClientsIcon from "~/resources/img/nav/clients-icon.svg";
import UsersIconSelected from "~/resources/img/nav/user-icon-white.svg";
import UsersIcon from "~/resources/img/nav/user-icon.svg";
import ShipmentsIcon from '~/resources/img/nav/shipments-icon.svg';
import ShipmentsIconSelected from '~/resources/img/nav/shipments-icon-selected.svg'
import colors from "~/styles/colors";
import fonts from "~/styles/fonts";

const SideBarBackground = styled.div`
  height: 85px;
  background: ${colors.navyBlue};
  width: 100%;
  position: fixed;
  z-index: 9;
  overflow: hidden;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  @media(min-width: 768px) {
    display: none;
  }
`;

const MenuLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background: ${props => props.active === "true" ? "linear-gradient(360deg, #006dc7 0%, rgba(0, 109, 199, 0) 100%)" : "transparent"};
  border-bottom: ${props => props.active === "true" ? "4px solid #fff" : "4px solid transparent"};
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: 20%;
`;

const LinkIcon = styled.img`
  height: 20px;
  max-width: 26px;
  padding-bottom: 10px;
`;

const LinkText = styled.div`
  color: ${colors.white};
  font-size: 10px;
  ${fonts.DMSansRegular};
`;

function AdminMobileNav() {
  let path = '';

  if (typeof window !== 'undefined') {
    path = window.location.pathname;
  }

  const [activeLink, setActiveLink] = useState(path)

  return (
    <SideBarBackground>
      <MenuLink to="/admin/users" active={`${activeLink === "/admin/users"}`}
                onClick={() => setActiveLink('/admin/users')}>
        <LinkIcon src={activeLink === '/admin/users' ? ClientsIconSelected : ClientsIcon}
                  alt='Icon for Users'/>
        <LinkText>Users</LinkText>
      </MenuLink>
      <MenuLink to="/admin/admins" active={`${activeLink === "/admin/admins"}`}
                onClick={() => setActiveLink('/admin/admins')}>
        <LinkIcon src={activeLink === '/admin/admins' ? UsersIconSelected : UsersIcon}
                  alt='Icon for Admins'/>
        <LinkText>Admins</LinkText>
      </MenuLink>
      <MenuLink to="/admin/organisations" active={`${activeLink === "/admin/organisations"}`}
                onClick={() => setActiveLink('/admin/organisations')}>
        <LinkIcon src={activeLink === '/admin/organisations' ? UsersIconSelected : UsersIcon}
                  alt='Icon for Organisations'/>
        <LinkText>Organisations</LinkText>
      </MenuLink>
      <MenuLink to="/admin/shipments" active={`${activeLink === "/admin/shipments"}`}
                onClick={() => setActiveLink('/admin/shipments')}>
        <LinkIcon src={activeLink === '/admin/shipments' ? ShipmentsIconSelected : ShipmentsIcon}
                  alt='Icon for Shipments'/>
        <LinkText>Shipments</LinkText>
      </MenuLink>
    </SideBarBackground>
  )
}

export default AdminMobileNav
