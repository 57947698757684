import React from 'react';
import {Provider} from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const initialState = {
  USER: {
    isLoggedIn: false,
    loginFailed: false
  }
};

const loadDevTools = () =>
  process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

const store = createStore(rootReducer, initialState, compose(applyMiddleware(thunk), loadDevTools()));
export default ({element}) => (
  <Provider store={store}>{element}</Provider>
);
