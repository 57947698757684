import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import { withTranslation } from "react-i18next";

const NoResultsContainer = styled.div`
    padding: 20px 0;
    ${fonts.DMSansRegular};
    color: ${colors.grey};
    font-size: 14px;
    line-height: 20px;
`;

const NoResults = ({t}) => {
    return (
        <NoResultsContainer>{t('core.no_documents')}</NoResultsContainer>
    )
}

export default withTranslation()(NoResults);