import React from 'react';
import styled from 'styled-components';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import VolumeIcon from '../../resources/img/ui/volume-icon.svg';
import WeightIcon from '../../resources/img/ui/weight-icon.svg';
import QuantityIcon from '../../resources/img/ui/quantity-icon.svg';
import {FreightImg, FreightType} from "./BookingsStyle";
import {withTranslation} from "react-i18next";

const FreightCount = styled.div`
    display: flex;
    flex-direction: column;
    ${fonts.UbuntuRegular};
    font-size: 12px;
    line-height: 19px;
    color: ${colors.navyBlue};
`;

const FreightDescription = styled.div`
    color: ${colors.navyBlue};
    font-size: 12px;
    line-height: 19px;
`;

function FreightDetails({ item, t }) {
  const containersCount = item.packLines?.length ?? 0;

  return (
    <FreightCount>
      {containersCount > 0 &&
      <FreightType>
        <FreightImg src={QuantityIcon} alt={t('bookings.quantity_icon')}/>
        <FreightDescription>x{Math.max(containersCount, 1)}</FreightDescription>
      </FreightType>}
      {item.volume > 0 &&
      <FreightType>
        <FreightImg src={VolumeIcon} alt={t('bookings.volume_icon')}/>
        <FreightDescription>{item.volume}{item.volumeUnit}</FreightDescription>
      </FreightType>}
      {item.weight > 0 &&
      <FreightType>
        <FreightImg src={WeightIcon} alt={t('bookings.weight_icon')}/>
        <FreightDescription>{item.weight}{item.weightUnit}</FreightDescription>
      </FreightType>}
    </FreightCount>
  )
};

export default withTranslation()(FreightDetails);
