import React from 'react';
import styled from 'styled-components';
import SEO from '../components/core/Seo';
import Button from '../components/core/Button';
import GlobalStyle from '../styles/global';
import colors from "../styles/colors";
import fonts from "../styles/fonts";
import "../components/core/i18n";
import { withTranslation } from "react-i18next";

const Background = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(248, 251, 255);
`;

const Title = styled.h1`
    color: ${colors.navyBlue};
    font-size: 60px;
    letter-spacing: 2px;
    ${fonts.UbuntuRegular};
    text-align: center;
    padding-bottom: 45px;
    padding-left: 20px;
    padding-right: 20px;
`;

const Description = styled.div`
    color: ${colors.grey};
    font-size: 14px;
    line-height: 20px;
    ${fonts.DMSansRegular};
    padding-bottom: 25px;
`;

const NotFoundPage = (props) => {
    const {t} = props;
    return (
        <Background>
            <GlobalStyle/>
            <SEO title={"404: "+t('error.title')}/>
            <Title>{t('error.title')}</Title>
            <Description>{t('error.description')}</Description>
            <Button color='blue' link='/' text={t("core.return")}/>
        </Background>
    )
}
;

export default withTranslation()(NotFoundPage);

