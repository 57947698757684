import React, { Component } from 'react';
import styled from 'styled-components';
import LogoImg from '../../resources/img/logo.svg';
import DashboardIcon from '../../resources/img/nav/dashboard-icon-selected.svg';
import ShipmentsIcon from '../../resources/img/nav/shipments-icon-selected.svg';
import BookingsIcon from '../../resources/img/nav/booking-icon-selected.svg';
import QuotesIcon from '../../resources/img/nav/quotes-icon-selected.svg';
import InvoicesIcon from '../../resources/img/nav/invoices-icon-selected.svg';
import DashboardIconSelected from '../../resources/img/nav/dashboard-icon-selected.svg';
import ShipmentsIconSelected from '../../resources/img/nav/shipments-icon-selected.svg';
import BookingsIconSelected from '../../resources/img/nav/booking-icon-selected.svg';
import QuotesIconSelected from '../../resources/img/nav/quotes-icon-selected.svg';
import InvoicesIconSelected from '../../resources/img/nav/invoices-icon-selected.svg';
import { Link } from 'gatsby';
// import MessagesIcon from '../../resources/img/nav/messages-icon-selected.svg';
// import MessagesIconSelected from '../../resources/img/nav/messages-icon-selected.svg';
import SettingsIcon from '../../resources/img/nav/settings-icon-selected.svg';
import SettingsIconSelected from '../../resources/img/nav/settings-icon-selected.svg';
import colors from "../../styles/colors";

const SideBarBackground = styled.div`
    height: 100%;
    min-height: 100vh;
    background: ${colors.white};
    width: 225px;
    padding-top: 50px;
    position: fixed;
    z-index: 9999;
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    @media(max-width: 767px) {
        display: none;
    }
`;

const Logo = styled.img`
    height: 49px;
    width: 139px;
    margin-bottom: 45px;
    padding-left: 35px;
`;

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    background: ${props => props.active === "true" ? "linear-gradient(90deg, rgba(229, 240, 249, 100), rgba(247, 251, 255, 100));" : "transparent"};
    border: none;
    border-left: ${props => props.active === "true" ? "3px solid #006DC7" : "3px solid transparent"};
    height: 48px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color: ${props => props.active === "true" ? "#006DC7" : "#939BA6"};
`;

const LinkIcon = styled.img`
    height: 20px;
    padding-right: 20px;
    padding-left: 35px;
`;

const LinkText = styled.span`
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
`;

// const SecondaryLinks = styled.div`
//     border-top: 1px solid ${colors.lightBlue};
//     border-bottom: 1px solid ${colors.lightBlue};
//     margin-top: 45px;
//     padding-top: 15px;
//     padding-bottom: 15px;
// `;
//
// const MessageCount = styled.div`
//     height: 26px;
//     width: 26px;
//     border-radius: 100px;
//     background: #006DC7;
//     color: ${colors.white};
//     font-family: 'DM Sans', sans-serif;
//     font-weight: bold;
//     font-size: 12px;
//     line-height: 16px;
//     margin-left: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `;

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

class SideBar extends Component {
    state = {
        activeLink: '/',
    };

    changeActive = (props) => {
        this.setState(() => {
            return { activeLink: props };
        });
    };

    componentDidMount() {
        if (typeof window !== 'undefined') {
            let activeLink = window.location;

            this.setState(() => {
                return { activeLink: activeLink.pathname };
            });
        }
    }

    render() {
        return (
            <SideBarBackground>
                <Link to={'/'}>
                    <Logo src={LogoImg} alt='Logo' />
                </Link>
                <MenuLink to="/" active={`${this.state.activeLink === "/"}`} onClick={() => this.changeActive('/')}>
                    <LinkIcon src={this.state.activeLink === '/' ? DashboardIconSelected : DashboardIcon} alt='Icon for Dashboard Page' />
                    <LinkText>Dashboard</LinkText>
                </MenuLink>
                <MenuLink to="/shipments" active={`${this.state.activeLink.includes("/shipment")}`} onClick={() => this.changeActive('/shipments')}>
                    <LinkIcon src={this.state.activeLink.includes('/shipment') ? ShipmentsIconSelected : ShipmentsIcon} alt='Icon for Shipments Page' />
                    <LinkText>Shipments</LinkText>
                </MenuLink>
                <MenuLink to="/bookings" active={`${this.state.activeLink.includes("/booking")}`} onClick={() => this.changeActive('/bookings')}>
                    <LinkIcon src={this.state.activeLink.includes('/booking') ? BookingsIconSelected : BookingsIcon} alt='Icon for Bookings Page' />
                    <LinkText>Bookings</LinkText>
                </MenuLink>
                {activeEnv !== 'production' && (
                  <MenuLink to="/quotes" active={`${this.state.activeLink.includes("/quote")}`} onClick={() => this.changeActive('/quotes')}>
                    <LinkIcon src={this.state.activeLink.includes('/quote' )? QuotesIconSelected : QuotesIcon} alt='Icon for Quotes Page' />
                    <LinkText>Quotes</LinkText>
                  </MenuLink>
                )}
                <MenuLink to="/invoices" active={`${this.state.activeLink.includes("/invoice")}`} onClick={() => this.changeActive('/invoices')}>
                    <LinkIcon src={this.state.activeLink.includes('/invoice') ? InvoicesIconSelected : InvoicesIcon} alt='Icon for Invoices Page' />
                    <LinkText>Invoices</LinkText>
                </MenuLink>
                <MenuLink to="/onboarding/company" active={`${this.state.activeLink === "/onboarding"}`} onClick={() => this.changeActive('/onboarding/company')}>
                    <LinkIcon src={this.state.activeLink === '/onboarding/company' ? SettingsIconSelected : SettingsIcon} alt='Icon for Settings' />
                    <LinkText>Settings</LinkText>
                </MenuLink>
                {/*<SecondaryLinks>*/}
                {/*    <MenuLink to="/" active={`${this.state.activeLink === "/messages"}`} onClick={() => this.changeActive('/messages')}>*/}
                {/*        <LinkIcon src={this.state.activeLink === '/messages' ? MessagesIconSelected : MessagesIcon} alt='Icon for Messages' />*/}
                {/*        <LinkText>Messages</LinkText>*/}
                {/*        <MessageCount>0</MessageCount>*/}
                {/*    </MenuLink>*/}
                {/*</SecondaryLinks>*/}
            </SideBarBackground>
        )
    }
}

export default SideBar;
