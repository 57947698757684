import styled from "styled-components";
import colors from "../../styles/colors";
import ArrowDown from "../../resources/img/ui/arrow-dropdown.svg";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";

const Border = styled.div`
    border-bottom: 1px solid ${colors.lightBlue};
    padding-bottom: 20px;
    @media(min-width: ${breakpoints.md}){
        padding-left: 35px;
        border-right: 1px solid ${colors.lightBlue};
        padding-bottom: 0;
        border-bottom: none;
        padding-right: 20px;
    }
`;

const Title = styled.div`
    ${fonts.DMSansMedium};
    color: ${colors.navyBlue};
    font-size: 16px;
    margin-top: 30.5px;
    margin-bottom: 15px;
    @media(min-width: ${breakpoints.sm}){
        margin-top: 42.5px;
    }
`;

const LabelStyled = styled.label`
    ${fonts.UbuntuRegular};
    color: ${colors.grey};
    font-size: 12px;
    line-height: 16px;
`;

const StyledInput = styled.input`
    display: block;
    box-sizing: border-box;
    margin-top: 9px;
    width: 100%;
    border: 1px solid ${colors.lightBlue};
    border-radius: 4px;
    height: 42px;
    ${fonts.UbuntuRegular};
    color: ${colors.navyBlue};
    font-size: 14px;
    line-height: 20px;
    padding-left: 14px;
    margin-bottom: 20px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    @media(min-width: ${breakpoints.md}){
        margin-bottom: 25px;
        max-width: 370px;
    }
`;

const SelectStyled = styled.select`
    display: block;
    box-sizing: border-box;
    margin-top: 9px;
    width: 100%;
    border: 1px solid ${colors.lightBlue};
    border-radius: 4px;
    height: 42px;
    -moz-appearance:none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-appearance:none;
    background: url(${ArrowDown}) right 18px center no-repeat;
    ${fonts.UbuntuRegular};
    color: ${colors.navyBlue};
    font-size: 14px;
    line-height: 20px;
    padding-left: 14px;
    cursor: pointer;
    margin-bottom: 20px;
    padding-right: 40px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    @media(min-width: ${breakpoints.md}){
        margin-bottom: 25px;
        max-width: 370px;
    }
`;

const RightColumn = styled.div`
    @media(min-width: ${breakpoints.lg}){
        padding-left: 45px;
    }
`;

const RadioInput = styled.label`
    display: block;
    margin-top: 20px;
    margin-bottom: 23px;
    cursor: pointer;
    ${fonts.UbuntuRegular};
    color: ${colors.navyBlue};
    font-size: 14px;
    line-height: 20px;
    input {
        cursor: pointer;
        margin-right: 10px;
    }
`;

const StyledTextarea = styled.textarea`
    width: 100%;
    border: 1px solid ${colors.lightBlue};
    height: 160px;
    box-sizing: border-box;
    padding-left: 12px;
    padding-top: 13px;
    ${fonts.UbuntuRegular};
    color: ${colors.navyBlue};
    font-size: 14px;
    line-height: 20px;
    resize: none;
    border-radius: 3px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    ::-webkit-input-placeholder { 
    color: ${colors.grey}; 
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  ::-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-ms-input-placeholder {
    color: ${colors.navyBlue};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
    @media(min-width: ${breakpoints.md}){
        height: 130px;
    }
`;

const MobileOnly = styled.div`
    display: block;
    @media(min-width: ${breakpoints.md}){
        display: none;
    }
`;


export {
    SelectStyled,
    StyledInput,
    LabelStyled,
    Title,
    Border,
    RightColumn,
    RadioInput,
    StyledTextarea,
    MobileOnly
}