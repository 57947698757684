import React from 'react';
import styled, {css} from 'styled-components';
import InnerTableRow from './InnerTableRow';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import { withTranslation } from "react-i18next";

const TableRow = styled.tr`
  border-bottom: 1px solid ${colors.lightBlue};
  height: ${props => props.header ? '60px' : '40px'};
  vertical-align: middle;
  
  :last-child {
    border-bottom: none;
  }
  
  @media(max-width: 1100px) {
    display: ${props => props.header && 'none'}  
  }
`;

const TableHeader = styled.th`
  text-align: left;
  color: ${colors.grey};
  font-size: 14px;
  line-height: 20px;
  ${fonts.DMSansRegular};
  vertical-align: bottom;
  box-sizing: border-box;
  width: 20%;
  padding: ${props => props.header ? '0' : '20px'} 12px;
  
  :first-child {
    min-width: 20%;
  }
  
  :nth-child(2) {
    width: 60%;
  }

  :last-child {
    width: 40px;
  }

  ${props => props.right && css`
    text-align: right;
  `}
`;

const StyledTable = styled.table`
  width: 100%;
  
  tbody {
    width: 100%;
  }
`

function FaresTable({ fares, t }) {

  return (
    <StyledTable>
      <tbody>
        <TableRow header>
          <TableHeader>
            {t('core.charge_type')}
          </TableHeader>
          <TableHeader />
          <TableHeader right style={{ width: 100 }}>
            {t('core.amount')}
          </TableHeader>
          <TableHeader right style={{ width: 100 }}>
            {t('core.currency')}
          </TableHeader>
          <TableHeader header style={{ width: 100 }}/>
        </TableRow>
        {fares
          ?.sort(({ order: a }, { order: b }) => {
            if (a === 0) return 1;
            if (b === 0) return -1;
            return a - b;
          })
          .map((fare, index) => <InnerTableRow fare={fare} key={index} />)}
      </tbody>
    </StyledTable>
  )
}

export default withTranslation()(FaresTable);
