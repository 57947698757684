import api from "./api";

export async function list({ page, transportModes, search }) {
  const res = await api.get('/bookings', {
    params: {
      page,
      transportModes,
      search,
    },
  })
  return res.data
}

export async function create(payload) {
  const res = await api.post('/bookings', payload)
  return res.data
}
