import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import SEO from '~/components/core/Seo';
import PrivateRoute from "~/router/PrivateRoute"
import AdminLayout from "~/components/admin/AdminLayout";
import Card from "../../components/shipments/Card";
import {shipmentService} from "../../services";
import {navigate} from "gatsby";

const Background = styled.div`
  width: calc(100% - 225px);
  left: 225px;
  top: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 35px 80px;
  box-sizing: border-box;
  
  @media(max-width: 767px) {
    left: 0;
    top: 105px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; 
    padding-bottom: 105px;      
  }
`;

function Shipments({ location }) {
  const path = location?.pathname
  const { perPage, page, transports, status } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const rawTransports = searchParams.get('transports')
    return {
      perPage: parseInt(searchParams.get('perPage'), 10),
      page: parseInt(searchParams.get('page'), 10),
      transports: rawTransports?.split(',') ?? [],
      status: searchParams.get('status'),
    }
  }, [location.search])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)

  useEffect(() => {
    if (isNaN(page)) {
      changeSearchParams('page', 1)
      return
    }
    if (isNaN(perPage)) {
      changeSearchParams('perPage', 15)
      return
    }

    setLoading(true);

    shipmentService.list({
      page,
      perPage,
      status,
      transportModes: transports.length ? transports : undefined,
    })
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }, [page, perPage, status, transports])

  const changeSearchParams = (key, value) => {
    const searchParams = new URLSearchParams(location.search)
    if (value === undefined) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }
    return navigate(`?${searchParams.toString()}`)
  }

  const toggleTransport = (transport) => {
    if (transports.some(v => v === transport)) {
      const newTransports = transports.filter(v => v !== transport)
      return changeSearchParams('transports', newTransports.length ? newTransports.join(',') : undefined)
    }
    return changeSearchParams('transports', [...transports, transport])
  }

  const toggleStatus = (newStatus) => {
    return changeSearchParams(
      'status',
      newStatus !== status ? newStatus : undefined,
    )
  }

  return (
    <AdminLayout loading={loading}>
      <SEO title="Shipment Management" path={path}/>
      <Background>
        <Card
          title="Shipments"
          admin={true}
          data={data?.items ?? []}
          count={data?.meta?.count ?? 0}
          pageStart={((data?.meta?.currentPage ?? 0) - 1) * (data?.meta?.perPage ?? 0)}
          pageEnd={(data?.meta?.currentPage ?? 0) * (data?.meta?.perPage ?? 0)}
          pageNumber={data?.meta?.currentPage}
          nextPage={data?.meta?.nextPage}
          previousPage={data?.meta?.previousPage}
          perPage={data?.meta?.perPage ?? 0}
          changePerPage={(perPage) => changeSearchParams('perPage', perPage)}
          changePage={(page) => changeSearchParams('page', page)}
          statusFilter={status}
          addStatusFilter={toggleStatus}
          transportFilters={transports}
          addTransportFilter={toggleTransport}
        />
      </Background>
    </AdminLayout>
  )
}

function shipmentsRoute(props) {
  return <PrivateRoute component={Shipments} {...props}/>
}

export default shipmentsRoute;
