import React, {useMemo} from 'react';
import styled from 'styled-components';
import SEO from '../components/core/Seo';
import GlobalStyle from '../styles/global';
import ResetPasswordForm from '../components/login/ResetPasswordForm';
import colors from "../styles/colors";
import LogoMobile from "../resources/img/logo.svg";
import "../components/core/i18n";
import { withTranslation } from "react-i18next";

const Background = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.backgroundBlue};
  position: relative;
  padding-bottom: 40px;
  box-sizing: border-box;
  
  @media(max-width: 767px) {
    left: 0;
  }
`;

const Logo = styled.img`
  width: 164px;
  margin-bottom: 46px;
  margin-top: 34px;
  
  @media(min-width: 768px) {
    width: 164px;
    margin-bottom: 56px;
    margin-top: 55px;
  }
`;

function ResetPasswordPage({ location, t }) {
  const token = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('token')
  }, [location]);

  return (
    <Background>
      <GlobalStyle/>
      <SEO title={t('reset_password.reset_password')}/>
      <Logo src={LogoMobile} alt="Logo" />
      <ResetPasswordForm token={token}/>
    </Background>
  )
}

export default withTranslation()(ResetPasswordPage);