import UserActionTypes from '../actionTypes/user'

const initialState = {
  isLoggedIn: false,
  loginFailed: false
};

export default (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case UserActionTypes.LOGIN_ACTION:
      return {
        ...state,
        isLoggedIn: true
      };
    case UserActionTypes.LOGIN_FAILED_ACTION:
      return {
        ...state,
        loginFailed: payload.loginFailed
      };
    case UserActionTypes.LOGOUT_ACTION:
      return {
        ...state,
        isLoggedIn: false
      };
    default:
      return state;
  }
}

export function getUser(store) {
  return store.USER;
}

export function isLoggedIn(store) {
  return store.isLoggedIn;
}
