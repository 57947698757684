import React, {useMemo} from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import {getInvoiceStatus, INVOICE_STATUS_DUE, INVOICE_STATUS_PAID} from "../../services/invoices";
import {useTranslation} from "react-i18next";

const InvoiceStatus = styled.div`
  color: ${({ status }) => status === 'Paid' ? colors.green : colors.red};
`;

function InvoiceStatusWithName({ transaction }) {
  const t = useTranslation()
  const status = useMemo(() => getInvoiceStatus(transaction), [transaction])
  const text = useMemo(() => {
    switch (status) {
    case INVOICE_STATUS_DUE:
      return t.t('invoice.status.due')
    case INVOICE_STATUS_PAID:
      return t.t('invoice.status.paid')
    default:
      return t.t('invoice.status.unknown')
    }
  }, [status])

  return (
    <InvoiceStatus status={status}>
      {text}
    </InvoiceStatus>
  )
}

export default InvoiceStatusWithName
