import React, {useState} from 'react';
import styled from 'styled-components';
import Table from './Table';
import AccordionOpen from '../../resources/img/ui/accordion-open.svg';
import AccordionClose from '../../resources/img/ui/accordion-close.svg';
import ArrowLeft from '../../resources/img/ui/arrow-left.svg';
import ArrowRight from '../../resources/img/ui/arrow-right.svg';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import NoResults from "../core/NoResults";
import BookingCard from "./BookingCard";
import SearchBar from "../core/SearchBar";
import TransportFilters from "../core/TransportFilters";
import { withTranslation } from "react-i18next";
import {MobileOnly} from "../core/FormStyles";

const Accordion = styled.div`
  height: ${props => props.open === false ? 'auto' : '60px'};
  
  @media(min-width: 1070px) {
    display: none;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;

const AccordionImgStyled = styled.img`
  height: auto;
  width: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

const CardBackground = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  width: 100%;
  margin-top: ${props => props.extraMargin && props.mobile && '20px'};
  
  @media(max-width: 1070px) {
    display: ${props => props.desktop && 'none'};
    cursor: pointer;
  }
  
  @media(min-width: 1070px) {
    display: ${props => props.mobile && 'none'}
  }
`;

const CardTitleRow = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.mobile ? 'space-between' : 'flex-start'};
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  
  @media(max-width: 1070px) {
    display: ${props => props.backup && 'none'};
  }
  
  @media(min-width: 1251px){
    border-bottom: 1px solid #DEE2E8;
  }
`;

const CardTitle = styled.div`
  ${fonts.DMSansBold};
  font-size: ${props => props.mobile ? '20px' : '18px'};
  line-height: ${props => props.mobile ? '26px' : '24px'};
  color: ${colors.navyBlue};
  padding-right: 10px;
  
  @media(min-width: 1010px){
    padding-right: 60px;
  }
`;

const CardContent = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${props => props.extraPadding && '15px'};
`;

const Options = styled.div`
  font-size: 14px;
  line-height: 20px;
  ${fonts.DMSansRegular};
  color: ${colors.grey};
  padding: 10px 20px 20px 20px;
  box-sizing: border-box;
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.grey};
  ${fonts.UbuntuRegular};
  align-items: center;
  
  @media(min-width: 1070px){
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const Arrow = styled.img`
  cursor: pointer;
  height: 10px;
  width: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;

const Title = styled.div`
  ${fonts.DMSansRegular};
  font-size: 14px;
  color: ${colors.grey};
  letter-spacing: 0;
  line-height: 20px;
  min-width: 55px;
`;

const PageNumber = styled.div`
  padding-right: 25px;
  padding-left: 10px;
`;

const NoResultsContainer = styled.div`
  padding-left: 20px;
`;

const PaginationBlock = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  align-items: baseline;
`;

const headers = ['Booking Info', 'Freight', 'Shipping', 'Schedule', ''];

function Card({
  title, data, count, pageStart, pageEnd, nextPage, previousPage, changePage,
  transportFilters, addTransportFilter, currentSearch, updateCurrentSearch, t
}) {
  const [isCollapsed, setCollapsed] = useState(false)
  const [search, setSearch] = useState(currentSearch)

  function onSubmit(event) {
    event.preventDefault()
    if (search?.trim()) {
      updateCurrentSearch(search.trim())
    } else {
      updateCurrentSearch(undefined)
    }
  }

  return (
    <>
      <CardBackground desktop>
        <CardTitleRow>
          <CardTitle>{title}</CardTitle>
          <form onSubmit={onSubmit}>
            <SearchBar
              type="text"
              placeholder={t('bookings.search')}
              onChange={e => setSearch(e.currentTarget.value)}
              value={search}
            />
          </form>
          <TransportFilters transportFilters={transportFilters} addTransportFilter={addTransportFilter} />
          {count > 0 && (
            <Pagination>
              <PageNumber>
                {pageStart + 1}-{Math.min(pageEnd, count)} {t('core.of')} {count}
              </PageNumber>
              {previousPage && (
                <Arrow
                  src={ArrowLeft}
                  alt={t('core.load_previous_items')}
                  onClick={() => changePage(previousPage)}
                />
              )}
              {nextPage && (
                <Arrow
                  src={ArrowRight}
                  alt={t('core.load_next_items')}
                  onClick={() => changePage(nextPage)}
                />
              )}
            </Pagination>
          )}
        </CardTitleRow>
        <CardContent>
          <Table data={data} headers={headers}/>
        </CardContent>
      </CardBackground>
      <CardBackground mobile>
        <Accordion open={isCollapsed}>
          <CardHeader onClick={() => setCollapsed(!isCollapsed)}>
            <CardTitleRow mobile>
              <CardTitle mobile>{title}</CardTitle>
              <AccordionImgStyled
                src={isCollapsed ? AccordionOpen : AccordionClose}
                alt={t('core.dropdown')}
              />
            </CardTitleRow>
          </CardHeader>
          {!isCollapsed && (
            <Options>
              <form onSubmit={onSubmit}>
                <SearchBar
                  type="text"
                  placeholder={t('bookings.search')}
                  onChange={e => setSearch(e.currentTarget.value)}
                  value={search}
                />
              </form>
              <TransportFilters transportFilters={transportFilters} addTransportFilter={addTransportFilter} />
              {count > 0 && (
                <PaginationBlock>
                  <Title>Page</Title>
                  <Pagination>
                    <PageNumber>
                      {pageStart}-{Math.min(pageEnd, count)} {t('core.of')} {count}
                    </PageNumber>
                    {previousPage && (
                      <Arrow
                        src={ArrowLeft}
                        alt={t('core.load_previous_items')}
                        onClick={() => changePage(previousPage)}
                      />
                    )}
                    {nextPage && (
                      <Arrow
                        src={ArrowRight}
                        alt={t('core.load_next_items')}
                        onClick={() => changePage(nextPage)}
                      />
                    )}
                  </Pagination>
                </PaginationBlock>
              )}
            </Options>
          )}
        </Accordion>
      </CardBackground>
      <MobileOnly style={{ width: '100%' }}>
        {data.length > 0 ? (
          data.map((item, index) =>
            <CardBackground key={index} mobile extraMargin>
              <BookingCard item={item} key={index}/>
            </CardBackground>
          )
        ) : (
          <NoResultsContainer>
            <NoResults/>
          </NoResultsContainer>
        )}
      </MobileOnly>
    </>
  )
}

export default withTranslation()(Card);
