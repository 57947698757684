export function formatDate(dateString) {
  let date = Date.parse(dateString);

  try {
    return new Intl.DateTimeFormat(
      "en-US",
      {
        year: "numeric",
        month: "short",
        day: "2-digit"
      })
      .format(date);
  } catch (e) {
    console.error(e)
    return ""
  }
}
