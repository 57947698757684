import React, {Component} from 'react';
import styled from 'styled-components';
import Button from '../core/Button';
import {navigate} from "../../../.cache/gatsby-browser-entry";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import { withTranslation } from "react-i18next";

const CardBackground = styled.div`
    margin-top: 20px;
    background: ${colors.white};
    height: 301px;
    width: 500px;
    border-radius: 4px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media(max-width: 650px) {
        width: calc(100% - 40px);
    }
`;

const CardTitle = styled.div`
    font-size: 20px;
    line-height: 26px;
    color: ${colors.navyBlue};
    padding-bottom: 25px;
    ${fonts.DMSansBold};
`;

const Form = styled.form`
    @media (max-width: 650px) {
    width: calc(100% - 40px);
  }
`;

const FieldLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey};
  padding-bottom: 5px;
  ${fonts.UbuntuRegular};
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Field = styled.input`
  height: 42px;
  width: 326px;
  box-sizing: border-box;
  background: ${colors.white};
  border: 1px solid ${colors.lightBlue};
  margin-bottom: 30px;
  border-radius: 3px;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
  }
  color: ${colors.navyBlue};
  ${fonts.UbuntuRegular};
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  ::-webkit-input-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  ::-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-ms-input-placeholder {
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-moz-placeholder { 
    color: ${colors.grey};
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${colors.white} inset !important;
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${colors.navyBlue} !important;
    color: ${colors.navyBlue};
    ${fonts.UbuntuRegular};
    font-size: 14px;
    padding-left: 15px;
  }
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Column = styled.div`
  margin-left: 12.5px;
  margin-right: 12.5px;
  width: calc(50% - 25px);
  @media(max-width: 926px) {
    width: calc(326px + 25px);
  }
  @media (max-width: 650px) {

    width: calc(100%);
    margin-left: 0;
    margin-right: 0;
  }
`;

const SpaceDiv = styled.div`
    padding-top: 15px;
`;

const ButtonBox = styled.div`
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    @media(min-width: 649px){
      padding: 0 86px 0 89px;
    }
`;


class LoginForm extends Component {
  state = {
    email: null
  };

  handleSubmit = (e) => {
    e.preventDefault();

    navigate('/register', {state: this.state});
  };

  handleChange(event) {
    let target = event.target;

    let stateObject = {};
    stateObject[target.name] = target.value;

    this.setState(stateObject);
  }

  render() {
    const {handleSubmit} = this;
    const {t} = this.props;


    return (
      <CardBackground>
        <CardTitle>{t("core.create_account")}</CardTitle>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Column>
              <FieldLabel htmlFor={'registrationEmail'}>{t('login.email_address')}</FieldLabel>
              <Field placeholder="john.doe@myfreightonline.com" name={"email"} onChange={this.handleChange.bind(this)} id={'registrationEmail'} />
            </Column>
          </Row>
        </Form>
        <SpaceDiv />
        <ButtonBox>
          <Button state={this.state} text={t("register.start_registration")} color={'blue'} signin={'true'} onClick={(e) => handleSubmit(e)} />
        </ButtonBox>
      </CardBackground>
    )
  }
}

export default withTranslation()(LoginForm);