import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const ButtonLink = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
    }
    width: ${props => props.signin && '100%'};
    width: ${props => props.fullwidth && '100%'};
    height: ${props => props.medium && '44px'};
    width: ${props => props.medium && '223px'};
    @media(max-width: 990px) {
        width: ${props => props.width && '100%'};
        margin-bottom: ${props => props.width && '20px'};
    }
    @media(max-width: 650px) {
        width: ${props => props.signin && 'calc(100% - 40px)'};
    }
    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: inherit;
    }
`;

const ButtonSimple = styled.button`
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin-left: 2.5px;
    margin-right: 2.5px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
    }
    width: ${props => props.big && '100%'};
    width: ${props => props.signin && '100%'};
    width: ${props => props.fullwidth && '100%'};
    width: ${props => props.medium && '223px'};
    @media(min-width: 650px) {
        width: ${props => props.signin && '325px'};
    }
    @media(max-width: 650px) {
        width: ${props => props.fullwidth && '100%'};
    }
    @media(max-width: 990px) {
        width: ${props => props.width && '100%'};
        width: ${props => props.fullwidth && '100%'};
        margin-bottom: ${props => props.width && '20px'};
    }
    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: inherit;
    }
`;

const ButtonBackground = styled.div`
    position: relative;
    height: 30px;
    height: ${props => props.big && '38px'};
    height: ${props => props.signin && '44px !important'};
    width: ${props => props.width && props.width};
    padding-left: 18px;
    padding-right: 18px;
    padding-left: ${props => props.mediumPadding && '30px'};
    padding-right: ${props => props.mediumPadding && '28px'};
    padding-left: ${props => props.largePadding && '44px'};
    padding-right: ${props => props.largePadding && '41px'};
    color: ${colors.white};
    ${fonts.DMSansBold};
    font-size: ${props => props.signin ? '12px' : '10px'};
    line-height: ${props => props.signin ? '16px' : '13px'};
    letter-spacing: ${props => props.signin ? '1.5px' : '1.25px'};
    height: ${props => props.medium && '44px'};
    text-transform: uppercase;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${props => props.inverted && 'transparent !important'};
    border: ${props => props.inverted ? '1px solid' + colors.navyBlue : '1px solid transparent'};
    color: ${props => props.inverted && colors.navyBlue};
    box-sizing: border-box;
    width: ${props => props.medium && '223px'};
    @media(max-width: 990px) {
        width: ${props => props.width && '100%'};
    }
`;

const StyledLoaderContainer = styled.div`
  margin: 0 !important;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 1px solid ${props => props.background};
  background: ${props => props.background};
`

const StyledLoader = styled.div`
  margin: 0 !important;
  text-align: center;
  
  div {
    margin: 0 !important;
    width: 9px;
    height: 9px;
    background-color: #fff;
  
    border-radius: 100%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .bounce1 {
    animation-delay: -0.32s;
  }
  
  .bounce2 {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% { 
      transform: scale(0);
    } 40% { 
      transform: scale(1.0);
    }
  }
`


function Button({
  state, color, text, link, big, fullwidth, signin, onClick, inverted, width, medium, mediumPadding,
  largePadding, loading, disabled, style,
}) {
  const colors = {
    blue: '#006DC7',
    darkBlue: '#070D49',
    red: '#E76C66',
    green: '#53B4A9'
  };

  return link ? (
    <ButtonLink state={state} to={`${link}`} big={big} fullwidth={fullwidth} signin={signin} onClick={onClick} width={width} medium={medium}>
      <ButtonBackground style={color ? {background: colors[color], ...style} : {background: colors['blue'], ...style}} big={big}
                        signin={signin} inverted={inverted} width={width} medium={medium} disabled={disabled}>
        {text}
      </ButtonBackground>
    </ButtonLink>
  ) : (
    <ButtonSimple style={style} big={big} fullwidth={fullwidth} signin={signin} onClick={onClick} width={width} medium={medium} disabled={disabled}>
      <ButtonBackground style={color ? {background: colors[color]} : {background: colors['blue']}} big={big}
                        signin={signin} inverted={inverted} width={width} medium={medium} mediumPadding={mediumPadding} largePadding={largePadding}>
        {loading && (
          <StyledLoaderContainer background={colors[color]}>
            <StyledLoader>
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </StyledLoader>
          </StyledLoaderContainer>
        )}
        {text}
      </ButtonBackground>
    </ButtonSimple>
  )
}

export default Button;
