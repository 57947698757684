import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql, navigate} from 'gatsby';
import GlobalStyle from '../../styles/global';
import SideBar from './SideBar';
import Header from './Header';
import MobileNav from './MobileNav';
import AdminSideBar from "../admin/AdminSideBar";
import AdminHeader from "../admin/AdminHeader";
import AdminMobileNav from "../admin/AdminMobileNav";
import 'react-day-picker/lib/style.css';
import Loader from "./Loader";
import "./i18n";
import {userService} from '~/services'

function Layout({loading, isDark, children}) {
  useEffect(() => {
    userService.getMe().then((user) => {
      if (user.role === 'ROLE_ADMIN') {
        return navigate('/admin/users')
      }
    })
  },[])

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <React.Fragment>
          {isDark ? <AdminHeader/> : <Header/>}
          {isDark ? <AdminSideBar/> : <SideBar/>}
          <GlobalStyle/>
          {children}
          {isDark ? <AdminMobileNav/> : <MobileNav/>}
          <Loader loading={loading}/>
        </React.Fragment>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
