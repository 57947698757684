import React from 'react';
import {TableContainer, TableBody, TableRow, TableHeader, TableData, Icon, ShipmentLink } from './DashboardStyle'
import Container from "../../resources/img/icons/container-icon.svg";
import Boat from "../../resources/img/icons/boat.svg";
import Plane from "../../resources/img/icons/plane.svg";
import Truck from "../../resources/img/icons/truck.svg";
import Rail from "../../resources/img/icons/rail.svg";
import {withTranslation} from "react-i18next";

const transportMode = {
  FCL: Container,
  LCL: Boat,
  AIR: Plane,
  TRUCK: Truck,
  RAIL: Rail
};

function QuotesTable({ headers, data , t }) {
  return (
    <TableContainer>
      <TableBody>
        <TableRow header>
          {headers.map((header, index) => (
            <TableHeader key={index} headers={headers.length}>
              {header}
            </TableHeader>
          ))}
        </TableRow>
        {data?.map((item, index) => (
          <TableRow key={index}>
            <TableData id="true">
              <Icon
                src={transportMode[item.transportMode]}
                alt={t('new_quote.transport_mode')}
              />
              <ShipmentLink to="/quotes">
                {item.id}
              </ShipmentLink>
            </TableData>
            <TableData>
              {item.originLocation}
            </TableData>
            <TableData>
              {item.destinationLocation}
            </TableData>
            <TableData>
              {formatDate(item.cargoReadyDate)}
            </TableData>
          </TableRow>
        )) ?? null}
      </TableBody>
    </TableContainer>
  )
}

function formatDate(date) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
}

export default withTranslation()(QuotesTable);