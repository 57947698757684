import 'core-js'
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import ShipmentCard from '../components/shipment/ShipmentCard';
import InformationCard from '../components/shipment/InformationCard';
import PrivateRoute from "../router/PrivateRoute"
import {shipmentService} from "../services";
import Breadcrumbs from "../components/core/Breadcrumbs";
import {withTranslation} from "react-i18next";

const PageContainer = styled.div`
  padding-bottom: 240px;
  display: block;
`;

const Background = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 31px 20px 40px 20px;
  left: 0;
  top: 105px;
  width: 100%;
  
  @media(min-width: 767px){
    padding: 14px 35px 76px 35px;
    width: calc(100% - 225px);
    left: 225px;
    top: 70px;
  }
`;

function ShipmentPage({ location, '*': reference, t }) {
  const path = location?.pathname;
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [documents, setDocuments] = useState([])
  const [invoices, setInvoices] = useState([])
  const [activities, setActivities] = useState([])
  const shipment = useMemo(() => {
    if (!data?.shipment) {
      return {}
    }
    return {
      ...data.shipment,
      relatedShipments: data.relatedShipments,
      documents,
      invoices,
      activities,
    }
  }, [data, documents, invoices, activities])

  useEffect(() => {
    shipmentService.get({ reference })
      .then(data => {
        setData(data)

        shipmentService.getDocuments({ reference })
          .then(data => setDocuments(formatDocuments(data)))
        shipmentService.getTransactions({ reference })
          .then(data => setInvoices(data))
        shipmentService.getActivities({ reference })
          .then(data => setActivities(data))
      })
      .finally(() => setLoading(false))
  }, [])

  function formatDocuments(raw) {
    if (!raw) {
      return []
    }
    return [...raw]
      .sort(({ saveDate: a }, { saveDate: b }) => new Date(b).getTime() - new Date(a).getTime())
      .filter((file, index, arr) => {
        const alreadyExists = arr.slice(0, index).some(c =>
          c.docType === file.docType &&
          c.dataType === file.dataType &&
          c.fileName.trim() === file.fileName.trim()
        )
        return !alreadyExists
      })
      .map((file, _, arr) => {
        const hasSameDescription = arr.some(c => c.id !== file.id && c.description.trim() === file.description.trim())
        return {
          ...file,
          description: hasSameDescription ? `${file.description} (${file.fileName}.${file.dataType.toLowerCase()})` : file.description,
        }
      })
  }

  function createActivity(values) {
    const payload = {
      reference,
      ...values,
    }
    return shipmentService.createActivity(payload)
      .then((activity) => setActivities([
        activity,
        ...activities,
      ]))
      .catch(console.error)
  }

  const markers = data?.markers ?? []

  const links = [
    {
      path: "/shipments",
      label: t('core.shipments'),
    },
  ];

  return (
    <Layout loading={loading}>
      <SEO title={t('core.shipment') + " " + reference} path={path}/>
      {shipment && (
        <PageContainer>
          <Background>
            <Breadcrumbs
              current={reference}
              links={links}
            />
            <ShipmentCard
              data={shipment}
            />
            <InformationCard
              shipment={shipment}
              markers={markers}
              createActivity={createActivity}
            />
          </Background>
        </PageContainer>
      )}
    </Layout>
  )
}

function shipment(props) {
  return <PrivateRoute component={ShipmentPage} {...props}/>
}

export default withTranslation()(shipment);
