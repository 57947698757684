import mapboxgl from 'mapbox-gl';
import {navigate} from "gatsby";
import colors from "../styles/colors";

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibWZvYXBwIiwiYSI6ImNrZDFrM2QydzE0NHoyc243NDdhZTQ0cDQifQ.FghOPI3fVlUCz83pcOmkVg';
const MAPBOX_STYLE_URL = 'mapbox://styles/mfoapp/ckd1l0dxv1b801iobjr9mxerq';

const MIN_LATITUDE = 90;
const MIN_LONGITUDE = 180;
const MAX_LATITUDE = -90;
const MAX_LONGITUDE = -180;

function createMap(mapContainer) {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

  return new mapboxgl.Map({
    container: mapContainer,
    style: MAPBOX_STYLE_URL,
    center: [48.860739, 2.337665],
    zoom: 0,
  });
}

function addMarkers(markers, map) {
  const geojson = {
    type: 'FeatureCollection',
    features: getFeatures(markers)
  };

  geojson.features.forEach((marker) => {
    const el = document.createElement('div');
    const properties = marker.properties;

    let className = 'marker ' + properties.transport.toLowerCase();
    if (properties.status === 'arrived') {
      className = className + ' complete';
    } else if (properties.status === 'awaiting_departure') {
      className = className + ' incomplete';
    }
    el.className = className;
    el.onclick = () => navigate('/shipment/' + properties.shipmentId);

    addMarker(el, marker, map);
  });
}

let len = 0
function addMarker(element, marker, map) {
  const {shipmentId} = marker.properties;
  const popup = new mapboxgl.Popup({
    closeOnClick: false,
    closeButton: false,
  }).setText(`${shipmentId}`);

  element.addEventListener('mouseenter', () => popup.addTo(map));
  element.addEventListener('mouseleave', () => popup.remove());

  new mapboxgl.Marker(element)
    .setLngLat(marker.geometry.coordinates)
    .setPopup(popup)
    .addTo(map);
}

function setMapBounds(markers, map, additionalSpace) {
  let minLatitude = MIN_LATITUDE;
  let minLongitude = MIN_LONGITUDE;
  let maxLatitude = MAX_LATITUDE;
  let maxLongitude = MAX_LONGITUDE;

  markers.forEach((marker) => {
    const latitude = marker.latitude;
    const longitude = marker.longitude;

    minLatitude = Math.min(minLatitude, latitude);
    maxLatitude = Math.max(maxLatitude, latitude);
    minLongitude = Math.min(maxLatitude, longitude);
    maxLongitude = Math.max(maxLongitude, longitude);
  });

  minLongitude = Math.max(MAX_LONGITUDE, minLongitude - additionalSpace);
  minLatitude = Math.max(MAX_LATITUDE, minLatitude - additionalSpace);
  maxLongitude = Math.min(MIN_LONGITUDE, maxLongitude + additionalSpace);
  maxLatitude = Math.min(MIN_LATITUDE, maxLatitude + additionalSpace);

  const mapBounds = [
    [minLongitude, minLatitude],
    [maxLongitude, maxLatitude]
  ];

  map.fitBounds(
    mapBounds
  );
}

function getFeatures(markers) {
  // console.log(markers)
  //
  // const features = markers.reduce((features, marker) => {
  //   if (!features[marker.locode]) {
  //     features[marker.locode] = {
  //       geometry: {
  //         type: 'Point',
  //         coordinates: [
  //           marker.longitude,
  //           marker.latitude
  //         ]
  //       },
  //       properties: {
  //         title: marker.locode,
  //         description: marker.name,
  //         transport: marker.transport,
  //         type: marker.type,
  //         shipmentId: [],
  //         status: marker.status,
  //       }
  //     }
  //   }
  //
  //   return features
  // }, {})

  return markers.map((marker) => {
    let transport = marker.transport
    if (transport === 'ROA') {
      transport = 'LAND'
    }

    return {
      geometry: {
        type: 'Point',
        coordinates: [
          marker.longitude,
          marker.latitude
        ]
      },
      properties: {
        title: marker.locode,
        description: marker.name,
        transport,
        type: marker.type,
        shipmentId: marker.shipmentId,
        status: marker.status
      }
    }
  })
}

function addRouteAnimation(markers, map) {
  let origin;
  let destination;
  let icon;
  let transport;
  markers.forEach((marker) => {
    const coordinates = [marker.longitude, marker.latitude];

    if (marker.type === 'origin') {
      origin = coordinates;
    } else {
      destination = coordinates;
    }

    transport = marker.transport.toLowerCase();

    icon = transport;

    const status = marker.status;
    if (status === 'arrived') {
      icon = icon + '-complete';
    } else if (status === 'awaiting_departure') {
      icon = icon + '-incomplete';
    }
  });

  if (!origin || !destination) {
    return;
  }

  const point = {
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'Point',
          'coordinates': origin
        }
      }
    ]
  };

  const route = {
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': [origin, destination]
        }
      }
    ]
  };

  if (route.features.length < 1) {
    return;
  }

  const turf = window.turf;

  let lineDistance = turf.lineDistance(route.features[0], 'kilometers');

  let arc = [];

  let steps = 500;

  for (let i = 0; i < lineDistance; i += lineDistance / steps) {
    let segment = turf.along(route.features[0], i, 'kilometers');
    arc.push(segment.geometry.coordinates);
  }

  route.features[0].geometry.coordinates = arc;

  let counter = 0;

  map.addSource('route', {
    'type': 'geojson',
    'data': route
  });

  map.addSource('point', {
    'type': 'geojson',
    'data': point
  });

  map.addLayer({
    'id': 'route',
    'source': 'route',
    'type': 'line',
    'paint': {
      'line-width': 2,
      'line-color': colors.green
    }
  });

  if (transport !== 'air') {
    return;
  }

  map.addLayer({
    'id': 'point',
    'source': 'point',
    'type': 'symbol',
    'layout': {
      'icon-image': icon,
      'icon-rotate': ['get', 'bearing'],
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    }
  });

  function animate() {
    const turf = window.turf;

    point.features[0].geometry.coordinates =
      route.features[0].geometry.coordinates[counter];

    point.features[0].properties.bearing = turf.bearing(
      turf.point(
        route.features[0].geometry.coordinates[
          counter >= steps ? counter - 1 : counter
          ]
      ),
      turf.point(
        route.features[0].geometry.coordinates[
          counter >= steps ? counter : counter + 1
          ]
      )
    );

    map.getSource('point').setData(point);

    if (counter < steps) {
      requestAnimationFrame(animate);
    }

    counter = counter + 1;
  }

  animate();
}

export {
  createMap,
  addMarkers,
  addRouteAnimation,
  setMapBounds
}
