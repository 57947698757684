import styled from "styled-components";
import colors from "../../styles/colors";
import {Link} from "gatsby";
import fonts from "../../styles/fonts";

const TableContainer = styled.table`
    width: 100%;
    @media(max-width: 768px) {
        display: none;
    }
`;

const TableContainerMobile = styled.table`
    width: 100%;
    margin-bottom: 16px;
`;

const TableBody = styled.tbody`
    width: 100%;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid ${colors.lightBlue};
    height: ${props => props.header ? '64px' : '80px'};
    :last-child {
        border-bottom: none;
    };
`;

const TableHeader = styled.th`
    text-align: left;
    color: ${colors.grey};
    font-size: 12px;
    line-height: 18px;
    ${fonts.DMSansRegular};
    vertical-align: middle;
    @media(min-width: 768px){
        font-size: 14px;
        line-height: 20px;
        width: ${props => props.headers === 4 ? '25%' : '33%'};
        :first-child {
            min-width: 180px;
        }
    }
`;

const TableData = styled.td`
    ${fonts.UbuntuRegular};
    font-size: 12px;
    line-height: 18px;
    color: ${colors.navyBlue};
    vertical-align: middle;
    padding-right: 5px;
    @media(min-width: 768px){
        font-size: 14px;
        line-height: 20px;
        color: ${props => props.id === "true" ? colors.blue : colors.navyBlue};
    }
`;

const Icon = styled.img`
    padding-right: 15px;
    margin-bottom: -4px;
`;

const ShipmentLink = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    outline: none;
    color: ${colors.blue};
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border: none;
        outline: none;
        box-shadow: none;
    }
`;

const ProgressBar = styled.div`
    width: 149px;
    height: 3px;
    border-radius: 100px;
    background: ${colors.lightBlue};
    margin-top: 12px;
    margin-bottom: 2px;
`;

const Progress = styled.div`
    background: ${colors.green};
    border-radius: 100px;
    height: 100%;
`;

const Card = styled.div`
    border-bottom: 1px solid ${colors.lightBlue};
    min-height: ${props => props.open === false ? '127px' : '60px'};
    &:last-of-type {
      border-bottom: none;
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
`;

const CardHeaderText = styled.div`
    ${fonts.UbuntuRegular};
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.id ? colors.blue : colors.navyBlue};
    vertical-align: middle;
    a{
        cursor: pointer;
        text-decoration: none;
        border: none;
        background: transparent;
        outline: none;
        &:hover,
        &:active,
        &:focus,
        &:visited {
            text-decoration: none;
            border: none;
            background: transparent;
            outline: none;
            -webkit-tap-highlight-color: transparent;
        }
    }
`;

const AccordionImgStyled = styled.img`
    height: auto;
    width: 10px;
    margin-left: 10px;
    cursor: pointer;
`;

export {
    TableContainer,
    TableContainerMobile,
    TableBody,
    TableRow,
    TableHeader,
    TableData,
    Icon,
    ShipmentLink,
    ProgressBar,
    Progress,
    Card,
    CardHeader,
    CardHeaderText,
    AccordionImgStyled,
}
