import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import MapBlock from '../components/dashboard/MapBlock';
import Card from '../components/dashboard/Card';
import PrivateRoute from "../router/PrivateRoute"
import {dashboardService} from "../services";
import Shipments from "../components/dashboard/Shipments";
import QuotesTable from "../components/dashboard/QuotesTable";
import Bookings from "../components/dashboard/Bookings";
import Toggle from "../components/dashboard/Toggle";
import ShipmentsMobile from "../components/dashboard/ShipmentsMobile";
import BookingsMobile from "../components/dashboard/BookingsMobile";
import Invoices from "../components/dashboard/Invoices";
import InvoicesMobile from "../components/dashboard/InvoicesMobile";
import InvoicesToggle from "../components/dashboard/InvoicesToggle";
import NoResults from "../components/core/NoResults";
import {Row, Col} from "styled-bootstrap-grid"
import breakpoints from "../styles/breakpoints"
import QuotesMobile from "../components/dashboard/QuotesMobile";
import { withTranslation } from "react-i18next";

const StyledRow = styled(Row)`
  width: calc(100% - 30px);
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  width: 100%;
  min-width: 200px;
  padding: 10px;
  box-sizing: border-box;

  @media(min-width: ${breakpoints.lg}) {
    width: 50%;
  }
`;

const Dashboard = styled.div`
  width: calc(100% - 225px);
  left: 225px;
  top: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 80px;
  padding-top: 10px;
    
  @media(max-width: 767px) {
    left: 0;
    top: 90px;
    width: 100%;
    padding-bottom: 85px;
  }
`;

const Accordion = styled.div`
  width: 100%;

  @media(min-width: 769px) {
    display: none;
  }
`;

function IndexPage({ location, t }) {
  const path = location?.pathname;
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    dashboardService.getData()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }, [])

  const {shipments, bookings, quotes, invoices, markers = []} = data
  const shipmentHeaders = [t('core.shipment') + " ID", t('core.origin'), t('core.destination'), t('core.status')];
  const bookingHeaders = [t('core.booking') + " ID", t('dashboard.counterpart'), t('core.status')];
  const quoteHeaders = [t('core.quote') + " ID", t('core.origin'), t('core.destination'), t('dashboard.ready_date')];

  return (
    <Layout loading={loading}>
      <SEO
        title={t('dashboard.title')}
        description={t('dashboard.description')}
        fullTitle={true}
        path={path}
      />
      <MapBlock markers={markers}/>
      <Dashboard>
        <StyledRow>
          <StyledCol xl={6} order={shipments && shipments.length > 0 ? "first" : "last"}>
            <Card title={t('core.shipments')} data={shipments} headers={shipmentHeaders} link="/shipments" subTitle={t('dashboard.latest_arrivals_dates')}>
              {(shipments && shipments.length > 0) ?
                <React.Fragment>
                  <Shipments data={shipments} headers={shipmentHeaders}/>
                  <Accordion>
                    {shipments && shipments.map((item, index) =>
                      <Toggle item={item} key={index} link={`/shipment/${item.reference}`}>
                        <ShipmentsMobile item={item} headers={shipmentHeaders}/>
                      </Toggle>
                    )}
                  </Accordion>
                </React.Fragment>
                :
                <NoResults/>
              }
            </Card>
          </StyledCol>

          <StyledCol xl={6} order={bookings && bookings.length > 0 ? "first" : "last"}>
            <Card title={t('core.bookings')} data={bookings} headers={bookingHeaders} link={'/bookings'}>
              {(bookings && bookings.length > 0) ?
                <React.Fragment>
                  <Bookings data={bookings} headers={bookingHeaders}/>
                  <Accordion>
                    {bookings && bookings.map((item, index) => {
                        return (item ? <Toggle item={item} key={index} link={false}>
                            <BookingsMobile item={item} headers={bookingHeaders}/>
                          </Toggle>
                          : null)
                      }
                    )}
                  </Accordion>
                </React.Fragment>
                :
                <NoResults/>
              }
            </Card>
          </StyledCol>
          <StyledCol xl={6} order={quotes && quotes.length > 0 ? "first" : "last"}>
            <Card title={t('core.quotes')} data={quotes} headers={quoteHeaders} link="/quotes">
              {(quotes && quotes.length > 0) ? (
                <>
                  <QuotesTable data={quotes} headers={quoteHeaders}/>
                  <Accordion>
                    {quotes?.map((item, index) => item ? (
                      <Toggle item={item} key={index} link={false}>
                        <QuotesMobile item={item} headers={quoteHeaders}/>
                      </Toggle>
                    ) : null) ?? null}
                  </Accordion>
                </>
              ) : <NoResults/>}
            </Card>
          </StyledCol>

          <StyledCol xl={6} order={invoices && invoices.length > 0 ? "first" : "last"}>
            <Card title={t('core.invoices')} data={invoices} link={'/invoices'}>
              {(invoices && invoices.length > 0) ?
                <React.Fragment>
                  <Invoices data={invoices} />
                  <Accordion>
                    {invoices && invoices.map((item, index) => {
                        return (item ? <InvoicesToggle item={item} key={index} link={`/shipment/${item.desc}`}>
                            <InvoicesMobile item={item} />
                          </InvoicesToggle>
                          : null)
                      }
                    )}
                  </Accordion>
                </React.Fragment>
                : <NoResults/>
              }
            </Card>
          </StyledCol>
        </StyledRow>
      </Dashboard>
    </Layout>
  )
}

function index(props) {
  return <PrivateRoute component={IndexPage} {...props}/>
}

export default withTranslation()(index);
