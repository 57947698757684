import UserActionTypes from '../../actionTypes/user';
import {authService} from "../../../services";
import {navigate} from "gatsby";

const loginAction = (email, password) => (dispatch) => {
  dispatch({
    type: UserActionTypes.LOGIN_FAILED_ACTION,
    payload: {
      loginFailed: false
    }
  });

  authService.login({ email, password })
    .then(({ user }) => {
      window.localStorage.isLoggedIn = true;
      dispatch({
        type: UserActionTypes.LOGIN_ACTION,
        payload: {}
      });
      if (user.role === 'ROLE_ADMIN') {
        return navigate('/admin/users');
      } else if (!user.onBoardingFinish) {
        return navigate('/onboarding/company');
      }
      return navigate('/');
    })
    .catch((err) => {
      window.localStorage.isLoggedIn = false;
      dispatch({
        type: UserActionTypes.LOGIN_FAILED_ACTION,
        payload: {
          loginFailed: true
        }
      });
    });
};

export {
  loginAction
}
