import React from 'react';
import styled from 'styled-components';
import Boat from '../../resources/img/icons/marker-boat.svg';
import Plane from '../../resources/img/icons/marker-plane.svg';
import Rail from '../../resources/img/icons/marker-rail.svg';
import Truck from '../../resources/img/icons/marker-truck.svg';
import colors from "../../styles/colors";

const LoaderBlock = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: ${colors.white};
  display: ${props => props.loading === 'false' ? 'none' : 'flex'};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  -webkit-animation-name: iconsAnimation;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-name: iconsAnimation;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  
  :first-child {
  }
  
  :nth-child(2) {
   animation-delay: 0.14s;
  }
  
  :nth-child(3) {
    animation-delay: 0.3s;
  }
  
  :last-child {
    animation-delay: 0.45s;
  }
  
  @-webkit-keyframes iconsAnimation {
    0%   {left:0px; top:0px; height: 32px; width: 32px;}
    25%  {left:0px; top:0px; height: 64px; width: 64px;}
    50%  {left:0px; top:0px; height: 32px; width: 32px;}
    75%  {left:0px; top:0px; height: 64px; width: 64px;}
    100% {left:0px; top:0px; height: 32px; width: 32px;}
  }
  
  @keyframes iconsAnimation {
    0%   {left:0px; top:0px; height: 32px; width: 32px;}
    25%  {left:0px; top:0px; height: 64px; width: 64px;}
    50%  {left:0px; top:0px; height: 32px; width: 32px;}
    75%  {left:0px; top:0px; height: 64px; width: 64px;}
    100% {left:0px; top:0px; height: 32px; width: 32px;}
  }
`;

const Loader = ({loading}) => {
  return (
    <LoaderBlock loading={loading ? loading.toString() : 'false'}>
      <Icon src={Boat} />
      <Icon src={Plane} />
      <Icon src={Rail} />
      <Icon src={Truck} />
    </LoaderBlock>
  )
};

export default Loader;
