import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";

const StyledMessage = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: 'Ubuntu', sans-serif;
  color: ${colors.navyBlue};
  margin-bottom: 50px;
`;

const SuccessMessage = (props) => {
  const {children, className} = props;

  return (
    <StyledMessage className={className}>
      {children}
    </StyledMessage>
  )

};

export default SuccessMessage;