import React, {useEffect, useState} from "react"
import styled, {css} from "styled-components"
import {Container, Row, Col} from "styled-bootstrap-grid"
import {Form, Field} from "react-final-form";
import ReactPopup from 'reactjs-popup';
import {SelectStyled} from "../core/FormStyles";
import CloseIcon from "../../resources/img/ui/close-popup.svg"
import Button from "../core/Button";
import {organisationService} from "../../services";
import ErrorMessage from "../core/forms/ErrorMessage";
import SuccessMessage from "../core/forms/SuccessMessage";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import { updateOrganisation } from "../../store/organisation";
import {useDispatch} from "react-redux";
import {FORM_ERROR} from "final-form";

const contentStyle = {
  margin: '45px auto auto',
  border: '0',
  height: 'auto',
  overflow: 'hidden',
  position: 'relative',
  width: '64%',
  maxWidth: '925px',
  maxHeight: '478px'
};

const contentStyleMobile = {
  margin: '40px 20px',
  width: '100%',
  border: '0',
  height: 'auto',
  overflow: 'hidden',
  position: 'relative',
};

const overlayStyle = {
  overflowY: 'scroll',
  marginTop: '70px',
  marginLeft: '225px',
  paddingBottom: '45px'
};

const overlayStyleMedium = {
  overflow: 'scroll',
  marginTop: '70px',
  marginLeft: '225px',
};

const overlayStyleMobile = {
  overflow: 'scroll',
  marginTop: '90px'
};

const ColStyled = styled(Col)`
  padding: 0;
`;

const Box = styled.div`
  margin: 0 11px;
`;

const PopUpContent = styled.div`
  padding: 25px 20px 41px 20px;
  
  @media(min-width: 990px){
    padding: 30px 32px 72px 32px;
  }
`;

const ContainerStyled = styled(Container)`
  width: auto;
  padding-left: 0;
  padding-right: 0;
  
  @media(min-width: 990px){
    padding-left: 94px;
    padding-right: 93px;
  }
`;

const Close = styled.img`
  float: right;
  cursor: pointer;
`;

const Title = styled.div`
  color: ${colors.navyBlue};
  ${fonts.DMSansBold};
  text-align: center;
  font-size: 22px;
  padding-bottom: 22px;
  padding-top: 32px;
  
  @media(min-width: 990px){
    padding-top: 32px;
  }
`;

const Subtitle = styled.div`
  ${fonts.UbuntuRegular};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.grey};
  text-align: center;
  padding-bottom: 43px;
`;


const Input = styled.input`
  height: 42px;
  box-sizing: border-box;
  border: 1px solid ${colors.lightBlue};
  border-radius: 3px;
  width: 100%;
  margin-right: 22px;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  
  color: ${colors.navyBlue};
  ${fonts.UbuntuRegular};
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  
  ::-webkit-input-placeholder { 
    color: #939BA6;
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  ::-moz-placeholder { 
    color: #939BA6;
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  :-ms-input-placeholder {
    color: #939BA6;
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  :-moz-placeholder { 
    color: #939BA6;
    ${fonts.UbuntuRegular};
    font-size: 14px;
  }
  
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  
  :-webkit-autofill {
    -webkit-text-fill-color: #070D49 !important;
    color: ${colors.navyBlue};
    ${fonts.UbuntuRegular};
    font-size: 14px;
    padding-left: 15px;
  }
`;

const FieldLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  ${fonts.UbuntuRegular};
  color: ${colors.grey};
  padding-bottom: 5px;
`;

const StyledSuccessMessage = styled(SuccessMessage)`
  text-align: center;
`;

function FormInput({ input, meta, ...props }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <Input {...props} {...input} />
      {(meta.touched && meta.error) && (
        <ErrorMessage css={css`margin-top: 6px`}>
          {meta.error}
        </ErrorMessage>
      )}
    </div>
  )
}

function FormSelect({ input, meta, children, ...props }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <SelectStyled {...props} {...input} css={css`margin-bottom: 0 !important;`}>
        {children}
      </SelectStyled>
      {(meta.touched && meta.error) && (
        <ErrorMessage css={css`margin-top: 6px`}>
          {meta.error}
        </ErrorMessage>
      )}
    </div>
  )
}

function EditOrganisationPopup({ organisation, close }) {
  const [isFormSuccess, setFormSuccess] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    function updateWindowDimensions() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', updateWindowDimensions);
    updateWindowDimensions()
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    }
  }, [])

  function validate(values) {
    const errors = {}

    if (!values.name) {
      errors.name = 'Required.'
    } else if (values.name.length > 50) {
      errors.name = 'Maximum 50 characters.'
    }

    if (!values.code) {
      errors.code = 'Required.'
    } else if (values.code.length > 15) {
      errors.code = 'Maximum 15 characters.'
    }

    if (!values.currency) {
      errors.currency = 'Required.'
    }

    return errors
  }

  function onSubmit(values) {
    return organisationService.update({
      id: organisation.id,
      ...values,
    })
      .then(data => {
        dispatch(updateOrganisation(data))
        setFormSuccess(true)
      })
      .catch(error => {
        if (error.isAxiosError) {
          return { [FORM_ERROR]: error.response.data.message }
        }
        return { [FORM_ERROR]: 'Unknown error.' }
      })
  }

  const overlayStyles = windowDimensions.width >= 990
    ? overlayStyle
    : windowDimensions.width >= 768
      ? overlayStyleMedium
      : overlayStyleMobile
  const contentStyles = windowDimensions.width >= 990
    ? contentStyle
    : contentStyleMobile

  return (
    <ReactPopup
      open={true}
      onClose={close}
      closeOnDocumentClick
      overlayStyle={overlayStyles}
      contentStyle={contentStyles}>
      <PopUpContent>
        <Close onClick={close} src={CloseIcon}/>
        <Title>Update Organisation</Title>
        <Subtitle>.</Subtitle>
        {isFormSuccess ? (
          <ContainerStyled>
            <StyledSuccessMessage className="styledSuccessMessage">
              Organisation updated successfully!
            </StyledSuccessMessage>
          </ContainerStyled>
        ) : (
          <Form
            initialValues={organisation}
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, submitError }) => (
              <form onSubmit={handleSubmit}>
                <ContainerStyled>
                  <Row>
                    <ColStyled lg={6}>
                      <Box>
                        <FieldLabel>Name</FieldLabel>
                        <Field component={FormInput} placeholder="Name" name="name" />
                      </Box>
                    </ColStyled>
                    <ColStyled lg={6}>
                      <Box>
                        <FieldLabel>Code</FieldLabel>
                        <Field component={FormInput} placeholder="Code" name="code" />
                      </Box>
                    </ColStyled>
                    <ColStyled lg={6}>
                      <Box>
                        <FieldLabel>Currency</FieldLabel>
                        <Field component={FormSelect} placeholder="Currency" name="currency">
                          <option value="">Select</option>
                          <option value="EUR">EUR</option>
                          <option value="GBP">GBP</option>
                          <option value="USD">USD</option>
                        </Field>
                      </Box>
                    </ColStyled>
                    <ColStyled lg={6}>
                      <Box>
                        <FieldLabel>Bansard Entity</FieldLabel>
                        <Field component={FormSelect} placeholder="Bansard Entity" name="entity">
                          <option value="">Select</option>
                          <option value="MAR">BANSARD MAROC SARL</option>
                          <option value="LIS">LOGISTIC INTERNATIONAL SERVICE LIMITED</option>
                          <option value="CHN">BANSARD CHINA</option>
                          <option value="USA">BANSARD ANKER INTERNATIONAL, LLC</option>
                          <option value="HKG">Bansard International Hong Kong Limited</option>
                          <option value="IND">BANSARD INDIA PRIVATE LIMITED</option>
                          <option value="TUN">BANSARD TUNISIE</option>
                          <option value="FRA">BANSARD FRANCE</option>
                          <option value="SGP">Bansard International (Singapore) Pte Ltd</option>
                          <option value="VNM">BANSARD VIETNAM</option>
                          <option value="BGD">BIL LOGISTICS INTERNATIONAL LIMITED</option>
                          <option value="ESP">BI LOGISTIQUE SL</option>
                          <option value="BEL">BANSARD BELGIQUE SPRL</option>
                          <option value="CLS">CLSBANSARD (HK) LIMITED</option>
                          <option value="THA">BANSARD INTERNATIONAL (THAILAND) CO., LTD</option>
                          <option value="DEM">Demo Company</option>
                          <option value="ISR">IBL LOGISTICS LTD</option>
                          <option value="GSM">GLOBAL SOURCING MANAGEMENT LIMITED</option>
                          <option value="KHM">BANSARD INTERNATIONAL (CAMBODIA) CO., LTD</option>
                        </Field>
                      </Box>
                    </ColStyled>
                  </Row>
                  <Button text="Update Organisation" type="submit" />
                  {submitError && (
                    <ErrorMessage>
                      {submitError}
                    </ErrorMessage>
                  )}
                </ContainerStyled>
              </form>
            )}
          />
        )}
      </PopUpContent>
    </ReactPopup>
  )
}

export default EditOrganisationPopup;
