import React, {useState} from 'react';
import {formatDate} from "../../services/core/dateHelper";
import {TableContainerMobile, TableBody, TableHeader, TableData, ShipmentLink} from "./DashboardStyle";
import Button from "../core/Button";
import {documentService} from "../../services";
import downloadjs from "downloadjs";
import InvoiceStatusWithName from "../core/InvoiceStatusWithName";
import {useTranslation} from "react-i18next";

function InvoicesMobile({item}) {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  function download({ document }) {
    if (loading) {
      return
    }

    setLoading(true)
    documentService.download({ id: document.id })
      .then(blob => downloadjs(window.atob(blob), `${document.fileName}.pdf`, 'application/pdf'))
      .catch(() => alert(t('core.no_documents')))
      .finally(() => setLoading(false))
  }

  return (
    <TableContainerMobile>
      <TableBody>
        <tr>
          <TableHeader>{t('dashboard.invoice.due_date')}</TableHeader>
          <TableHeader>{t('dashboard.invoice.status')}</TableHeader>
        </tr>
        <tr>
          <TableData>
            {item.dueDate && formatDate(item.dueDate)}
          </TableData>
          <TableData>
            <InvoiceStatusWithName transaction={item} />
          </TableData>
        </tr>
        <tr>
          <TableHeader>{t('dashboard.invoice.shipment_id')}</TableHeader>
          <TableHeader />
        </tr>
        <tr>
          <TableData>
            <ShipmentLink to={`/shipment/${item.shipmentId}`}>
              {item.shipmentId}
            </ShipmentLink>
          </TableData>
          <TableData>
            <Button
              color="blue"
              text={item.document ? t('core.download') : t('core.download_unavailable')}
              disabled={!item.document}
              loading={loading}
              onClick={() => download(item)}
            />
          </TableData>
        </tr>
      </TableBody>
    </TableContainerMobile>
  )
}

export default InvoicesMobile;
