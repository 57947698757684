import React, { Component } from 'react';
import styled from 'styled-components';
import DashboardIcon from '../../resources/img/nav/dashboard-icon.svg';
import ShipmentsIcon from '../../resources/img/nav/shipments-icon.svg';
import BookingsIcon from '../../resources/img/nav/booking-icon.svg';
// import QuotesIcon from '../../resources/img/nav/quotes-icon.svg';
// import InvoicesIcon from '../../resources/img/invoices-icon.svg';
import DashboardIconSelected from '../../resources/img/nav/dashboard-icon-selected.svg';
import ShipmentsIconSelected from '../../resources/img/nav/shipments-icon-selected.svg';
import BookingsIconSelected from '../../resources/img/nav/booking-icon-selected.svg';
// import QuotesIconSelected from '../../resources/img/nav/quotes-icon-selected.svg';
// import InvoicesIconSelected from '../../resources/img/invoices-icon-selected.svg';
import { Link } from 'gatsby';
// import MessagesIcon from '../../resources/img/messages-icon.svg';
// import MessagesIconSelected from '../../resources/img/messages-icon-selected.svg';
// import SettingsIcon from '../../resources/img/settings-icon.svg';
// import SettingsIconSelected from '../../resources/img/settings-icon-selected.svg';
// import MoreIcon from '../../resources/img/ui/more-icon.svg';
// import MoreIconSelected from '../../resources/img/ui/more-icon-selected.svg';
// import colors from "../../styles/colors";

const SideBarBackground = styled.div`
    height: 85px;
    background: #FFF;
    width: 100%;
    position: fixed;
    z-index: 9999;
    overflow: hidden;
    bottom: 0;
    display: flex;
    flex-direction: wrap;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: space-around;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    @media(min-width: 768px) {
        display: none;
    }
`;

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    background: ${props => props.active === "true" ? "linear-gradient(180deg, rgba(247, 251, 255, 100), rgba(229, 240, 249, 100))" : "transparent"};
    border-bottom: ${props => props.active === "true" ? "4px solid #006DC7" : "4px solid transparent"};
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    width: 20%;
    
`;

const LinkIcon = styled.img`
    height: 20px;
    max-width: 26px;
    padding-bottom: 10px;
`;

const LinkText = styled.div`
    color: #939BA6;
    font-size: 10px;
    font-family: 'DM Sans', sans-serif;
`;

// const SecondaryLinks = styled.div`
//     border-top: 1px solid #393E6E;
//     border-bottom: 1px solid #393E6E;
//     margin-top: 45px;
//     padding-top: 15px;
//     padding-bottom: 15px;
// `;

// const MessageCount = styled.div`
//     height: 26px;
//     width: 26px;
//     border-radius: 100px;
//     background: #006DC7;
//     color: ${colors.white};
//     font-family: 'DM Sans', sans-serif;
//     font-weight: bold;
//     font-size: 12px;
//     line-height: 16px;
//     margin-left: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `;


class SideBar extends Component {
    state = {
        activeLink: '/',
    }
    changeActive = (props) => {
        this.setState(() => {
            return { activeLink: props };
        });
    }
    componentDidMount() {
        if (typeof window !== 'undefined') {
            let activeLink = window.location;

            this.setState(() => {
                return { activeLink: activeLink.pathname };
            });
        }
    }

    render() {
        return (
            <SideBarBackground>
                <MenuLink to="/" active={`${this.state.activeLink === "/"}`} onClick={() => this.changeActive('/')}>
                    <LinkIcon src={this.state.activeLink === '/' ? DashboardIconSelected : DashboardIcon} alt='Icon for Dashboard Page' />
                    <LinkText>Dashboard</LinkText>
                </MenuLink>
                <MenuLink to="/shipments" active={`${this.state.activeLink.includes("/shipment")}`} onClick={() => this.changeActive('/shipments')}>
                    <LinkIcon src={this.state.activeLink.includes("/shipment") ? ShipmentsIconSelected : ShipmentsIcon} alt='Icon for Shipments Page' />
                    <LinkText>Shipments</LinkText>
                </MenuLink>
                <MenuLink to="/bookings" active={`${this.state.activeLink.includes("/booking")}`} onClick={() => this.changeActive('/bookings')}>
                    <LinkIcon src={this.state.activeLink.includes("/booking") ? BookingsIconSelected : BookingsIcon} alt='Icon for Bookings Page' />
                    <LinkText>Bookings</LinkText>
                </MenuLink>
                {/*<MenuLink to="/quotes" active={`${this.state.activeLink.includes("/quote")}`} onClick={() => this.changeActive('/quotes')}>*/}
                {/*    <LinkIcon src={this.state.activeLink.includes("/quote") ? QuotesIconSelected : QuotesIcon} alt='Icon for Quotes Page' />*/}
                {/*    <LinkText>Quotes</LinkText>*/}
                {/*</MenuLink>*/}
                {/*<MenuLink to="/" active={`${this.state.activeLink === "/more"}`} onClick={() => this.changeActive('/more')}>*/}
                {/*    <LinkIcon src={this.state.activeLink === '/more' ? MoreIconSelected : MoreIcon} alt='Icon for More Options' />*/}
                {/*    <LinkText>More</LinkText>*/}
                {/*</MenuLink>*/}
                {/* <SecondaryLinks>
                    <MenuLink to="/" active={this.store.activeLink === "messages"} onClick={()=>this.changeActive('messages')}>
                        <LinkIcon src={this.store.activeLink === 'messages' ? MessagesIconSelected : MessagesIcon}/>
                        <LinkText>Messages</LinkText>
                        <MessageCount>0</MessageCount>
                    </MenuLink>
                    <MenuLink to="/" active={this.store.activeLink === "settings"} onClick={()=>this.changeActive('settings')}>
                        <LinkIcon src={this.store.activeLink === 'settings' ? SettingsIconSelected : SettingsIcon}/>
                        <LinkText>Invoices</LinkText>
                    </MenuLink>
                </SecondaryLinks> */}
            </SideBarBackground>
        )
    }
}

export default SideBar;