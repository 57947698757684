import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '../core/Button';
import {formatDate} from "../../services/core/dateHelper";
import downloadjs from "downloadjs";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import { documentService } from "../../services";
import {Link} from "gatsby";
import { withTranslation } from "react-i18next";
import InvoiceStatusWithName from "../core/InvoiceStatusWithName";

const TableContainer = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableRow = styled.tr`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledDiv = styled.div`
  margin-top: 20px;
`;

const TableHeader = styled.th`
  text-align: left;
  color: ${colors.grey};
  font-size: 12px;
  line-height: 18px;
  ${fonts.DMSansRegular};
  vertical-align: middle;
  
  @media(min-width: 915px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const TableData = styled.td`
  ${fonts.UbuntuRegular};
  font-size: 12px;
  line-height: 18px;
  color: ${colors.navyBlue};
  padding: 0 5px 0 0;
  vertical-align: middle;
  width: 25%;
  
  @media(min-width: 915px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ShipmentLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  color: ${colors.blue};
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

function SmallTable({ item, t }) {
  const [loading, setLoading] = useState(false)

  function download({ document }) {
    if (loading) {
      return
    }

    setLoading(true)
    documentService.download({ id: document.id })
      .then(blob => downloadjs(window.atob(blob), `${document.fileName}.${document.dataType.toLowerCase()}`, 'application/pdf'))
      .catch(() => alert(t('core.no_documents')))
      .finally(() => setLoading(false))
  }

  return (
    <TableContainer>
      <TableBody>
        <TableRow header>
          {[t('core.issuer'), t('invoices.invoice_date'), t('core.due_date')].map((header, index) =>
            <TableHeader key={index} headers={3}>{header}</TableHeader>
          )}
        </TableRow>
        <TableRow>
          <TableData>
            {item.issuerName}
          </TableData>
          <TableData>
            {item.invoiceDate && formatDate(item.invoiceDate)}
          </TableData>
          <TableData>
            {item.dueDate && formatDate(item.dueDate)}
          </TableData>
        </TableRow>
        <TableRow header>
          {[t('core.shipment') + " ID", t('core.status'), t('core.amount')].map((header, index) =>
            <TableHeader key={index} headers={3}>{header}</TableHeader>
          )}
        </TableRow>
        <TableRow>
          <TableData>
            <ShipmentLink to={`/shipment/${item.shipmentId}`}>
              {item.shipmentId}
            </ShipmentLink>
          </TableData>
          <TableData>
            <InvoiceStatusWithName transaction={item} />
          </TableData>
          <TableData>
            {formatCurrency(item.invoiceAmount, item.currency)}
          </TableData>
        </TableRow>
        <TableRow>
          <td>
            <StyledDiv style={{ marginBottom: 16 }}>
              <Button
                color="blue"
                text={item.document ? t('core.download') : t('core.download_unavailable')}
                disabled={!item.document}
                loading={loading}
                onClick={() => download(item)}
              />
            </StyledDiv>
          </td>
        </TableRow>
      </TableBody>
    </TableContainer>
  )
}

function formatCurrency(amount, currency) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount)
}

export default withTranslation()(SmallTable);
