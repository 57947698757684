const INVOICE_STATUS_PAID = 'Paid';
const INVOICE_STATUS_DUE = 'Due';

function getInvoiceStatus(invoice) {
  if (invoice.fullyPaidDate) {
    return INVOICE_STATUS_PAID;
  }

  return INVOICE_STATUS_DUE;
}

export {
  INVOICE_STATUS_DUE,
  INVOICE_STATUS_PAID,
  getInvoiceStatus
}
