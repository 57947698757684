import React from 'react';
import {formatDate} from "../../services/core/dateHelper";
import {TableContainer, TableBody, TableRow, TableHeader, TableData, ShipmentLink} from "./DashboardStyle";
import styled, {css} from "styled-components";
import colors from "../../styles/colors";
import {documentService} from "../../services";
import InvoiceStatusWithName from "../core/InvoiceStatusWithName";
import {useTranslation} from "react-i18next";

const TransactionReference = styled.a`
  color: ${colors.blue};
  text-decoration: none;
  ${props => props.disabled && css`
    opacity: .7;
    pointer-events: none;
  `}
`;

function Invoices({ data }) {
  const { t } = useTranslation()
  const headers = [
    t('dashboard.invoice'),
    t('dashboard.invoice.due_date'),
    t('dashboard.invoice.status'),
    t('dashboard.invoice.shipment_id'),
  ];

  return (
    <TableContainer>
      <TableBody>
        <TableRow header>
          {headers.map((header, index) => (
            <TableHeader key={index} headers={headers.length}>
              {header}
            </TableHeader>
          ))}
        </TableRow>
        {(data && data.length > 0) && data.filter(v => v).map((item, index) => (
          <TableRow key={index}>
            <TableData>
              {item.transactionNum && (
                <TransactionReference
                  href={documentService.downloadUrl({ id: item.document?.id })}
                  disabled={!item.document}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.transactionNum}
                </TransactionReference>
              )}
            </TableData>
            <TableData>
              {item.dueDate && formatDate(item.dueDate)}
            </TableData>
            <TableData>
              <InvoiceStatusWithName transaction={item} />
            </TableData>
            <TableData>
              <ShipmentLink to={`/shipment/${item.shipmentId}`}>
                {item.shipmentId}
              </ShipmentLink>
            </TableData>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

export default Invoices;
