import React from 'react';
import {TableContainerMobile, TableBody, TableHeader, TableData } from './DashboardStyle'
import {withTranslation} from "react-i18next";

const BookingsMobile = (props) => {
  const { headers, item, t } = props;

  return (
    <TableContainerMobile>
      <TableBody>
        <tr>
          {headers
            .filter(header => !header.includes("ID"))
            .map((header, index) =>
              <TableHeader key={index} headers={headers.length - 1}>{header}</TableHeader>
            )}
        </tr>
        <tr>
          <TableData>{item.shipmentOrg?.consignorFullName}</TableData>
          <TableData>{t('bookings.pending')}</TableData>
        </tr>
      </TableBody>
    </TableContainerMobile>
  )
};

export default withTranslation()(BookingsMobile);