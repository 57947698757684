import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
    ${reset}
    
    body {
      background: rgb(248, 251, 255);
    }
    
    html, body, #___gatsby, #gatsby-focus-wrapper {
      height: 100%
    }
      
    .mapboxgl-ctrl-bottom-left {
      display: none;
    }
    
    .mapboxgl-ctrl-bottom-right {
      display: none;
    }
`;

export default GlobalStyle;