import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import NotificationsIcon from '~/resources/img/ui/notifications-icon.svg';
import LogoMobile from '~/resources/img/white-logo.svg';
import AvatarImg from "~/resources/img/icons/user-avatar.svg"
import colors from "~/styles/colors";
import { notificationService } from '~/services'
import { setNotifications, updateNotification } from '~/store/notification'
import {useDispatch, useSelector} from "react-redux";
import {navigate} from "gatsby";

const HeaderBackground = styled.div`
  height: 70px;
  width: 100%;
  background-color: ${colors.white};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 8;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  @media(max-width: 767px) {
    height: 90px;
    align-items: flex-end;
    background-color: ${colors.navyBlue};
  }
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 0;
  top: 0;
  height: 70px;

  @media(max-width: 767px) {
    height: 90px;
  }
`;

const Notifications = styled.img`
  width: 20px;
`;

const Avatar = styled.img`
  height: 30px;
  width: 30px;
`;

const Logo = styled.img`
  height: 49px;
  width: 139px;
  position: fixed;
  left: 0;
  padding-bottom: 20px;
  padding-left: 20px;

  @media(min-width: 768px) {
    padding-left: 20px;
    position: relative;
    padding-bottom: 0;
  }
`;

const NotificationContainer = styled.div`
  z-index: 10000;
  position: absolute;
  top: 65px;
  left: -125px;
  width: 250px;
  max-height: 260px;
  overflow-x: auto;
  background: #FFF;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.08);
`

const NotificationItem = styled.div`
  font-family: 'Ubuntu', sans-serif;
  border-bottom: 1px solid rgba(0,0,0,0.08);
  padding: 16px;
  cursor: pointer;
  
  &:hover {
    background: rgba(0,0,0,0.02);
  }
  
  &:last-child {
    border-bottom: none;
  }
`

const NotificationItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`

const NotificationItemUnReaded = styled.div`
  width: 6px;
  height: 6px;
  background: #006DC7;
  border-radius: 50%;
  margin-right: 8px;
`

const NotificationItemDescription = styled.div`
  font-size: 14px;
  color: rgba(0,0,0,0.7);
`

const MenuOption = styled.div`
  position: relative;
  height: 100%;
  width: 90px;
  border-left: 1px solid ${colors.lightBlue};
  align-items: center;
  justify-content: center;
  padding-top: 0;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  transition: .3s;
  
  &:hover {
    background: rgba(0,0,0,0.02);
  }
  
  & > ${NotificationItemUnReaded} {
    position: absolute;
    top: 24px;
    right: 18px;
    width: 8px;
    height: 8px;
  }

  @media(min-width: 768px){
    width: 70px;
    display: flex;
  }
`;

function AdminHeader({ isLoginMode }) {
  const dispatch = useDispatch()
  const notifications = useSelector(v => v.notification.notifications)
  const [isVisible, setVisible] = useState(true)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const hasUnReadedNotification = useMemo(
    () => notifications.filter(v => !v.readed).length > 0,
    [notifications],
  )

  useEffect(() => {
    notificationService.list()
      .then((data) => dispatch(setNotifications(data)))
      .catch(console.error)
  }, [])

  useEffect(() => {
    let prev = window.scrollY;
    function onScroll(e) {
      const window = e.currentTarget;
      let scrollTop = window.pageYOffset;
      if (window.innerWidth <= 767){
        if ((prev > window.scrollY || scrollTop < 90) && isVisible === false) {
          setVisible(true)
        } else if ((prev < window.scrollY && scrollTop > 90) &&  isVisible === true) {
          setVisible(false)
        }
      }
      prev = window.scrollY;
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [isVisible])

  async function notificationGo({ id }) {
    const notification = await notificationService.read({ id })
    dispatch(updateNotification(notification))
    return navigate(`/admin/${notification.resourceType}/${notification.resourceId}`)
  }

  return (
    <HeaderBackground>
      <Logo src={LogoMobile} alt='Logo'/>
      {!isLoginMode && (
        <MenuOptions>
          <MenuOption
            onMouseEnter={() => setNotificationVisible(true)}
            onMouseLeave={() => setNotificationVisible(false)}
          >
            <Notifications src={NotificationsIcon} alt="Notifications icon" />
            {hasUnReadedNotification && <NotificationItemUnReaded />}
            {notificationVisible && (
              <NotificationContainer>
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    onClick={() => notificationGo(notification)}
                  >
                    <NotificationItemHeader>
                      {!notification.readed && <NotificationItemUnReaded />}
                      <div>
                        {notification.name}
                      </div>
                    </NotificationItemHeader>
                    <NotificationItemDescription>
                      {notification.description}
                    </NotificationItemDescription>
                  </NotificationItem>
                ))}
              </NotificationContainer>
            )}
          </MenuOption>
          <MenuOption>
            <Avatar src={AvatarImg}/>
          </MenuOption>
        </MenuOptions>
      )}
    </HeaderBackground>
  )
}

AdminHeader.propTypes = {
  isLoginMode: PropTypes.bool,
}

export default AdminHeader
