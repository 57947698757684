import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import Button from '../core/Button';
import {FormInput, FormSelect} from '../core/Form'
import ErrorMessage from "../core/forms/ErrorMessage";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import {Link, navigate} from 'gatsby'
import {Form, Field} from "react-final-form";
import {FORM_ERROR} from "final-form";
import {userService} from '~/services'
import {withTranslation} from "react-i18next";

const CardTitle = styled.div`
  ${fonts.DMSansBold};
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.navyBlue};
  padding-bottom: 40px;
`;

const StyledForm = styled.form`
  @media (max-width: 650px) {
    width: calc(100% - 40px);
  }
`;

const FieldLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 16px;
  ${fonts.UbuntuRegular};
  color: ${colors.grey};
  padding-bottom: 5px;
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  
  @media(max-width: 926px) {
    flex-wrap: wrap;
  }
  
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Column = styled.div`
  margin-left: 12.5px;
  margin-right: 12.5px;
  width: calc(50% - 25px);
  
  @media(max-width: 926px) {
    width: calc(326px + 25px);
  }
  
  @media (max-width: 650px) {
    width: calc(100%);
    margin-left: 0;
    margin-right: 0;
  }
`;

const Information = styled.div`
  color: ${colors.grey};
  ${fonts.UbuntuRegular};
  font-size: 12px;
  line-height: 20px;
  padding-top: 30px;

  @media(max-width: 650px) {
    padding: 30px 20px;
    text-align: center;
  }
`;

const CheckBoxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fonts.Cabin};
  font-size: 12px;
  color: ${colors.navyBlue};
  line-height: 20px;
`;

const SpaceDiv = styled.div`
  padding-top: 29px;
`;

const RegisterButton = styled.div`
  display: flex;
  justify-content: center;
`;

const GreyLink = styled.div`
  display: block;
  margin-top: 24px;
  text-align: center;
  color: ${colors.grey};
  font-size: 12px;
  ${fonts.UbuntuRegular};
  text-decoration: underline;
  cursor: pointer;
`;

const requiredFields = [
  'companyName',
  'companyAddress',
  'companyCity',
  'companyPostcode',
  'companyCountryCode',
  'companyState',
];

function CompanyForm({t}) {
  const [values, setValues] = useState(null)

  useEffect(() => {
    userService.getMe()
      .then(user => setValues(user))
      .catch(console.error)
  }, []);

  function skip() {
    return userService.updateMe({ onBoardingFinish: true })
      .finally(() => navigate('/'))
  }

  function validate(values) {
    return requiredFields.reduce((errors, field) => {
      if (!values?.[field]) {
        errors[field] = t('core.required');
      }
      return errors
    }, {})
  }

  function handleSubmit(values) {
    return userService.updateMe(values)
      .then(() => navigate('/onboarding/business-department'))
      .catch((err) => {
        const error = err?.response?.data?.message ?? t('form.response_error');
        return { [FORM_ERROR]: error }
      });
  }

  return (
    <Form
      initialValues={values}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitError }) => (
        <>
          <CardTitle>{t('onboarding.company_information')}</CardTitle>
          <StyledForm onSubmit={handleSubmit}>
            {submitError && (
              <ErrorMessage css={css`margin: 0 0 24px; text-align: center`}>
                {submitError}
              </ErrorMessage>
            )}
            <Row>
              <Column>
                <FieldLabel htmlFor="companyName">{t('onboarding.name')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="companyName"
                  name="companyName"
                />
              </Column>
              <Column>
                <FieldLabel htmlFor="companyAddress">{t('onboarding.address')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="companyAddress"
                  name="companyAddress"
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <FieldLabel htmlFor="companyCity">{t('onboarding.city')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="companyCity"
                  name="companyCity"
                />
              </Column>
              <Column>
                <FieldLabel htmlFor="companyPostcode">{t('onboarding.postcode')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="companyPostcode"
                  name="companyPostcode"
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <FieldLabel htmlFor="companyCountryCode">{t('onboarding.country')}</FieldLabel>
                <Field component={FormSelect} id="companyCountryCode" name="companyCountryCode">
                  <option value="">{t('core.select')}</option>
                  <option value="GB">{t('core.united_kingdom')}</option>
                  <option value="US">{t('core.united_states')}</option>
                  <option value="CA">{t('core.canada')}</option>
                  <option value="FR">{t('core.france')}</option>
                  <option value="DE">{t('core.germany')}</option>
                  <option value="CN">{t('core.china')}</option>
                  <option value="BE">{t('core.belgium')}</option>
                </Field>
              </Column>
              <Column>
                <FieldLabel htmlFor="companyState">{t('onboarding.state')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="companyState"
                  name="companyState"
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <FieldLabel htmlFor="companyWebsite">{t('onboarding.website')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="companyWebsite"
                  name="companyWebsite"
                />
              </Column>
              <Column></Column>
            </Row>
            <SpaceDiv/>
            <RegisterButton>
              <Button
                text={t('core.continue')}
                color="blue"
                signin="true"
                type="submit"
              />
            </RegisterButton>
            <GreyLink onClick={skip}>
              {t('core.skip')}
            </GreyLink>
          </StyledForm>
        </>
      )}
    />
  )
}

export default withTranslation()(CompanyForm);