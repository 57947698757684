import axios from 'axios'

export const ENDPOINT = process.env.API_URL;

const client = axios.create({
  baseURL: ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
})

client.interceptors.request.use(res => {
  const jwt = localStorage.getItem('token')
  if (jwt) {
    res.headers["jwt"] = jwt
  }
  return res
})

export default client
