import React from 'react';
import styled from 'styled-components';
import SEO from '../components/core/Seo';
import GlobalStyle from '../styles/global';
import RegistrationForm from '../components/registration/RegistrationForm';
import colors from "../styles/colors";
import LogoMobile from "../resources/img/logo.svg";
import "../components/core/i18n";
import { withTranslation } from "react-i18next";

const Background = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(248, 251, 255);
  position: relative;
  box-sizing: border-box;
  padding-bottom: 40px;

  @media(max-width: 767px) {
    left: 0;
  }
`;

const Card = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  box-sizing: border-box;

  @media(max-width: 650px) {
    width: calc(100% - 40px) !important;
    padding: 30px 0 0;
  }
  
  @media(max-width: 926px) {
    width: calc(70%);
  }
`;

const Logo = styled.img`
  height: 49px;
  width: 139px;
  padding-bottom: 46px;
  padding-top: 34px;

  @media(min-width: 768px) {
    padding-bottom: 56px;
    padding-top: 55px;
  }
`;

function RegistrationPage(props, {location: {state: locationState} = {}} = {}) {

    const {t} = props;
    return (
        <Background>
            <GlobalStyle/>
            <SEO title={t("register.title")}/>
            <Logo src={LogoMobile} alt="Logo"/>
            <Card>
                <RegistrationForm email={locationState?.email}/>
            </Card>
        </Background>
    );

}

export default withTranslation()(RegistrationPage);
