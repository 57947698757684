import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import SEO from '~/components/core/Seo';
import PrivateRoute from "~/router/PrivateRoute"
import AdminLayout from "~/components/admin/AdminLayout";
import {shipmentService} from "../../services";
import ShipmentCard from "../../components/shipment/ShipmentCard";
import InformationCard from "../../components/shipment/InformationCard";

const Background = styled.div`
  width: calc(100% - 225px);
  left: 225px;
  top: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 35px 80px;
  box-sizing: border-box;
  
  @media(max-width: 767px) {
    left: 0;
    top: 105px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; 
    padding-bottom: 105px;      
  }
`;

function Shipment({ location, '*': reference }) {
  const path = location?.pathname;
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [documents, setDocuments] = useState([])
  const [invoices, setInvoices] = useState([])
  const [activities, setActivities] = useState([])
  const shipment = useMemo(() => {
    if (!data?.shipment) {
      return {}
    }
    return {
      ...data.shipment,
      relatedShipments: data.relatedShipments,
      documents,
      invoices,
      activities,
    }
  }, [data, documents, invoices, activities])

  useEffect(() => {
    shipmentService.get({ reference })
      .then(data => {
        setData(data)

        shipmentService.getDocuments({ reference })
          .then(data => setDocuments(formatDocuments(data)))
        shipmentService.getTransactions({ reference })
          .then(data => setInvoices(data))
        shipmentService.getActivities({ reference })
          .then(data => setActivities(data))
      })
      .finally(() => setLoading(false))
  }, [])

  function formatDocuments(raw) {
    if (!raw) {
      return []
    }
    return [...raw]
      .sort(({ saveDate: a }, { saveDate: b }) => new Date(b).getTime() - new Date(a).getTime())
      .filter((file, index, arr) => {
        const alreadyExists = arr.slice(0, index).some(c =>
          c.docType === file.docType &&
          c.dataType === file.dataType &&
          c.fileName.trim() === file.fileName.trim()
        )
        return !alreadyExists
      })
      .map((file, _, arr) => {
        const hasSameDescription = arr.some(c => c.id !== file.id && c.description.trim() === file.description.trim())
        return {
          ...file,
          description: hasSameDescription ? `${file.description} (${file.fileName}.${file.dataType.toLowerCase()})` : file.description,
        }
      })
  }

  function createActivity(values) {
    const payload = {
      reference,
      ...values,
    }
    return shipmentService.createActivity(payload)
      .then((activity) => setActivities([
        activity,
        ...activities,
      ]))
      .catch(console.error)
  }

  const markers = data?.map?.markers ?? []

  return (
    <AdminLayout loading={loading}>
      <SEO title={`Shipment ${reference}`} path={path}/>
      {shipment && (
        <Background>
          <ShipmentCard
            data={shipment}
          />
          <InformationCard
            shipment={shipment}
            markers={markers}
            createActivity={createActivity}
          />
        </Background>
      )}
    </AdminLayout>
  )
}

function shipmentRoute(props) {
  return <PrivateRoute component={Shipment} {...props}/>
}

export default shipmentRoute;
