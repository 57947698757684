import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import Button from '../core/Button';
import {FormCheckbox, FormInput, FormSelect} from '../core/Form'
import ErrorMessage from "../core/forms/ErrorMessage";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import {Form, Field} from "react-final-form";
import {FORM_ERROR} from "final-form";
import {authService} from '~/services'
import SuccessMessage from "../core/forms/SuccessMessage";
import {Trans, withTranslation} from "react-i18next";

const CardTitle = styled.div`
  ${fonts.DMSansBold};
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.navyBlue};
  padding-bottom: 40px;
`;

const StyledForm = styled.form`
  @media (max-width: 650px) {
    width: calc(100% - 40px);
  }
`;

const FieldLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 16px;
  ${fonts.UbuntuRegular};
  color: ${colors.grey};
  padding-bottom: 5px;
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  
  @media(max-width: 926px) {
    flex-wrap: wrap;
  }
  
  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

const Column = styled.div`
  margin-left: 12.5px;
  margin-right: 12.5px;
  width: calc(50% - 25px);
  
  @media(max-width: 926px) {
    width: calc(326px + 25px);
  }
  
  @media (max-width: 650px) {
    width: calc(100%);
    margin-left: 0;
    margin-right: 0;
  }
`;

const Information = styled.div`
  color: ${colors.grey};
  ${fonts.UbuntuRegular};
  font-size: 12px;
  line-height: 20px;
  padding-top: 30px;

  @media(max-width: 650px) {
    padding: 30px 20px;
    text-align: center;
  }
`;

const CheckBoxContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fonts.Cabin};
  font-size: 12px;
  color: ${colors.navyBlue};
  line-height: 20px;
`;

const SpaceDiv = styled.div`
  padding-top: 29px;
`;

const RegisterButton = styled.div`
  display: flex;
  justify-content: center;
`;

const requiredFields = [
  'email',
  'password',
  'confirmPassword',
  'firstName',
  'lastName',
  'phoneNumber',
  'companyName',
  'companyCountryCode',
  'registrationSource'
];

function RegistrationForm(props, { values }) {
  const [isFormSubmitted, setFormSubmitted] = useState(false)

  const { t} = props;

  function validate(values) {
    const errors = {};

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t('form.password_does_not_match');
    }

    if (values.password?.length < 5) {
      errors.password = t('form.password_minimum_length');
    }

    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = t('core.required');
      }
    });

    if (!values.agreeTerms) {
      errors.agreeTerms = t('register.register.agree_terms');
    }

    return errors
  }

  function handleSubmit(values) {
    return authService.register(values)
      .then(() => setFormSubmitted(true))
      .catch((err) => {
        const error = err.isAxiosError && err?.response?.status === 400
          ? t('register.verify_details') : t('form.response_error')
        return { [FORM_ERROR]: error }
      });
  }

  return isFormSubmitted ? (
    <>
      <CardTitle>{t('register.register_success')}</CardTitle>
      <SuccessMessage>{t('register.success_message')}</SuccessMessage>
      <Button text={t('core.login')} color="blue" signin="true" link="/login"/>
    </>
  ) : (
    <Form
      initialValues={values}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitError }) => (
        <>
          <CardTitle>{t('core.create_account')}</CardTitle>
          <StyledForm onSubmit={handleSubmit}>
            {submitError && (
              <ErrorMessage css={css`margin: 0 0 24px; text-align: center`}>
                {submitError}
              </ErrorMessage>
            )}
            <Row>
              <Column>
                <FieldLabel htmlFor="email">{t('register.email_address')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="email"
                  name="email"
                  placeholder="john.doe@myfreightonline.com"
                />
              </Column>
              <Column>
                <FieldLabel htmlFor="companyName">{t('register.company_name')}</FieldLabel>
                <Field
                  component={FormInput}
                  id="companyName"
                  name="companyName"
                  placeholder="MFO"
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <FieldLabel htmlFor="password">{t('register.password')}</FieldLabel>
                <Field
                  component={FormInput}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="**********"
                />
              </Column>
              <Column>
                <FieldLabel htmlFor="confirmPassword">{t('register.confirm_password')}</FieldLabel>
                <Field
                  component={FormInput}
                  type="password"
                  placeholder="**********"
                  id="confirmPassword"
                  name="confirmPassword"
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <FieldLabel htmlFor="firstName">{t('register.first_name')}</FieldLabel>
                <Field
                  component={FormInput}
                  placeholder="John"
                  id="firstName"
                  name="firstName"
                />
              </Column>
              <Column>
                <FieldLabel htmlFor="lastName">{t('register.last_name')}</FieldLabel>
                <Field
                  component={FormInput}
                  placeholder="Doe"
                  id="lastName"
                  name="lastName"
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <FieldLabel htmlFor="phone">{t('register.phone_number')}</FieldLabel>
                <Field
                  component={FormInput}
                  placeholder="075123456789"
                  id="phoneNumber"
                  name="phoneNumber"
                />
              </Column>
              <Column>
                <FieldLabel htmlFor="companyCountryCode">{t('register.country')}</FieldLabel>
                <Field component={FormSelect} id="companyCountryCode" name="companyCountryCode">
                  <option value="">{t('core.select')}</option>
                  <option value="GB">{t('core.united_kingdom')}</option>
                  <option value="US">{t('core.united_states')}</option>
                  <option value="CA">{t('core.canada')}</option>
                  <option value="FR">{t('core.france')}</option>
                  <option value="DE">{t('core.germany')}</option>
                  <option value="CN">{t('core.china')}</option>
                  <option value="BE">{t('core.belgium')}</option>
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <FieldLabel htmlFor="registrationSource">{t('register.registration_source')}</FieldLabel>
                <Field component={FormSelect} id="registrationSource" name="registrationSource">
                  <option value="">{t('core.select')}</option>
                  <option value="search">{t('register.registration_source.search_engine')}</option>
                  <option value="linkedin">{t('register.registration_source.linkedin')}</option>
                  <option value="email">{t('register.registration_source.email')}</option>
                  <option value="recommendation">{t('register.registration_source.recommendation')}</option>
                  <option value="mfo">{t('register.registration_source.mfo_team')}</option>
                </Field>
              </Column>
              <Column>
                <CheckBoxContainer>
                  <Field component={FormCheckbox} name="agreeTerms">
                    <Trans i18nKey={'register.tos'}>
                      I agree
                      <a href={"https://myfreightonline.com/page/terms-and-conditions"} target={"_blank"}
                         rel="noopener noreferrer">Terms of Service</a>
                      and
                      <a href={"https://myfreightonline.com/page/privacy-policy"} target={"_blank"}
                         rel="noopener noreferrer">Privacy Policy</a>
                    </Trans>

                  </Field>
                </CheckBoxContainer>
              </Column>
            </Row>
            <SpaceDiv/>
            <RegisterButton>
              <Button text={t('register.sign_up')}
                      color="blue"
                      signin="true"
                      type="submit"/>
            </RegisterButton>
          </StyledForm>
          <Information>{t('register.information')}</Information>
        </>
      )}
    />
  )
}

export default withTranslation()(RegistrationForm);

