import api from "./api";

export async function list() {
  const res = await api.get('/organisations');
  return res.data
}

export async function create(payload) {
  const res = await api.post('/organisations', payload);
  return res.data
}

export async function update({ id, ...payload }) {
  const res = await api.patch(`/organisations/${id}`, payload);
  return res.data
}

export async function destroy({ id }) {
  const res = await api.delete(`/organisations/${id}`);
  return res.data
}
