import React, {useEffect, useRef} from 'react';
import {MapContainer} from "./MapStyle"
import {createMap, addMarkers, setMapBounds} from "../../services/map";

const style = {
  position: "relative",
  display: "block",
  height: "100%",
  width: "100%"
};

function MapBox({ markers = [] }) {
  const mapRef = useRef(null)

  useEffect(() => {
    if (mapRef.current) {
      const map = createMap(mapRef.current);

      map.on('load', (event) => {
        map.resize();

        addMarkers(markers, map);
        setMapBounds(markers, map, 4);
      });

      return () => {
        map.remove()
      }
    }
  }, [mapRef, markers])

  return (
    <MapContainer>
      <div style={style} ref={mapRef}/>
    </MapContainer>
  );
}


export default MapBox;
