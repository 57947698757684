import api from "./api";

export async function list({ role = 'ROLE_USER' } = {}) {
  const res = await api.get('/users', {
    params: {
      role,
    },
  });
  return res.data
}

export async function create(payload) {
  const res = await api.post('/users', payload);
  return res.data
}

export async function update({ id, ...payload }) {
  const res = await api.patch(`/users/${id}`, payload);
  return res.data
}

export async function destroy({ id }) {
  const res = await api.delete(`/users/${id}`)
  return res.data
}

export async function getMe() {
  const res = await api.get('/me');
  return res.data
}

export async function updateMe(payload) {
  const res = await api.patch('/me', payload);
  return res.data
}
