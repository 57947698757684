import React, {useMemo, useState} from "react";
import styled from "styled-components";
import AccordionOpen from '../../resources/img/ui/accordion-open.svg';
import AccordionClose from '../../resources/img/ui/accordion-close.svg';
import BasicInfoTable from './BasicInfoTable';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import { Icon, DataText, Reference} from "./BookingsStyle";
import {useTranslation} from "react-i18next";
import useCWTransportModes from "../../hooks/useCWTransportModes";
import FaresTable from "../quotes/FaresTable";
import breakpoints from "../../styles/breakpoints";

const CardBackground = styled.div`
    margin-left: 20px;
    margin-right: 20px;
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: ${props => props.open ? '1px solid' + colors.lightBlue : 'none'};
`;

const AccordionImgStyled = styled.img`
    height: auto;
    width: 10px;
    cursor: pointer;
    margin-left: 10px;
`;

const TableData = styled.td`
    ${fonts.UbuntuRegular};
    font-size: ${props => props.booking ? '12px' : '14px'};
    line-height: ${props => props.booking ? '18px' : '20px'};
    color: ${colors.navyBlue};
    vertical-align: top;
    padding-top: ${props => props.booking ? '7px' : '20px'};
    padding-bottom: 20px;
    box-sizing: border-box;
`;

const TableBackgroundDiv = styled.div`
    margin-left: -20px;
    margin-right: -20px;
`;

const TableBackground = styled.table`
    background: ${colors.backgroundBlue};
    width: 100%;
    tbody, tr {
        width: 100%;
    }
`;

const CardTitleRow = styled.div`
    height: 60px;
    width: 100%;
    border-bottom: ${props => props.mobile ? 'none' : '1px solid' + colors.lightBlue};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.mobile ? 'space-between' : 'flex-start'};
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    @media(max-width: 1100px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Card = styled.div`
    padding-left: ${props => props.mobile ? '0' : '20px'};
    padding-right: ${props => props.mobile ? '0' : '20px'};
    box-sizing: border-box;
    display: ${props => props.active === "false" && 'none'};
    padding-bottom: 20px;
`;

const CardLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CardLink = styled.button`
    margin-left: 17px;
    ${fonts.DMSansRegular};
    font-size: 14px;
    line-height: 20px;
    height: 60px;
    color: ${props => props.active ? colors.blue : colors.grey};
    border-bottom: ${props => props.active ? '3px solid #006DC7' : '3px solid transparent'};
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
    background: transparent;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border-bottom: 3px solid ${colors.blue};
        outline: none;
        box-shadow: none;
    }
    @media(max-width: 1100px) {
        :first-child {
            margin-left: 0;
        }
    }
`;

const CardTable = styled.table`
   width: 100%;
`;

const LightGreyTitle = styled.div`
  ${fonts.DMSansRegular};
  color: ${colors.grey};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 28px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 14px;
    line-height: 20px;
    max-width: 940px;
    margin-top: 24px;
  }
`;

const NavyBlueText = styled.div`
  ${fonts.DMSansRegular};
  color: ${colors.navyBlue};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 14px;
    line-height: 20px;
    max-width: 940px;
  }
`;

const TermsContent = styled.div`
  padding: 8px 20px 25px;
  
  @media(min-width: ${breakpoints.md}){
    padding: 17px 20px 28px;
  }
`;

const AccordionCell = styled.td`
    vertical-align: middle;
    text-align: right;
`;

const BookingStatus = styled.div`
  ${fonts.UbuntuRegular};
  margin-left: auto;
  background: #070D49;
  padding: 6px 14px;
  color: #fff;
  font-size: 14px;
  border-radius: 25px;
  font-weight: 300;
  ${props => props.status === 'confirmed' && 'background: #54b4a9;'}
  ${props => props.status === 'canceled' && 'background: #e86c65;'}
`

function BookingCard({ item, last }) {
  const { t } = useTranslation()
  const [isCollapsed, setCollapsed] = useState(true)
  const [view, setView] = useState('details')
  const transportMode = useCWTransportModes()
  const orderReferences = useMemo(
    () => item.docsAndCartage?.orderItems
      ?.sort(({ sequence: a }, { sequence: b }) => parseInt(a, 10) > parseInt(b, 10))
      .map(v => v.orderReference)
      .join(', ')
  )

  function formatBookingStatus(status) {
    switch (status) {
      case 'pending':
        return 'Pending'
      case 'confirmed':
        return 'Confirmed'
      case 'canceled':
        return 'Canceled'
    }
  }

  return (
    <>
      <CardBackground open={!isCollapsed} last={last}>
        <CardHeader onClick={() => setCollapsed(!isCollapsed)} open={!isCollapsed}>
          <CardTable>
            <tbody>
              <tr>
                <TableData>
                  {(item.transportMode && transportMode[item.transportMode]) &&
                  <>
                    <Icon src={transportMode[item.transportMode].image}
                          alt={`${t('core.transport_mode')}: ${transportMode[item.transportMode].label}`}/>
                  </>}
                  <DataText>
                    {item.shipmentOrg?.consignorFullName}
                    <Reference>
                      {item.reference && item.reference}
                    </Reference>
                    {orderReferences && `PO ${orderReferences}`}
                  </DataText>
                </TableData>
                <AccordionCell>
                  <div style={{ display: 'flex' }}>
                    <BookingStatus status={item.bookingStatus}>
                      {formatBookingStatus(item.bookingStatus)}
                    </BookingStatus>
                    <AccordionImgStyled src={!isCollapsed ? AccordionClose : AccordionOpen}
                                        alt={t('core.dropdown')}/>
                  </div>
                </AccordionCell>
              </tr>
            </tbody>
          </CardTable>
        </CardHeader>
        {!isCollapsed && (
          <TableBackgroundDiv>
            <TableBackground>
              <tbody>
                <tr>
                  <td>
                    <CardTitleRow tall>
                      <CardLinks>
                        <CardLink onClick={() => setView('details')} active={view === 'details'}>
                          Basic Info
                        </CardLink>
                        {item.quote && (
                          <CardLink onClick={() => setView('fares')} active={view === 'fares'}>
                            Quote
                          </CardLink>
                        )}
                      </CardLinks>
                    </CardTitleRow>
                    <Card active={`${view === 'details'}`}>
                      <BasicInfoTable item={item} />
                    </Card>
                    {item.quote && (
                      <Card active={`${view === 'fares'}`} mobile>
                        <FaresTable fares={item.quote.result.fares} />
                        <TermsContent>
                          {item.quote.result.service && (
                            <>
                              <LightGreyTitle>{t('core.service')}</LightGreyTitle>
                              <NavyBlueText>
                                {item.quote.result.service}
                              </NavyBlueText>
                            </>
                          )}
                        </TermsContent>
                      </Card>
                    )}
                  </td>
                </tr>
              </tbody>
            </TableBackground>
          </TableBackgroundDiv>
        )}
      </CardBackground>
      {/*<PopUp handlePopUp={this.handlePopUp} open={this.state.isPopUpOpen} item={item}/>*/}
    </>
  )
}

export default BookingCard
