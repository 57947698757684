import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import NotificationsIcon from '../../resources/img/ui/notifications-icon.svg';
import LogoMobile from '../../resources/img/logo.svg';
import {logoutAction} from "../../store/actions/user";
import {useDispatch, useSelector} from "react-redux";
import { notificationService } from '~/services'
import { setNotifications, updateNotification } from '~/store/notification'
import colors from "../../styles/colors";
import { navigate, Link } from "gatsby";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import {userService} from '~/services'

const HeaderBackground = styled.div`
  height: ${props => props.isVisible ? '90px' : '0px'};
  width: 100%;
  background-color: ${colors.white};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 9999;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  transition: .3s;

  @media(min-width: ${breakpoints.sm}){
    height: 70px;
    align-items: center;
    padding-left: 225px;
    justify-content: flex-start;
  }
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: row;
  height: ${props => props.isVisible ? '90px' : '0px'};
  transition: .3s;

  @media(min-width: ${breakpoints.sm}) {
    height: 70px;
    position: fixed;
    right: 0;
    top: 0;
  }
`;

const Logo = styled.img`
  height: 49px;
  width: 139px;
  padding-bottom: 20px;
  padding-left: 20px;

  @media(min-width: ${breakpoints.sm}) {
    padding-left: 20px;
    position: relative;
    padding-bottom: 0;
    display: none;
  }
`;

const LogoutButton = styled.span`
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Ubuntu', sans-serif;
  color: ${colors.grey};

  &:hover {
    text-decoration: underline;
  }
`;

const Notifications = styled.img`
  position: relative;
  width: 20px;
`;

const NotificationContainer = styled.div`
  z-index: 10000;
  position: absolute;
  top: 65px;
  left: -125px;
  width: 250px;
  max-height: 260px;
  overflow-x: auto;
  background: #FFF;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.08);
`

const NotificationItem = styled.div`
  font-family: 'Ubuntu', sans-serif;
  border-bottom: 1px solid rgba(0,0,0,0.08);
  padding: 16px;
  cursor: pointer;
  
  &:hover {
    background: rgba(0,0,0,0.02);
  }
  
  &:last-child {
    border-bottom: none;
  }
`

const NotificationItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`

const NotificationItemUnReaded = styled.div`
  width: 6px;
  height: 6px;
  background: #006DC7;
  border-radius: 50%;
  margin-right: 8px;
`

const NotificationItemDescription = styled.div`
  font-size: 14px;
  color: rgba(0,0,0,0.7);
`

const MenuOption = styled.div`
  position: relative;
  height: 100%;
  width: 90px;
  border-left: 1px solid ${colors.lightBlue};
  align-items: center;
  justify-content: center;
  padding-top: 0;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  transition: .3s;
  
  &:hover {
    background: rgba(0,0,0,0.02);
  }
  
  & > ${NotificationItemUnReaded} {
    position: absolute;
    top: 24px;
    right: 18px;
    width: 8px;
    height: 8px;
  }

  @media(min-width: ${breakpoints.sm}){
    width: 70px;
    display: flex;
  }
`;

const UserName = styled.div`
  ${fonts.UbuntuRegular};
  font-size: 12px;
  padding-left: 20px;
  color: ${colors.grey};
  padding-right: ${props => !props.left && "90px"};
  position: absolute;
  bottom: 8px;
  right: 98px;

  @media(min-width: ${breakpoints.sm}){
    font-size: 14px;
    position: relative;
    bottom: auto;
    right: auto;
  }
`

function Header({ login }) {
  const dispatch = useDispatch()
  const notifications = useSelector(v => v.notification.notifications)
  const [isVisible, setVisible] = useState(true)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const [user, setUser] = useState('')
  const hasUnReadedNotification = useMemo(
    () => notifications.filter(v => !v.readed).length > 0,
    [notifications],
    )

  useEffect(() => {
    notificationService.list()
      .then((data) => dispatch(setNotifications(data)))
      .catch(console.error)
  }, [])

  useEffect(() => {
    userService.getMe().then((user) => {
      const userName = `${user.companyName}`
      setUser(userName)})
  },[])

  useEffect(() => {
    let prev = window.scrollY;
    function onScroll(e) {
      const window = e.currentTarget;
      let scrollTop = window.pageYOffset;
      if (window.innerWidth <= 767){
        if ((prev > window.scrollY || scrollTop < 90) && isVisible === false) {
          setVisible(true)
        } else if ((prev < window.scrollY && scrollTop > 90) &&  isVisible === true) {
          setVisible(false)
        }
      }
      prev = window.scrollY;
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [isVisible])

  async function notificationGo({ id }) {
    const notification = await notificationService.read({ id })
    dispatch(updateNotification(notification))
    return navigate(`/${notification.resourceType}/${notification.resourceId}`)
  }
  
  return (
    <>
      <HeaderBackground isVisible={isVisible}>
        <Link to="/">
          <Logo src={LogoMobile} alt="Logo" />
        </Link>
        <UserName left>{user}</UserName>
        {login !== 'true' && (
          <MenuOptions isVisible={isVisible}>
            <MenuOption
              onMouseEnter={() => setNotificationVisible(true)}
              onMouseLeave={() => setNotificationVisible(false)}
            >
              <Notifications src={NotificationsIcon} alt="Notifications icon" />
              {hasUnReadedNotification && <NotificationItemUnReaded />}
              {notificationVisible && (
                <NotificationContainer>
                  {notifications.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      onClick={() => notificationGo(notification)}
                    >
                      <NotificationItemHeader>
                        {!notification.readed && <NotificationItemUnReaded />}
                        <div>
                          {notification.name}
                        </div>
                      </NotificationItemHeader>
                      <NotificationItemDescription>
                        {notification.description}
                      </NotificationItemDescription>
                    </NotificationItem>
                  ))}
                </NotificationContainer>
              )}
            </MenuOption>
            <MenuOption isVisible={isVisible}>
              <LogoutButton onClick={() => dispatch(logoutAction())}>
                Log out
              </LogoutButton>
            </MenuOption>
          </MenuOptions>
        )}
      </HeaderBackground>
    </>
  )
}

export default Header

