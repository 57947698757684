import api from './api'

export async function login(payload) {
  const res = await api.post('/login', payload);
  localStorage.setItem('token', res.data.token)
  return res.data
}

export async function register(payload) {
  const res = await api.post('/register', payload)
  return res.data
}

export async function recoverPassword(payload) {
  const res = await api.post('/recover-password', payload)
  return res.data
}

export async function resetPassword(payload) {
  const res = await api.post('/reset-password', payload)
  return res.data
}
