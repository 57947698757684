import {
  SHIPMENT_STATUS_IN_TRANSIT,
  SHIPMENT_STATUS_ARRIVED,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_AWAITING_DEPARTURE,
  SHIPMENT_STATUS_PICKUP,
} from '../../services/shipments'

function ShipmentStatusName({status}) {
  switch (status) {
    case SHIPMENT_STATUS_PICKUP:
    case SHIPMENT_STATUS_IN_TRANSIT:
      return 'In transit'
    case SHIPMENT_STATUS_ARRIVED:
      return 'Arrived'
    case SHIPMENT_STATUS_DELIVERED:
      return 'Delivered'
    case SHIPMENT_STATUS_AWAITING_DEPARTURE:
      return 'Awaiting departure'
  }
  return 'Unknown'
}

export default ShipmentStatusName
