import React from 'react';
import styled from 'styled-components';
import Button from '../core/Button';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import { withTranslation } from "react-i18next";

const CardBackground = styled.div`
    background: ${colors.white};
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
`;

const CardTitleRow = styled.div`
    height: 60px;
    width: 100%;
    border-bottom: 1px solid ${colors.lightBlue};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
`;

const CardTitle = styled.div`
    ${fonts.DMSansBold};
    font-size: 18px;
    line-height: 24px;
    color: ${colors.navyBlue};
    
    span {
      display: none;
      ${fonts.DMSansRegular};
      font-size: 12px;
      color: ${colors.grey};
      margin-left: 8px;
      
      @media(min-width: ${breakpoints.md}){
        display: inline-block;
      }
    }
`;

const CardContent = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
`;

function Card({title, link, children, subTitle, t}) {
  return (
    <CardBackground>
      <CardTitleRow>
        <CardTitle>{title} <span>{subTitle}</span></CardTitle>
        {link && (
          <Button
            color="blue"
            text={t('core.view_all')}
            link={link}
          />
        )}
      </CardTitleRow>
      <CardContent>
        {children}
      </CardContent>
    </CardBackground>
  )
}

export default withTranslation()(Card);