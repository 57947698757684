import api from "./api";

export async function list({ page = 1, perPage }) {
  const res = await api.get('/transactions', {
    params: {
      page,
      perPage,
    }
  })
  return res.data
}
