import React from 'react';
import {formatDate} from "../../services/core/dateHelper";
import {getProgress} from "../../services/shipments";
import {TableContainer, TableBody, TableRow, TableHeader, TableData, Icon, ShipmentLink, ProgressBar, Progress} from "./DashboardStyle";
import transportMode from "../core/transportMode";
import ShipmentStatusName from "../core/ShipmentStatusName";
import {withTranslation} from "react-i18next";

const Shipments = (props) => {
  const {headers, data, t} = props;

  if (data) {
    data.map((item) => {
      if (!item) {
        return;
      }

      if (item.estimatedArrival || item.estimatedDeparture || item.shippedOnBoard) {
        item.progress = getProgress(item);
      }
    });
  }

  return (
    <TableContainer>
      <TableBody>
        <TableRow header>
          {headers.map((header, index) =>
            <TableHeader key={index} headers={headers.length}>{header}</TableHeader>
          )}
        </TableRow>
        {(data && data.length > 0) && data.map((item, index) =>
          item ?
          <TableRow key={index}>
            <TableData id="true">
              {(item.transportMode && transportMode[item.transportMode]) &&
              <Icon src={transportMode[item.transportMode].image} alt={`${t('core.transport_mode')}: ${item.transportMode}`}/>}
              <ShipmentLink to={`/shipment/${item.reference}`}>{item.reference && item.reference}</ShipmentLink>
              {item.progress &&
              <ProgressBar>
                <Progress style={{width: `${item.progress}`}}/>
              </ProgressBar>}
            </TableData>
            {(item.origin || item.estimatedDeparture || item.shippedOnBoardDate) &&
            <TableData>
              {item.origin && item.origin} <br/>
              {item.shippedOnBoardDate ? formatDate(item.shippedOnBoardDate) : (item.estimatedDeparture && formatDate(item.estimatedDeparture))}
            </TableData>}
            {(item.destination || item.estimatedArrival) &&
            <TableData>
              {item.destination && item.destination} <br/>
              {item.estimatedArrival && formatDate(item.estimatedArrival)}
            </TableData>}
            {item.status && (
              <TableData>
                <ShipmentStatusName status={item.status}/>
              </TableData>
            )}
          </TableRow>
            : null
        )}
      </TableBody>
    </TableContainer>
  )
};

export default withTranslation()(Shipments);