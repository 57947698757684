import React from "react";
import styled from "styled-components";
import SearchIcon from "../../resources/img/ui/search.svg";

const Search = styled.input`
  background: #F4F5F6 url(${SearchIcon}) no-repeat;
  height: 35px;
  min-width: 250px;
  width: 100%;
  border-radius: 50px;
  border: 0;
  background-position-x: 10px;
  background-position-y: center;
  padding-left: 35px;
  color: #939BA6;
  font-size: 14px;
  font-family: 'Ubuntu', sans-serif;
  margin-top: 15px;
  margin-bottom: 16px;

  ::placeholder {
    color: #939BA6;
    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
  }
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
  
  @media(max-width: 1101px) {
    box-sizing: border-box;
  }
  
  @media(min-width: 767px) {
    margin-top: 0;
    margin-bottom: 0;
    width: 200px;
  }
  
  @media(min-width: 900px){
    margin-top: 0;
    margin-bottom: 0;
    width: 330px;
  }
`;

function SearchBar(props) {
  return (
    <Search type="text" {...props}/>
  )
}

export default SearchBar;
