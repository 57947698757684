import React, {useMemo} from 'react';
import styled from 'styled-components';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import {formatNumber} from "../../services/shipments";
import {useTranslation, withTranslation} from "react-i18next";

const TableRow = styled.tr`
    vertical-align: middle;
    @media(max-width: 1100px) {
            display: ${props => props.desktop && 'none'};
    }
    @media(min-width: 1101px) {
        display: ${props => props.mobile && 'none'};
    }
    td table {
        width: 100%;
    }
`;

const TableHeader = styled.th`
    text-align: left;
    color: ${colors.grey};
    font-size: 14px;
    line-height: 20px;
    ${fonts.DMSansRegular};
    vertical-align: bottom;
    box-sizing: border-box;
    //width: 20%;
    padding-left: 20px;
    padding-bottom: 4px;
    @media(max-width: 1100px) {
        font-size: 12px;
        line-height: 18px;
        vertical-align: top;
        //width: 33%;
        padding: 20px 5px 10px 0;
    }
`;

const TableData = styled.td`
    ${fonts.UbuntuRegular};
    font-size: 14px;
    line-height: 20px;
    color: ${colors.navyBlue};
    vertical-align: top;
    box-sizing: border-box;
    padding-left: 20px;
    @media(max-width: 1100px) {
        font-size: 12px;
        line-height: 18px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
    }
`;

const StyledTable = styled.table`
    width: 100%;
    min-height: 110px;
  margin-top: 16px;
  height: auto;
`;

const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

function CWAddress({ address }) {
  const override = address.addressOverride
  const state = override ? address.state : address.organisationAddress.state
  const countryCode = override ? address.countryCode : address.organisationAddress.countryCode
  return (
    <div>
      <div>{override ? address.companyName : address.organisationAddress.organisation.fullName}</div>
      <div>{override ? address.address1 : address.organisationAddress.address1}</div>
      <div>{override ? address.address2 : address.organisationAddress.address2}</div>
      <div>
        {override ? address.city : address.organisationAddress.city}{' '}
        {override ? address.postcode : address.organisationAddress.postcode}{state ? ', ' : ''}
        {state}{countryCode ? ', ' : ''}
        {countryCode ? countryCode : ''}
      </div>
    </div>
  )
}

function DetailsTable({ item }) {
  const { t } = useTranslation()
  const consigneeAddress = useMemo(() => item.docAddresses?.find(v => v.addressType === 'CED'), [item])
  const pickupAddress = useMemo(() => item.docAddresses?.find(v => v.addressType === 'CRG'), [item])
  const deliveryAddress = useMemo(() => item.docAddresses?.find(v => v.addressType === 'CEG'), [item])
  const headers = useMemo(() => {
    let current = ['Consignee Address', 'Good description', 'Incoterm'];
    if (pickupAddress) {
      current = [...current, 'Pickup Address']
    }
    if (deliveryAddress) {
      current = [...current, 'Delivery Address']
    }
    return current
  }, [pickupAddress, deliveryAddress])
  const containersCount = item.packLines?.length ?? 0;

  return (
    <StyledTable>
      <tbody>
      <TableRow header desktop>
        {headers.map((header, index) =>
          <TableHeader key={index}>
            {header}
          </TableHeader>
        )}
      </TableRow>
      <TableRow desktop>
        <TableData>
          {consigneeAddress
            ? <CWAddress address={consigneeAddress} />
            : <span style={{ fontStyle: 'italic' }}>Unknown</span>}
        </TableData>
        <TableData>
          {item.goodsDescription || <span style={{ fontStyle: 'italic' }}>Unknown</span>}
        </TableData>
        <TableData>
          {item.incoterm}
        </TableData>
        {pickupAddress && (
          <TableData>
            <CWAddress address={pickupAddress} />
          </TableData>
        )}
        {deliveryAddress && (
          <TableData>
            <CWAddress address={deliveryAddress} />
          </TableData>
        )}
      </TableRow>
      <TableRow mobile>
        <MobileRow>
          <MobileCol>
            <TableHeader>Consignee Address</TableHeader>
            <TableData>
              {consigneeAddress
                ? <CWAddress address={consigneeAddress} />
                : <span style={{ fontStyle: 'italic' }}>Unknown</span>}
            </TableData>
          </MobileCol>
          <MobileCol>
            <TableHeader>Good description</TableHeader>
            <TableData>
              {item.goodsDescription || <span style={{ fontStyle: 'italic' }}>Unknown</span>}
            </TableData>
          </MobileCol>
          <MobileCol>
            <TableHeader>Incoterm</TableHeader>
            <TableData>
              {item.incoterm}
            </TableData>
          </MobileCol>
          <MobileCol>
            <TableHeader>Pickup Address</TableHeader>
            <TableData>
              {pickupAddress
                ? <CWAddress address={pickupAddress}/>
                :<span style={{ fontStyle: 'italic' }}>Unavailable</span>}
            </TableData>
          </MobileCol>
          <MobileCol>
            <TableHeader>Delivery Address</TableHeader>
            <TableData>
              {deliveryAddress
                ? <CWAddress address={deliveryAddress}/>
                :<span style={{ fontStyle: 'italic' }}>Unavailable</span>}
            </TableData>
          </MobileCol>
        </MobileRow>
      </TableRow>
      </tbody>
    </StyledTable>
  )
}

export default DetailsTable
