import UserActionTypes from '../../actionTypes/user';
import {navigate} from "gatsby";

const logoutAction = () => (dispatch) => {
  window.localStorage.isLoggedIn = true;

  dispatch({
    type: UserActionTypes.LOGOUT_ACTION,
    payload: {}
  });

  return navigate('/login');
};

export {
  logoutAction
}
